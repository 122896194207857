import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, Button, Row, Col, Modal } from 'react-bootstrap';
import { EmailFormField } from '../../app/parts/FormFields';
import TitleDropdown from '../TitleDropdown/TitleDropdown';
import useRegisterDelegate from '@hooks/user/useRegisterDelegate';
import useUserProfile from '@hooks/user/useUserProfile';
import { PermissionToggleBar } from '@components/user/delegates/DelegateUserCard';
function SuccessfulDelegateRegistrationModal({ show }) {
	const navigate = useNavigate();
	const handleClose = () => navigate('/');
	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title className="medr-text-inv">
					Delegate Registration
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p className="medr-text-inv">Delegate registration successful</p>
			</Modal.Body>
		</Modal>
	);
}
SuccessfulDelegateRegistrationModal.propTypes = {
	show: PropTypes.bool.isRequired,
};

function RegisterDelegateForm() {
	const { userProfile } = useUserProfile();
	const {
		registerDelegateData,
		updateRegisterDelegateData,
		updateRegisterEmail,
		formChecks,
		onSumbit,
		showSuccessfulRegistration,
		dupEmail,
	} = useRegisterDelegate(userProfile.id);

	const {
		title,
		firstName,
		lastName,
		email,
		permissionViewReferrals,
		permisisonCreateReferrals,
		permisisonManageReferrals,
	} = registerDelegateData;

	return (
		<>
			<SuccessfulDelegateRegistrationModal show={showSuccessfulRegistration} />
			<Form
				className="text-left"
				onSubmit={onSumbit}
				style={{ maxWidth: '700px', marginLeft: 'auto', marginRight: 'auto' }}
			>
				<Row>
					<Col className="col-12 col-lg-4">
						<Form.Label className="my-0">Title</Form.Label>
						<TitleDropdown
							currentTitle={title}
							sendTitle={updateRegisterDelegateData}
						/>
						{!formChecks.title ? (
							<p className="medr-invalid-text">Please select a title</p>
						) : null}
					</Col>

					<Col className="col-12 col-lg-8">
						<Form.Group controlId="firstName">
							<Form.Label className="my-0">First name</Form.Label>
							<Form.Control
								required
								type="name"
								placeholder="Enter first name"
								name="firstName"
								value={firstName}
								onChange={(e) =>
									updateRegisterDelegateData({
										[e.target.name]: e.target.value,
									})
								}
								isInvalid={!formChecks.firstName}
							/>
							<Form.Control.Feedback type="invalid">
								Please enter a first name.
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
				</Row>

				<Row className="mb-3 mt-2">
					<Col className="col-12 col-md-6">
						<Form.Group controlId="lastName">
							<Form.Label className="my-0">Last name</Form.Label>
							<Form.Control
								required
								type="name"
								placeholder="Enter last name"
								name="lastName"
								value={lastName}
								onChange={(e) =>
									updateRegisterDelegateData({
										[e.target.name]: e.target.value,
									})
								}
								isInvalid={!formChecks.lastName}
							/>
							<Form.Control.Feedback type="invalid">
								Please enter a last name.
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
					<Col className="col-12 col-md-6 email-no-margin">
						<Form.Label className="my-0">Email</Form.Label>
						<EmailFormField
							email={email}
							emailKey="email"
							changeValues={updateRegisterEmail}
							failedRegistration={dupEmail}
						/>
						{!formChecks.email ? (
							<p className="medr-invalid-text">Please enter email</p>
						) : null}
					</Col>
				</Row>
				<PermissionToggleBar
					field="permissionViewReferrals"
					label="Able to view referrals"
					value={permissionViewReferrals}
					onChange={updateRegisterDelegateData}
				/>
				<PermissionToggleBar
					field="permisisonCreateReferrals"
					label="Able to create and send referrals"
					value={permisisonCreateReferrals}
					onChange={updateRegisterDelegateData}
				/>
				<PermissionToggleBar
					field="permisisonManageReferrals"
					label="Able to manage referrals (accept and reject)"
					value={permisisonManageReferrals}
					onChange={updateRegisterDelegateData}
				/>
				<Row className="text-center mt-4">
					<Col>
						<Button
							className="sel-button w80"
							variant="primary"
							onClick={onSumbit}
						>
							Register new delegate
						</Button>
					</Col>
				</Row>
			</Form>
		</>
	);
}

export default RegisterDelegateForm;
