import { useDispatch } from 'react-redux';
import { loadUserDelegates, deactivateUserDelegates, loadUser } from '@actions/profileActions';
import { updateDelegateUserPermission } from '@actions/authActions';
import { useState } from 'react';


const useDelegateUserCard = (delegate) => {

    const dispatch = useDispatch();
    
    const[showDectivateModal, setShowDeactivateModal] = useState(false);
    const deactivateSuccess = (value) => {
        if (value) {
            dispatch(loadUser());
        }
    }
    const toggleShowDeactivate = () => setShowDeactivateModal(prev => !prev);
    const deactivateDelegate = () => {
        dispatch(deactivateUserDelegates(delegate, deactivateSuccess));
        setShowDeactivateModal(false);
    }

    const updatePermissionSuccess = (value) => {
        if (value) {
            dispatch(loadUserDelegates());
        }
    }

    const updatePermission = (data) => {
        const payload = {
            delegateId: delegate.id,
            delegateUserId: delegate.delegateUserProfile.id,
            permissions: data
        }
        dispatch(updateDelegateUserPermission(payload, updatePermissionSuccess));
    }

    return {
        deactivateDelegate,
        updatePermission,
        showDectivateModal,
        toggleShowDeactivate,
    }
}

export default useDelegateUserCard;