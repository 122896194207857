import React from 'react';
import { Card } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import useClinicianDelegates from '@hooks/user/useClinicianDelegates';
import DelegateUserCard, {
	DeactivatedDelegateUserCard,
} from './DelegateUserCard';

function NoDelegates() {
	const divStyle = {
		width: '400px',
		height: '200px',
		backgroundColor: '#f0f0f0',
		borderRadius: '15px', // Rounded corners
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Optional: Add a shadow
	};
	return (
		<div className="col d-flex flex-column align-items-center p-0">
			<div className="col inactive-del-cont d-flex flex-column align-items-stretch">
				<Card
					className="mb-2 rounded d-flex flex-fill"
					style={{ padding: '0.5rem' }}
				>
					<div className="d-flex justify-content-between">
						<div className="text-start">
							<Card.Title
								style={{
									marginBottom: '0.5rem',
									marginTop: '0rem',
									textAlign: 'left',
								}}
							>
								No active delegate acounts found
							</Card.Title>
							<Card.Subtitle
								className="text-muted"
								style={{
									marginTop: '0.5rem',
									marginBottom: '0.5rem',
									textAlign: 'left',
								}}
							>
								Please click &#39;Add New&#39; on the left to set one up.
							</Card.Subtitle>
						</div>
					</div>
				</Card>
			</div>
		</div>
	);
}

function DelegatesDisplay() {
	const { delegates, inactiveDelegates } = useClinicianDelegates();
	return (
		<>
			{delegates && delegates.length > 0 ? (
				delegates.map((delegate) => (
					<DelegateUserCard key={uuidv4()} delegate={delegate} />
				))
			) : (
				<NoDelegates />
			)}
			{inactiveDelegates && inactiveDelegates.length > 0 && (
				<>
					<h4 className="my-2">Inactive Delegate accounts</h4>
					<div className="col d-flex flex-column align-items-center p-0">
						<div className="col inactive-del-cont d-flex flex-column align-items-stretch">
							{inactiveDelegates.map((delegate) => (
								<DeactivatedDelegateUserCard
									key={uuidv4()}
									delegate={delegate}
								/>
							))}
						</div>
					</div>
				</>
			)}
		</>
	);
}

export default DelegatesDisplay;
