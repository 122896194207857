import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { boolPropType, funcPropType } from '@constants/propTypes';
import { termsPolicyPath } from '@/constants/appConstants';


function TermsAndConditionsPopup({showModal, handleClose}) {	
	return (
		<Modal show={showModal} onHide={handleClose} size="xl">
			<Modal.Header closeButton>
				<Modal.Title className='medr-inv'>MedR terms and conditions</Modal.Title>
			</Modal.Header>
			<Modal.Body className='medr'>
				<embed 
					src={termsPolicyPath}
					type="application/pdf" 
					height="500px" 
					width="100%" 
					className="responsive" />

			</Modal.Body>
				<Modal.Footer>
					<Button variant="danger" onClick={handleClose}>
						Close
					</Button>
			</Modal.Footer>
		</Modal>


	);
}
	
TermsAndConditionsPopup.propTypes = {
	showModal: boolPropType.isRequired,
	handleClose: funcPropType.isRequired
}
	
export default TermsAndConditionsPopup;