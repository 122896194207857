import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PatientEntryForm from '@components/patient/PatientEntryForm';

function PatientEditScreen() {

	const navigate = useNavigate();
	const [refReqSuccessful, setRefReqSuccessful] = useState(false);

	useEffect(() => {
		if (refReqSuccessful===true){
			navigate("/patients");
		}
	}, [refReqSuccessful])

	return (
		<Row className="text-center" style={{maxWidth: '650px', marginRight: 'auto', marginLeft: 'auto'}}>
			<Col>
				<h1 className='text-left'>Update Patient Information</h1>
				<PatientEntryForm initNewPatient={false}  isSuccessful={setRefReqSuccessful} />
			</Col>
		</Row>
	);
}

export default PatientEditScreen;