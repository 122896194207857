import React, { useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {
	clinicanProfilePropType,
	boolPropType,
	funcPropType,
} from '@constants/propTypes';
import {
	addClinicianToFavourites,
	removeClinicianFromFavourites,
} from '@actions/clinicianActions';
import { getNameString } from '@actions/general';

function FavClinicianStarPopup({
	clinician,
	showPopup,
	handleClose,
	requestMade,
}) {
	const dispatch = useDispatch();

	const { isFavourite, userProfile } = clinician;
	const { id } = userProfile;

	const onClick = async () => {
		try {
			if (isFavourite === true) {
				await dispatch(removeClinicianFromFavourites(id));
			} else {
				await dispatch(addClinicianToFavourites(id));
			}
			handleClose();

			setTimeout(() => {
				// TODO: understand why deley in add/remove favourites results in a deleay in calculating the new list of favourites
				requestMade();
			}, 1000);
		} catch (error) {
			// Handle any errors that occurred during the dispatch
			console.error('Error updating favourites:', error);
		}
	};

	return (
		<Modal show={showPopup} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title className="medr-inv">
					{isFavourite ? 'Remove' : 'Add'} {getNameString(userProfile)}{' '}
					{isFavourite ? 'from' : 'to'} favourite clinicians?
				</Modal.Title>
			</Modal.Header>
			<Modal.Footer>
				<Button variant={isFavourite ? 'danger' : 'success'} onClick={onClick}>
					{isFavourite ? 'Remove' : 'Add'}
				</Button>
				<Button variant="info" onClick={handleClose}>
					Cancel
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

FavClinicianStarPopup.propTypes = {
	clinician: clinicanProfilePropType.isRequired,
	showPopup: boolPropType.isRequired,
	handleClose: funcPropType.isRequired,
	requestMade: funcPropType.isRequired,
};

export default FavClinicianStarPopup;
