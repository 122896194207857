import React from 'react';
import AdminPatientsTable from '@components/admin/AdminPatientsTable';


function AdminPatientListScreen() {
	return (
		<div>
			<h1>Patients</h1>
			<AdminPatientsTable />
		</div>
	);
}

export default AdminPatientListScreen;
