import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import { funcPropType, boolPropType } from '@constants/propTypes';
import { requestNewFeature } from '@actions/adminActions';


function FeatureSuggestionPopup({ showModal, handleClose }) {
    const dispatch = useDispatch();
    const initData ={ suggestion: '' };

    const [formData, setFormData] = useState(initData);
    const { suggestion } = formData;

    const inviteSuccessful = (success) => {
        if (success) {
            setFormData(initData);
            handleClose();
        }
    }

    const onSubmit = () => {
        if (suggestion !== '') {
            dispatch(requestNewFeature({ suggestion }, inviteSuccessful));
        }
    }

	return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className='medr-inv'>Suggest a new feature</Modal.Title>
            </Modal.Header>
            <Modal.Body className='medr'>
                <Form className='text-left w100' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                    <Form.Group controlId="suggestion" className="mb-3">
                        <Form.Label className='my-0v'>Please describe the feature or information you would like to see in the app.</Form.Label>
                        <Form.Control
                            required
                            as="textarea"
                            placeholder=""
                            rows={5}
                            defaultValue={formData.patientHistory}
                            onChange={(e) => setFormData({...formData, suggestion: e.target.value })}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => onSubmit(formData)}>
                        Send suggestion
                    </Button>
                    <Button variant="danger" onClick={handleClose}>
                        Cancel
                    </Button>
            </Modal.Footer>
        </Modal>


	);
}

FeatureSuggestionPopup.propTypes = {
    showModal: boolPropType.isRequired,
    handleClose: funcPropType.isRequired
}

export default FeatureSuggestionPopup;
