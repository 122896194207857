import React from 'react';
import { Card, Button } from 'react-bootstrap';
// import { useNavigate } from 'react-router';

function ErrorFallback({ error, resetErrorBoundary }) {
  // const navigate = useNavigate();
  // const returnToDashboard = () => {
  //   navigate('/dashboard');
  // }
  return (
    <div className='medr-layout text-center landing-page' style={{maxWidth:'650px', marginLeft: 'auto', marginRight: 'auto'}}>
       <div className="d-flex justify-content-center">
        <Card 
            className="mb-2 rounded d-flex flex-fill" 
            style={{ padding: '0.5rem', maxWidth: '500px' , minHeight: '175px'}}
            >    
            <Card.Title>There has been an unexpected error</Card.Title>
            <Card.Subtitle className="text-muted mb-2" style={{paddingLeft: '30px'}}>
            The MedR team has been notified, apologies for the inconvenience
            </Card.Subtitle>
            <Card.Body>
              <Button 
                  variant='primary' 
                  className="usr-rx-now-button mt-4 w100"
                  onClick={resetErrorBoundary}
                  >
                  Retry page
              </Button>
              {/* <Button 
                  variant='info' 
                  className="usr-rx-now-button mt-4 w100"
                  onClick={returnToDashboard}
                  >
                  Return to user dashboard
              </Button> */}
            </Card.Body>
          </Card>
       </div>
    </div>
  );
}

export default ErrorFallback;
