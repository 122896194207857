import React, { useState, useEffect } from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
// import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import TitleDropdown from '../TitleDropdown/TitleDropdown';
import { EmailFormField } from '../../app/parts/FormFields';
import { registerCheckEmail } from '@actions/authActions';
import PasswordField from '@components/app/parts/PasswordField';
function RegisterPersonalDetailsForm({ values, changeValues, handleNext }) {
	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	const [passwordsMatch, setPasswordsMatch] = useState(false);
	const [dupEmail, setDupEmail] = useState(false);

	const checkFormReady = () => {
		const ready =
			values.title &&
			values.firstName &&
			values.lastName &&
			values.email &&
			values.password &&
			values.rePassword;
		return ready;
	};

	const handleEmailCheck = () => {
		registerCheckEmail(values.email).then((emailValid) => {
			setDupEmail(!emailValid);
		});
	};

	useEffect(() => {
		setPasswordsMatch(values.password === values.rePassword);
	}, [values.password, values.rePassword]);

	useEffect(() => {
		handleEmailCheck();
	}, [values.email]);

	const handleNextStep = (e) => {
		e.preventDefault();

		const formComplete = checkFormReady();

		if (!passwordsMatch) {
			return;
		}
		if (!formComplete) {
			return;
		}
		if (dupEmail) {
			return;
		}
		handleNext();
	};

	return (
		<div className="register-user-page">
			<Form onSubmit={handleNextStep}>
				<div className="row">
					<div
						className="col-md-4"
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'flex-end',
						}}
					>
						<Form.Group controlId="title" className="mt-2 dropdown-tile-group">
							<Form.Label className="my-0">Title</Form.Label>
							<TitleDropdown
								currentTitle={values.title}
								sendTitle={changeValues}
							/>
						</Form.Group>
					</div>
					<div className="col-md-8 py-1">
						<Form.Group controlId="firstName" className="mt-2">
							<Form.Label className="my-0">First name</Form.Label>
							<Form.Control
								required
								type="name"
								placeholder="Enter first name"
								defaultValue={values.firstName}
								onChange={(e) => changeValues({ firstName: e.target.value })}
							/>
						</Form.Group>
					</div>
				</div>

				<Form.Group controlId="lastName" className="mt-2">
					<Form.Label className="my-0">Last name</Form.Label>
					<Form.Control
						required
						type="name"
						placeholder="Enter last name"
						defaultValue={values.lastName}
						onChange={(e) => changeValues({ lastName: e.target.value })}
					/>
				</Form.Group>

				<EmailFormField
					email={values.email}
					emailKey="email"
					emailLabel="User contact email"
					changeValues={changeValues}
					failedRegistration={dupEmail}
				/>
				<PasswordField
					controlId="password"
					label="Password"
					password={values.password}
					keyLabel="password"
					changeValues={changeValues}
				/>
				<PasswordField
					controlId="rePassword"
					label="Confirm Password"
					password={values.rePassword}
					keyLabel="rePassword"
					changeValues={changeValues}
					isInvalid={!passwordsMatch}
					invalidString="Passwords do not match!"
				/>
				<Button
					className="sel-button mt-4 w100"
					type="submit"
					variant="primary"
				>
					Next
				</Button>
			</Form>
		</div>
	);
}

RegisterPersonalDetailsForm.propTypes = {
	values: PropTypes.shape({
		// jobRoleId: PropTypes.number.isRequired,
		title: PropTypes.string.isRequired,
		firstName: PropTypes.string.isRequired,
		lastName: PropTypes.string.isRequired,
		email: PropTypes.string.isRequired,
		password: PropTypes.string.isRequired,
		rePassword: PropTypes.string.isRequired,
	}).isRequired,
	changeValues: PropTypes.func.isRequired,
	handleNext: PropTypes.func.isRequired,
};

export default RegisterPersonalDetailsForm;
