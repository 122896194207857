import { useState, useEffect } from 'react';


const useScreenInfo = (maxWidth) => {
    const[isNarrow, setIsNarrow] = useState(window.innerWidth < maxWidth);

    useEffect(() => {
    const handleResize = () => {
        setIsNarrow(window.innerWidth < maxWidth);
    };

    window.addEventListener('resize', handleResize);
    
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);
    return [ isNarrow ];
};

export default useScreenInfo;