// Clinicians
export const CLINICIAN_LIST_REQUEST = 'CLINICIAN_LIST_REQUEST';
export const CLINICIAN_LIST_SUCCESS = 'CLINICIAN_LIST_SUCCESS';
export const CLINICIAN_LIST_FAIL = 'CLINICIAN_LIST_FAIL';

export const CLINICS_LIST_REQUEST = 'CLINICS_LIST_REQUEST';
export const CLINICS_LIST_SUCCESS = 'CLINICS_LIST_SUCCESS';
export const CLINICS_LIST_FAIL = 'CLINICS_LIST_FAIL';

export const CLINICIAN_BY_ID_REQUEST = 'CLINICIAN_BY_ID_REQUEST';
export const CLINICIAN_BY_ID_SUCCESS = 'CLINICIAN_BY_ID_SUCCESS';
export const CLINICIAN_BY_ID_FAIL = 'CLINICIAN_BY_ID_FAIL';

export const CLINICIAN_CREATE_REQUEST = 'CLINICIAN_CREATE_REQUEST';
export const CLINICIAN_CREATE_SUCCESS = 'CLINICIAN_CREATE_SUCCESS';
export const CLINICIAN_CREATE_FAIL = 'CLINICIAN_CREATE_FAIL';

export const CLINICIAN_INSURANCE_UPDATE_REQUEST = 'CLINICIAN_INSURANCE_UPDATE_REQUEST';
export const CLINICIAN_INSURANCE_UPDATE_SUCCESS = 'CLINICIAN_INSURANCE_UPDATE_SUCCESS';
export const CLINICIAN_INSURANCE_UPDATE_FAIL = 'CLINICIAN_INSURANCE_UPDATE_FAIL';

export const CLINICIAN_ROLE_REMOVE_REQ_REQUEST = 'CLINICIAN_ROLE_REMOVE_REQ_REQUEST';
export const CLINICIAN_ROLE_REMOVE_REQ_SUCCESS = 'CLINICIAN_ROLE_REMOVE_REQ_SUCCESS';
export const CLINICIAN_ROLE_REMOVE_REQ_FAIL = 'CLINICIAN_ROLE_REMOVE_REQ_FAIL';

export const CLINICIAN_ROLE_DEACTIVATE_REQUEST = 'CLINICIAN_ROLE_DEACTIVATE_REQUEST';
export const CLINICIAN_ROLE_DEACTIVATE_SUCCESS = 'CLINICIAN_ROLE_DEACTIVATE_SUCCESS';
export const CLINICIAN_ROLE_DEACTIVATE_FAIL = 'CLINICIAN_ROLE_DEACTIVATE_FAIL';

// JOB ROLES
export const JOB_ROLE_LIST_REQUEST = 'JOB_ROLE_LIST_REQUEST';
export const JOB_ROLE_LIST_SUCCESS = 'JOB_ROLE_LIST_SUCCESS';
export const JOB_ROLE_LIST_FAIL = 'JOB_ROLE_LIST_FAIL';

export const JOB_ROLE_SET_ID_REQUEST = 'JOB_ROLE_SET_ID_REQUEST';
export const JOB_ROLE_SET_ID_SUCCESS = 'JOB_ROLE_SET_ID_SUCCESS';
export const JOB_ROLE_SET_ID_FAIL = 'JOB_ROLE_SET_ID_FAIL';

export const INSURANCE_AGREEMENT_LIST_REQUEST = 'INSURANCE_AGREEMENT_LIST_REQUEST';
export const INSURANCE_AGREEMENT_LIST_SUCCESS = 'INSURANCE_AGREEMENT_LIST_SUCCESS';
export const INSURANCE_AGREEMENT_LIST_FAIL = 'INSURANCE_AGREEMENT_LIST_FAIL';

export const INSURANCE_AGREEMENT_SET_ID_REQUEST = 'INSURANCE_AGREEMENT_SET_ID_REQUEST';
export const INSURANCE_AGREEMENT_SET_ID_SUCCESS = 'INSURANCE_AGREEMENT_SET_ID_SUCCESS';
export const INSURANCE_AGREEMENT_SET_ID_FAIL = 'INSURANCE_AGREEMENT_SET_ID_FAIL';

export const USER_CRPS_GET_REQUEST = 'USER_CRPS_GET_REQUEST';
export const USER_CRPS_GET_SUCCESS = 'USER_CRPS_GET_SUCCESS';
export const USER_CRPS_GET_FAIL = 'USER_CRPS_GET_FAIL';

// CLinician favs
export const CLINICIAN_ADD_FAVOURITE_REQUEST = 'CLINICIAN_ADD_FAVOURITE_REQUEST';
export const CLINICIAN_ADD_FAVOURITE_SUCCESS = 'CLINICIAN_ADD_FAVOURITE_SUCCESS';
export const CLINICIAN_ADD_FAVOURITE_FAIL = 'CLINICIAN_ADD_FAVOURITE_FAIL';

export const CLINICIAN_GET_FAVOURITE_REQUEST = 'CLINICIAN_GET_FAVOURITE_REQUEST';
export const CLINICIAN_GET_FAVOURITE_SUCCESS = 'CLINICIAN_GET_FAVOURITE_SUCCESS';
export const CLINICIAN_GET_FAVOURITE_FAIL = 'CLINICIAN_GET_FAVOURITE_FAIL';

export const CLINICIAN_RM_FAVOURITE_REQUEST = 'CLINICIAN_RM_FAVOURITE_REQUEST';
export const CLINICIAN_RM_FAVOURITE_SUCCESS = 'CLINICIAN_RM_FAVOURITE_SUCCESS';
export const CLINICIAN_RM_FAVOURITE_FAIL = 'CLINICIAN_RM_FAVOURITE_FAIL';

// invite
export const INVITE_CLINICIAN_REQUEST = 'INVITE_CLINICIAN_REQUEST';
export const INVITE_CLINICIAN_SUCCESS = 'INVITE_CLINICIAN_SUCCESS';
export const INVITE_CLINICIAN_FAIL = 'INVITE_CLINICIAN_FAIL';

