import PropTypes from 'prop-types';

export const strPropType = PropTypes.string;
export const numberPropType = PropTypes.number;
export const boolPropType = PropTypes.bool;
export const funcPropType = PropTypes.func;
export const isAuthenticatedProptype = PropTypes.bool;
export const isAdminPropType = PropTypes.bool;
export const targetLatPropType = PropTypes.number;
export const targetLongPropType = PropTypes.number;

export const addressPropType = PropTypes.shape({
    id: PropTypes.number,
    addressType: PropTypes.string,
    firstLine: PropTypes.string,
    city: PropTypes.string,
    postcode: PropTypes.string,
    longitude: PropTypes.number,
    latitude: PropTypes.number
});

export const addressesPropType = PropTypes.arrayOf(addressPropType);

export const clinicPropType = PropTypes.shape({
    id: PropTypes.number,
    clinicName: PropTypes.string,
    centreName: PropTypes.string,
    address: addressPropType
});

export const clinicsPropType = PropTypes.arrayOf(clinicPropType);

export const insuranceProptype = PropTypes.shape({
    id: PropTypes.number,
    policy: PropTypes.string,
    provider: PropTypes.string,
});

export const insuranceAgreementsPropType = PropTypes.arrayOf(insuranceProptype);

export const appointmentsContactPropType = PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
});

export const appointmentsContactListPropType = PropTypes.arrayOf(appointmentsContactPropType);


export const userPropType = PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    is_staff: PropTypes.bool
});

export const jobRolePropType = PropTypes.shape({
    id: PropTypes.number,
    jobRole: PropTypes.string,
    registrationBody: PropTypes.string,
    jobTitle: PropTypes.string,
    specialty: PropTypes.string,
    subSpecialty: PropTypes.string,
});
    
export const userProfilePropType = PropTypes.shape({
    id: PropTypes.number,
    user: userPropType,
    jobRole: PropTypes.arrayOf(jobRolePropType),
    title: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    profilePicture: PropTypes.string,
    registrationNumber: PropTypes.string,
    clinicianDescription: PropTypes.string,
});

export const notificationSettingsProptype = PropTypes.shape({
    id: PropTypes.number,
    userProfile: PropTypes.number,
    userCreatedReferral: PropTypes.bool,
    userReceivedReferral: PropTypes.bool,
    targetHasToggledReferral: PropTypes.bool,
    userHasToggledReferral: PropTypes.bool,
    newOpenReferral: PropTypes.bool,
})

export const clinicanProfilePropType = PropTypes.shape({
    id: PropTypes.number,
    userProfile: userProfilePropType,
    clinic: clinicPropType,
    isFavourite: PropTypes.bool,
});

export const clinicanProfileRankPropType = PropTypes.shape({
    id: PropTypes.number,
    userProfile: userProfilePropType,
    clinic: clinicPropType,
    distanceRank: PropTypes.number,
    distance: PropTypes.number

});

export const appointmentContactPropType = PropTypes.shape({
    appointmentPhone: PropTypes.string,
    appointmentEmail: PropTypes.string,
});

export const patientPropType = PropTypes.shape({
    id: PropTypes.number
});


export const referralPropType = PropTypes.shape({
    id: PropTypes.number
});

export const userProfilesPropType = PropTypes.arrayOf(userProfilePropType);
export const jobRolesPropType = PropTypes.arrayOf(jobRolePropType);
export const clinicianProfilesPropType = PropTypes.arrayOf(clinicanProfilePropType);
export const clinicanProfileRanksPropType = PropTypes.arrayOf(clinicanProfileRankPropType);
export const patientsPropType = PropTypes.arrayOf(patientPropType);
export const referralsPropType = PropTypes.arrayOf(patientPropType);

export const specialtiesPropType = PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      })
    )
  );


export const initCrpFormPropType = PropTypes.shape({
    centreName: PropTypes.string, 
    addressFirstLine: PropTypes.string, 
    addressCity: PropTypes.string, 
    addressPostcode: PropTypes.string
});