import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button, Table, Card } from 'react-bootstrap';
import { getNameString } from '@actions/general';
import AppUpdatesPanel from '@components/app/AppUpdatesPanel';
import useUserProfile from '@hooks/user/useUserProfile';
import usePlatformStatistics from '../../hooks/admin/usePlatformStatistics';

function DashboardStaff() {
	const navigate = useNavigate();
	const { userProfile } = useUserProfile();
	const handleNavigationUsersList = () => navigate('/staff/users');
	const handleNavigationCliniciansMap = () => navigate('/clinicians');
	const statsData = usePlatformStatistics();

	const loginRows =
		statsData.last_logged_in &&
		Object.entries(statsData.last_logged_in).map(([group, times]) =>
			Object.entries(times).map(([period, nn]) => (
				<li className="text-muted">
					<div className="row">
						<div
							// className="col"
							style={{ width: '30px' }}
						>
							{nn}
						</div>
						<div
							// className="col"
							style={{ width: '100px' }}
						>
							{group}
						</div>
						<div>in the last {period} days.</div>
					</div>
				</li>
			))
		);
	const rxRows =
		statsData.last_logged_in &&
		Object.entries(statsData.referrals).map(([rxType, times]) =>
			Object.entries(times).map(([period, nn]) => (
				<li className="text-muted">
					<div className="row">
						<div
							// className="col"
							style={{ width: '40px' }}
						>
							{nn}
						</div>
						<div
							// className="col"
							style={{ width: '100px' }}
						>
							{rxType}
						</div>
						<div>referrals in the last {period} days.</div>
					</div>
				</li>
			))
		);

	function statsBoxDisplay(title, list) {
		return (
			<Card className="my-3 p-3 rounded d-flex flex-fill">
				<Card.Title>{title}</Card.Title>
				<Card.Body>
					<ul style={{ listStyle: 'none', textAlign: 'left' }}>{list}</ul>
				</Card.Body>
			</Card>
		);
	}

	return (
		<>
			<Row className="medr-layout text-center">
				<Col>
					<h4>Welcome {userProfile && getNameString(userProfile)}</h4>
				</Col>
			</Row>
			<Row className="medr-layout text-center">
				<Col>
					<Button
						type="button"
						onClick={handleNavigationUsersList}
						className="sel-button w100"
					>
						Verify Users
					</Button>
				</Col>
				<Col>
					<Button
						type="button"
						onClick={handleNavigationCliniciansMap}
						className="sel-button w100"
					>
						See clinicians
					</Button>
				</Col>
			</Row>

			<Row className="medr-layout text-center">
				<Col xs={12} md={9}>
					<p className="medr-text">MedR administration account</p>
					<div className="d-flex justify-content-start flex-wrap">
						{statsBoxDisplay('Login statistics', loginRows)}
						{statsBoxDisplay('Referral statistics', rxRows)}
					</div>
				</Col>
				<Col xs={12} md={3}>
					<AppUpdatesPanel />
				</Col>
			</Row>
		</>
	);
}

export default DashboardStaff;
