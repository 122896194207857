import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getReferralNotifications } from '@actions/profileActions';

const useReferralNotifications = (isAuthenticated) => {
    const dispatch = useDispatch();

    const referralNotifications = useSelector(state => state.profile.referralNotifications);
    const [nReferrals, setNReferrals] = useState(null);
    const [referrals, setReferrals] = useState([]);

    const updateNotifications = () => {
        if (!referralNotifications || !referralNotifications.referrals || !referralNotifications.nReferrals) {
            setNReferrals(0);
            setReferrals([]);
            return;
        }
        setNReferrals(referralNotifications.nReferrals);
        setReferrals(referralNotifications.referrals);
    }
    useEffect(() => {
        if (!isAuthenticated) return;
        async function fetchData() {
            await dispatch(getReferralNotifications());
        }
        fetchData();
    }, []);
    useEffect(() => updateNotifications(), [referralNotifications]);

    return {
        nReferrals,
        referrals,
    };
}

export default useReferralNotifications;