import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadUserDelegates } from '@actions/profileActions';

const useRegisterDelegates = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadUserDelegates());
    }, []);

    const delegates = useSelector((state) => state.profile.delegates);
    const inactiveDelegates = useSelector((state) => state.profile.pendingDelegates);
    return { delegates, inactiveDelegates };
}

export default useRegisterDelegates;