import React, { useState, useEffect } from "react";
import { Row,  Col, Button, Form  } from 'react-bootstrap';
import PropTypes from 'prop-types';
import AddressSearchBox from "./AddressSearchBox";
import { registerCheckClinic } from '@actions/authActions';

function ClinicFormDoctor({ handleAddClinic }){

    const initForm = {
        clinicName: '',
        centreName: '',
        address: {
            firstLine: '',
            city: '',
            postcode: '',
        },
    };

    const [formData, setFormData] = useState(initForm);
    const [dataSent, setDataSent] = useState(true);
    const [gpsError, setGpsError] = useState(false);
    const [showExisting, setShowExisting] = useState(false);
    const { clinicName, centreName, address } = formData;
    const { firstLine, city, postcode } = address;

    const checkAddress = async () => {
        if (postcode===''){return;}
        const addressValid = await registerCheckClinic(firstLine, city, postcode);
        setGpsError(!addressValid);
    };

    useEffect(() => {
        checkAddress();
    }, [address]);


    const onSubmit = (e) => {
        e.preventDefault();
        if (gpsError){return;}
        handleAddClinic(formData);
        setDataSent(true);
        setShowExisting(false);
    };
    
    useEffect(() => {
        if (dataSent===true){
            setFormData(initForm);
            setDataSent(false);
        }
    }, [dataSent, initForm, formData])


    const receiveAddressInfo = (addressInfo) => {
        setFormData({...formData, centreName: addressInfo.centreName, address: addressInfo.address});
    }
    const setShowAddExisting = () => setShowExisting(!showExisting);
    
    return (
        <div className='medr-rounded bg-medr-gray px-3 text-left'>
            <Row>
                <Col>
                    <Form onSubmit={(e) => onSubmit(e)}>
                        <Row className='mb-2'>
                            <Col className="text-center">
                                <Button className="sel-button w80" onClick={setShowAddExisting}>{showExisting ? "Hide exisiting hospitals" : "Select address from exisiting hospitals"}</Button>
                            </Col>
                        </Row>
                        {showExisting ? 
                            <Form.Group className='mb-2' controlId="existingClinics">
                                <Form.Label className='my-0 bluegreen'>Select address from exisiting hospitals</Form.Label>
                                <AddressSearchBox changeValue={(info) => receiveAddressInfo(info)} />
                            </Form.Group>
                        : null}

                        <Form.Group controlId="clinicName">
                            <Form.Label className='my-0 bluegreen'>Clinic Name</Form.Label>
                            <Form.Control
                                required
                                type="name"
                                placeholder="Enter clinic name"
                                className='mb-3'
                                value={clinicName}
                                onChange={(e) => setFormData({...formData, clinicName: e.target.value})}
                            />
                        </Form.Group>

                        <Form.Group controlId="centerName">
                            <Form.Label className='my-0 bluegreen'>Hospital/practice</Form.Label>
                            <Form.Control
                                type="name"
                                placeholder="Enter hospital/practice"
                                className='mb-3'
                                value={centreName}
                                onChange={(e) => setFormData({...formData, centreName: e.target.value})}
                            />
                        </Form.Group>

                        <Form.Group controlId="firstLine">
                            <Form.Label className='my-0 bluegreen'>First line</Form.Label>
                            <Form.Control
                                type="name"
                                placeholder="Enter first line of address"
                                className='mb-3'
                                value={address.firstLine}
                                onChange={(e) => setFormData({...formData, address: {...address, firstLine: e.target.value}})}
                            />
                        </Form.Group>

                        <Form.Group controlId="city">
                            <Form.Label className='my-0 bluegreen'>City</Form.Label>
                            <Form.Control
                                type="name"
                                placeholder="Enter city"
                                className='mb-3'
                                value={address.city}
                                onChange={(e) => setFormData({...formData, address: {...address, city: e.target.value}})}
                            />
                        </Form.Group>

                        <Form.Group controlId="postcode">
                            <Form.Label className='my-0 bluegreen'>Postcode</Form.Label>
                            <Form.Control
                                required
                                type="name"
                                placeholder="Enter postcode"
                                className='mb-3'
                                value={address.postcode}
                                onChange={(e) => setFormData({...formData, address: {...address, postcode: e.target.value}})}
                            />
                        </Form.Group>
                        <Row>
                            <Col className='text-center mt-4'>
                                <Button className="sel-button w100"  type="submit">Add clinic</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>

    )
};

ClinicFormDoctor.propTypes = {
	handleAddClinic: PropTypes.func.isRequired,
};

export default ClinicFormDoctor;