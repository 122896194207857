// Referrals
export const REFERRAL_LIST_REQUEST = 'REFERRAL_LIST_REQUEST';
export const REFERRAL_LIST_SUCCESS = 'REFERRAL_LIST_SUCCESS';
export const REFERRAL_LIST_FAIL = 'REFERRAL_LIST_FAIL';

export const REFERRAL_REQUEST = 'REFERRAL_REQUEST';
export const REFERRAL_SUCCESS = 'REFERRAL_SUCCESS';
export const REFERRAL_FAIL = 'REFERRAL_FAIL';

export const REFERRAL_TOGGLE_ACCEPT_REQUEST = 'REFERRAL_TOGGLE_ACCEPT_REQUEST';
export const REFERRAL_TOGGLE_ACCEPT_SUCCESS = 'REFERRAL_TOGGLE_ACCEPT_SUCCESS';
export const REFERRAL_TOGGLE_ACCEPT_FAIL = 'REFERRAL_TOGGLE_ACCEPT_FAIL';

export const REFERRAL_TOGGLE_COMPLETE_REQUEST = 'REFERRAL_TOGGLE_COMPLETE_REQUEST';
export const REFERRAL_TOGGLE_COMPLETE_SUCCESS = 'REFERRAL_TOGGLE_COMPLETE_SUCCESS';
export const REFERRAL_TOGGLE_COMPLETE_FAIL = 'REFERRAL_TOGGLE_COMPLETE_FAIL';

export const REFERRAL_CANCEL_REQUEST = 'REFERRAL_CANCEL_REQUEST';
export const REFERRAL_CANCEL_SUCCESS = 'REFERRAL_CANCEL_SUCCESS';
export const REFERRAL_CANCEL_FAIL = 'REFERRAL_CANCEL_FAIL';

// Clinican Referrals
export const CLINICIAN_REFERRALS_REQUEST = 'CLINICIAN_REFERRALS_REQUEST';
export const CLINICIAN_REFERRALS_SUCCESS = 'CLINICIAN_REFERRALS_SUCCESS';
export const CLINICIAN_REFERRALS_FAIL = 'CLINICIAN_REFERRALS_FAIL';

// Clinican Referrals
export const CREATE_REFERRAL_REQUEST = 'CREATE_REFERRAL_REQUEST';
export const CREATE_REFERRAL_SUCCESS = 'CREATE_REFERRAL_SUCCESS';
export const CREATE_REFERRAL_FAIL = 'CREATE_REFERRAL_FAIL';
