import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getClinicianById } from '@actions/clinicianActions';


const useClinician = (clinicianId) => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (!clinicianId || clinicianId <1) return;
        dispatch(getClinicianById(clinicianId));
    }, [clinicianId]);

    const clinician = useSelector((state) => state.clinicians.clinician);    
    return { 
        clinician
     };
}

export default useClinician;