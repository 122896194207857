import React from 'react';
import { Form } from 'react-bootstrap';
import { funcPropType } from '@constants/propTypes';


function ClinicianFilterInput({ setFilterString }) {
    const updateStrs = (e) => {
        e.preventDefault()
        setFilterString(e.target.value);
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

	return (
        <Form className='text-left'>
            <Form.Group controlId="cxSearchBar" className="mb-3">
                <Form.Control
                    required
                    type="name"
                    placeholder="Search clinician by name"
                    name="searchStr"
                    onChange={(e) => updateStrs(e)}
                    onKeyDown={handleKeyDown}
                    />
            </Form.Group>

        
        </Form>

	);
}

ClinicianFilterInput.propTypes = {
    setFilterString: funcPropType.isRequired
}

export default ClinicianFilterInput;