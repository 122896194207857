import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Map from './map';
import ClinicianPanel from './ClinicianPanel';
import PostcodeSearch from '@components/clinicians/PostcodeSearch';
import {
	clinicsPropType,
	clinicanProfileRanksPropType,
	funcPropType,
} from '@constants/propTypes';
import useMapView from './useMapView';

function MapView({
	clinics,
	clinicians,
	handleShowFavourites,
	hoveredClinicianUserProfileIds,
	hoveredAddressIds,
	updateHovered,
	onClinicianSelect,
	updateMapViewCoords,
	refreshClinicians,
	showReferralButton = true,
}) {
	const { updateShowOnlyFavourites, receivePostcodeSearch, searchedLocation } =
		useMapView(handleShowFavourites);

	return (
		<div className="container-fluid">
			<div className="row mt-2 mb-5">
				<div
					className="col-md-8 mb-5 mb-md-0"
					style={{ maxHeight: '60vh', paddingRight: '0px' }}
				>
					<div className="row mb-3">
						<div className="col-md-12 col-lg-10 clinicians-search-postcode">
							<PostcodeSearch changeValues={receivePostcodeSearch} />
						</div>
					</div>

					<Map
						clinics={clinics}
						hoveredAddressIds={hoveredAddressIds}
						updateHovered={updateHovered}
						viewLocation={searchedLocation}
						updateMapViewCoords={updateMapViewCoords}
					/>
				</div>
				<div className="col-md-4 mt-5 mt-md-0">
					<div className="row">
						<div className="col clinicians-search-postcode-tabs">
							<Tabs
								defaultActiveKey="allClinicians"
								id="custom-tabs"
								className="custom-tabs mb-3 clin-panel-div"
								onSelect={updateShowOnlyFavourites}
							>
								<Tab
									eventKey="allClinicians"
									title="All clinicians"
									className="custom-tabs"
								/>
								<Tab
									eventKey="favouriteClinicians"
									title="Favourites"
									className="custom-tabs "
								/>
							</Tabs>
						</div>
					</div>
					<div className="row" style={{ maxHeight: '60vh', overflowX: 'auto' }}>
						<div className="col">
							<ClinicianPanel
								clinicians={clinicians}
								hoveredClinicianUserProfileIds={hoveredClinicianUserProfileIds}
								updateHovered={updateHovered}
								onClinicianSelect={onClinicianSelect}
								showReferralButton={showReferralButton}
								refreshClinicians={refreshClinicians}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

MapView.propTypes = {
	clinics: clinicsPropType.isRequired,
	clinicians: clinicanProfileRanksPropType.isRequired,
	handleShowFavourites: funcPropType.isRequired,
	hoveredClinicianUserProfileIds: PropTypes.arrayOf(PropTypes.number)
		.isRequired,
	hoveredAddressIds: PropTypes.arrayOf(PropTypes.number).isRequired,
	updateHovered: funcPropType.isRequired,
	onClinicianSelect: funcPropType.isRequired,
	updateMapViewCoords: funcPropType.isRequired,
	refreshClinicians: funcPropType.isRequired,
	showReferralButton: PropTypes.bool,
};

export default MapView;
