import React, { useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import RegisterPersonalDetailsForm from '@components/user/Registration/RegisterUserDetailsForm';
import RegisterClinicianDetialsForm from '@components/user/Registration/RegisterClinicianDetailsForm';
import RegisterInsuranceDetails from '@components/user/RegisterInsuranceDetails';
import RegisterReview from '@components/user/RegisterReview';
import { RedirectIfAuthDetailsAvailable } from '@actions/navigations';
import useUserRegistration from '@hooks/user/useUserRegistration';

function SignupScreen() {
	RedirectIfAuthDetailsAvailable();

	const {
		activeStep,
		setNextStep,
		setPreviousStep,
		registrationData,
		updateRegistrationData,
		addJobRole,
		removeJobRole,
		addClinic,
		removeClinic,
		editClinic,
		updateAppointmentContacts,
		updateExisitingProviders,
		updateNewProviders,
	} = useUserRegistration();

	return (
		<div
			className="medr-layout text-center register-page"
			style={{ maxWidth: '90%', marginLeft: 'auto', marginRight: 'auto' }}
		>
			<h1>Registration</h1>
			<Row>
				<Col className="py-0">
					{activeStep === 0 && (
						<RegisterPersonalDetailsForm
							values={registrationData}
							changeValues={updateRegistrationData}
							handleNext={setNextStep}
						/>
					)}
					{activeStep === 1 && (
						<RegisterClinicianDetialsForm
							values={registrationData}
							addJobRole={addJobRole}
							removeJobRole={removeJobRole}
							addClinic={addClinic}
							editClinic={editClinic}
							removeClinic={removeClinic}
							updateAppointmentContacts={updateAppointmentContacts}
							changeValues={updateRegistrationData}
							handleNext={setNextStep}
						/>
					)}
					{activeStep === 2 && (
						<RegisterInsuranceDetails
							values={registrationData}
							updateExisitingProviders={updateExisitingProviders}
							updateNewProviders={updateNewProviders}
							handleNext={setNextStep}
						/>
					)}
					{activeStep === 3 && <RegisterReview values={registrationData} />}
				</Col>
			</Row>
			{activeStep !== 0 ? (
				<Row>
					<Col className="text-center">
						<Button
							className="sel-button w80"
							variant="dark"
							onClick={setPreviousStep}
						>
							Previous
						</Button>
					</Col>
				</Row>
			) : null}
		</div>
	);
}

export default SignupScreen;
