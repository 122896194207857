import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPlatformStats } from '@actions/adminActions';

const usePlatformStatistics = () => {
	const dispatch = useDispatch();
	const statsData = useSelector((state) => state.admin.platformStats);

	useEffect(() => {
		dispatch(getPlatformStats());
	}, []);

	return statsData;
};

export default usePlatformStatistics;
