import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { numberPropType, funcPropType } from '@constants/propTypes';


function PaginationBar({ arrayLength, setSliceValues, windowSize = 10}) {
    
    const minIndex = 0;
    const [maxIndex, setMaxIndex] = useState(arrayLength);
    const [window, setWindow] = useState(windowSize);
    const [startSlice, setStartSlice] = useState(minIndex);
    const [endSlice, setEndSlice] = useState(minIndex + window);
    const atStart = minIndex === startSlice;
    const atEnd = maxIndex === endSlice;

    useEffect(() => {
        if (arrayLength < windowSize) {
            setWindow(arrayLength);
            setEndSlice(arrayLength);
        }
        setMaxIndex(arrayLength);
    }, [arrayLength]);

    const validateIndexRange = (index, min, max) =>  Math.max(min, Math.min(index, max));
    
    const setToStart = () => {
        setStartSlice(minIndex);
        setEndSlice(validateIndexRange(minIndex + windowSize, minIndex, maxIndex));
    }

    const moveWindowBackward = () => {
        const newStart = validateIndexRange(startSlice - window, minIndex, maxIndex - window);
        const newEnd = validateIndexRange(endSlice - window, minIndex + window, maxIndex);
        setStartSlice(newStart);
        setEndSlice(newEnd);
    }

    const moveWindowForward = () => {
        const newStart = validateIndexRange(startSlice + window, minIndex, maxIndex - window);
        const newEnd = validateIndexRange(endSlice + window, minIndex + window, maxIndex);
        setStartSlice(newStart);
        setEndSlice(newEnd);
    }

    const setToEnd = () => {
        setStartSlice(validateIndexRange(maxIndex - window, minIndex, maxIndex - window));
        setEndSlice(maxIndex);
    }

    useEffect(() => {
        setSliceValues(startSlice, endSlice);
    }, [startSlice, endSlice]);

	return (
        <Row>
            <Col className='text-center pagination-row'>
                <div className='d-flex d-sm-inline-flex justify-content-center align-items-center flex-column  flex-sm-row'>
                    <Button className='sel-button mx-2 mb-3 mb-sm-0' type="button" variant='primary' onClick={setToStart} disabled={atStart} >Start</Button>
                    <Button className='sel-button mx-2 mb-3 mb-sm-0' type="button" variant='primary' onClick={moveWindowBackward} disabled={atStart} >Previous</Button>
                    <p className='pagination-text my-0 mx-2 mb-3 mb-sm-0'>Displaying {startSlice+1} to {endSlice} (of {maxIndex})</p>
                    <Button className='sel-button mx-2 mb-3 mb-sm-0' type="button" variant='primary' onClick={moveWindowForward} disabled={atEnd}>Next</Button>
                    <Button className='sel-button mx-2 mb-3 mb-sm-0' type="button" variant='primary' onClick={setToEnd} disabled={atEnd}>End</Button>
                </div>
            </Col>
        </Row>
	);
}

PaginationBar.propTypes = {
    arrayLength: numberPropType.isRequired,
    setSliceValues: funcPropType.isRequired,
    windowSize: numberPropType
}

export default PaginationBar;