import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import JobRoleSearchBar from '@components/clinicians/JobRoleSearchBar';
import InsuranceAgreementSearchBar from '@components/clinicians/InsuranceSearchBox/InsuranceAgreementSearchBar';
import { getCliniciansList } from '@actions/clinicianActions';
import useFilteredClinicians from '@hooks/clinicians/useFilteredClinicians';
import useCliniciansList from '@hooks/clinicians/useCliniciansList';
import ClinicianFilterInput from '@components/clinicians/ClinicianFilterInput';
import MapView from '@features/map/MapView';
import useGeoLocationToSearch from '@features/map/useGeolocationToSearch';

function CliniciansListScreen() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useGeoLocationToSearch();
	const { filterFields, updateFilterFields } = useFilteredClinicians(0);

	const {
		clinicianList,
		clinicAddresses,
		setFilterString,
		toggleShowOnlyFavourites,
		hoveredClinicianUserProfileIds,
		hoveredAddressIds,
		updateHovered,
		updateMapViewCoords,
	} = useCliniciansList();

	const {
		jobRoleId,
		insuranceProviderId,
		searchLatitude,
		searchLongitude,
		jobRoleSelected,
		jobSpecialtySelected,
	} = filterFields;

	const refreshClinicians = () => {
		dispatch(
			getCliniciansList(
				jobRoleId,
				insuranceProviderId,
				searchLatitude,
				searchLongitude,
				jobRoleSelected,
				jobSpecialtySelected
			)
		);
	};

	useEffect(() => {
		refreshClinicians();
	}, [
		jobRoleId,
		insuranceProviderId,
		searchLatitude,
		searchLongitude,
		jobRoleSelected,
		jobSpecialtySelected,
	]);

	const onClinicianSelect = (id) => {
		const clin = clinicianList.find((clinician) => clinician.id === id);
		if (!clin) return;
		const upId = clin.userProfile.id;
		navigate(`/clinicians/user/${upId}`);
	};

	return (
		<>
			<Row className="py-1">
				<Col className="text-center">
					<h1>Search our clinicians</h1>
				</Col>
			</Row>
			<div className="container-fluid">
				<Row className="mb-4 search-clinicians-page-row1">
					<Col sm={12} md={6}>
						<JobRoleSearchBar changeValues={updateFilterFields} filtered />
					</Col>
					<Col sm={12} md={6}>
						<InsuranceAgreementSearchBar changeValues={updateFilterFields} />
						<ClinicianFilterInput setFilterString={setFilterString} />
					</Col>
				</Row>
			</div>
			<MapView
				clinics={clinicAddresses}
				clinicians={clinicianList}
				handleShowFavourites={toggleShowOnlyFavourites}
				hoveredClinicianUserProfileIds={hoveredClinicianUserProfileIds}
				hoveredAddressIds={hoveredAddressIds}
				updateHovered={updateHovered}
				onClinicianSelect={onClinicianSelect}
				refreshClinicians={refreshClinicians}
				updateMapViewCoords={updateMapViewCoords}
			/>
		</>
	);
}

export default CliniciansListScreen;
