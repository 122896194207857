import React, { useState } from 'react';
import { Form, Button, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { login } from '@actions/authActions';
import { loadUser, getReferralNotifications } from '@actions/profileActions';
import { RedirectIfAuthDetailsAvailable } from '@actions/navigations';
import store from '../../store';
import PasswordField from '@components/app/parts/PasswordField';

function LoginScreen() {
	/**
	 * 1. Check for auth token in cookies
	 * 2. If auth token exists, set is authenticated to true and redirect to dashboard
	 * 3  If auth token doesn't exist, set is authenticated to false and render login form
	 */

	RedirectIfAuthDetailsAvailable();

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [formData, setFormData] = useState({
		username: '',
		password: '',
	});
	const [error, setError] = useState(false);

	const { username, password } = formData;

	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};
	const updateChanges = (newData) => {
		setFormData({ ...formData, ...newData });
	};
	const successfulLoadUser = (value) => {
		if (value === true) {
			// try {
			dispatch(getReferralNotifications());
			const state = store.getState();
			const { profile } = state;
			if (!profile) {
				throw new Error('No profile data found in local storage');
			}
			if (!profile.userProfile) {
				throw new Error('User profile data not found in local storage');
			}

			localStorage.setItem('profile', JSON.stringify(profile));

			navigate('/dashboard');

			// }
			// catch (error) {console.error('Error while retrieving user:', error.message);}
		}
	};

	const successfulLogin = (value) => {
		if (value === true) {
			const state = store.getState();
			Cookies.set('authToken', state.auth.token, { expires: 7 });
			localStorage.setItem(
				'auth',
				JSON.stringify({
					user: state.auth.user,
					isAuthenticated: state.auth.isAuthenticated,
					isAdmin: state.auth.isAdmin,
				})
			);
			dispatch(loadUser(successfulLoadUser));
		} else {
			setError(!value);
		}
	};

	const onSubmit = (e) => {
		e.preventDefault();
		dispatch(login(username, password, successfulLogin));
	};

	return (
		<div
			className="medr-layout text-center login-page"
			style={{ maxWidth: '380px', marginLeft: 'auto', marginRight: 'auto' }}
		>
			<h1>Sign In</h1>
			<Form onSubmit={(e) => onSubmit(e)}>
				<Form.Group className="py-0" controlId="email">
					<Form.Label className="my-0">Email address</Form.Label>
					<Form.Control
						type="email"
						placeholder="Enter Email"
						name="username"
						value={username}
						onChange={(e) => onChange(e)}
						autoComplete="username"
						isInvalid={error}
					/>
					<Form.Control.Feedback type="invalid">
						Email and password combination not found.
					</Form.Control.Feedback>
				</Form.Group>
				<PasswordField
					controlId="password"
					label="Password"
					password={password}
					keyLabel="password"
					changeValues={updateChanges}
					autoComplete="current-password"
				/>
				{/* <Form.Group className="mt-3" controlId="password">
					<Form.Label className="my-0">Password</Form.Label>
					<Form.Control
						type="password"
						placeholder="Enter Password"
						name="password"
						value={password}
						onChange={(e) => onChange(e)}
						autoComplete="current-password"
					/>
				</Form.Group> */}
				<div>
					<p className="medr-text mt-2 medr-small">
						Forgotten your password?{' '}
						<Link
							className="medr-small"
							style={{ fontSize: '1em' }}
							to="/user/send-reset-email"
						>
							Reset here
						</Link>
					</p>
				</div>
				<Row className="mt-4">
					<div className="col-12">
						<Button className="sel-button w100" type="submit" variant="primary">
							Sign In
						</Button>
					</div>
				</Row>
				<Row className="mt-3">
					<div className="col-12">
						<LinkContainer to="/register">
							<Button className="sel-button w100" variant="dark">
								Register
							</Button>
						</LinkContainer>
					</div>
				</Row>
			</Form>
		</div>
	);
}

export default connect(null, { login })(LoginScreen);
