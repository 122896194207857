import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { patientPropType } from '@constants/propTypes';
import {
	getNameString,
	reformatDateTime,
	getAddressMultiline,
} from '@actions/general';
import {
	deletePatientById,
	getCurrentPatient,
	getClinicianPatientList,
} from '@actions/patientActions';
import useUserPermissions from '@hooks/user/useUserPermissions';

function PatientBar({ patient }) {
	const dispatch = useDispatch();
	const {
		permisisonCreateReferrals,
		permisisonManageReferrals,
		// permissionViewReferrals
	} = useUserPermissions();

	if (!patient) {
		return <div id="referral_missing">Loading...</div>;
	}
	const { displayType, detailsImage } = patient;
	const showPatientImage = detailsImage !== null;

	const showView = {
		Added: {
			patientTitle: true,
			patientContact: true,
			patientInfo: true,
			patientImage: showPatientImage,
			seeReferralButton: false,
			seeReferOnwardButton: false,
			seeMakeReferralButton: true,
			seeUpdatePatientButton: true,
			seeDeletePatientButton: true,
		},
		Sent: {
			patientTitle: true,
			patientContact: true,
			patientInfo: true,
			patientImage: showPatientImage,
			seeReferralButton: true,
			seeReferOnwardButton: false,
			seeMakeReferralButton: false,
			seeUpdatePatientButton: true,
			seeDeletePatientButton: false,
		},
		Accepted: {
			patientTitle: true,
			patientContact: true,
			patientInfo: true,
			patientImage: showPatientImage,
			seeReferralButton: false,
			seeReferOnwardButton: true,
			seeMakeReferralButton: false,
			seeUpdatePatientButton: false,
			seeDeletePatientButton: false,
		},
		Pending: {
			patientTitle: false,
			patientContact: false,
			patientInfo: false,
			patientImage: false,
			seeReferralButton: false,
			seeReferOnwardButton: false,
			seeMakeReferralButton: false,
			seeUpdatePatientButton: false,
			seeDeletePatientButton: false,
		},
		Open: {
			patientTitle: false,
			patientContact: false,
			patientInfo: false,
			patientImage: false,
			seeReferralButton: false,
			seeReferOnwardButton: false,
			seeMakeReferralButton: false,
			seeUpdatePatientButton: false,
			seeDeletePatientButton: false,
		},
		Init: {
			patientTitle: false,
			patientContact: false,
			patientInfo: false,
			patientImage: false,
			seeReferralButton: false,
			seeReferOnwardButton: false,
			seeMakeReferralButton: false,
			seeUpdatePatientButton: false,
			seeDeletePatientButton: false,
		},
	};

	const [isOpen, setIsOpen] = useState(false);
	const handleClickOpen = () => setIsOpen(!isOpen);

	// const dispatch = useDispatch();
	const navigate = useNavigate();

	const [viewType, setViewType] = useState(showView[displayType]);

	const {
		patientTitle,
		patientContact,
		// patientImage,
		// seeReferralButton,
		seeReferOnwardButton,
		seeMakeReferralButton,
		seeUpdatePatientButton,
		seeDeletePatientButton,
	} = viewType;

	useEffect(() => {
		setViewType(showView[displayType]);
	}, [displayType]);

	const pxName = (hidden) => (
		<div className="flex-fill text-center referral-bar-item lines-max-2">
			{hidden ? (
				<h5 className="m-0 blur-text">Mr Example Person</h5>
			) : (
				<h5 className="m-0">{getNameString(patient)}</h5>
			)}
		</div>
	);

	const pxDob = (
		<div className="flex-fill text-center referral-bar-item lines-max-1">
			<p className="m-0">{reformatDateTime(patient.dob)}</p>
		</div>
	);

	const pxAge = (
		<div className="flex-fill text-center referral-bar-item lines-max-1">
			<p className="m-0">
				{patient.currentAge} {patient.sex}
			</p>
		</div>
	);

	const pxInfo = (
		<div className="flex-fill referral-bar-bottom">
			<h4 className="m-0 p-1 text-left">Info</h4>
			<p className="m-0  p-1 text-left">Sex: {patient.sex}</p>
			<p className="m-0  p-1 text-left">
				Age: {patient.currentAge.toFixed(0)} years old
			</p>
			<p className="m-0  p-1 text-left">
				Insurance:{' '}
				{patient.insurancePolicy
					? `${patient.insurancePolicy.provider}`
					: 'Self-payer'}
			</p>
		</div>
	);

	const pxContact = (
		<div className="flex-fill referral-bar-bottom">
			<h4 className="m-0 p-1 text-left">Contact details:</h4>
			<p className="m-0  p-1 text-left">
				<a href={`mailto:"${patient.primaryEmail}`}>{patient.primaryEmail}</a>
			</p>
			<p className="m-0  p-1 text-left">
				<a href={`tel:"${patient.primaryPhoneNumber}`}>
					{patient.primaryPhoneNumber}
				</a>
			</p>
		</div>
	);

	const pxAddress = (
		<div className="flex-fill referral-bar-bottom">
			<h4 className="m-0 p-1 text-left">Address:</h4>
			{getAddressMultiline(patient.address)}
		</div>
	);

	// BUTTON FUNCTIONS

	function makeReferButton() {
		// const onClick = () => navigate(`/referral/add/patientId/${patient.id}`);
		const onClick = (event) => {
			event.stopPropagation();
			dispatch(getCurrentPatient(patient.id));
			navigate(`/referral/add?patientId=${patient.id}`);
		};

		return (
			<div className="flex-fill text-center referral-bar-item">
				<Button
					className="sel-button w80"
					variant="success"
					onClick={onClick}
					disabled={!permisisonCreateReferrals}
				>
					Refer
				</Button>
			</div>
		);
	}
	function makeReferOnwardButton() {
		// const onClick = () => navigate(`/referral/add/patientId/${patient.id}`);
		const onClick = (event) => {
			event.stopPropagation();
			dispatch(getCurrentPatient(patient.id));
			navigate(`/referral/add?patientId=${patient.id}`);
		};

		return (
			<div className="flex-fill text-center referral-bar-item">
				<Button
					className="sel-button w80"
					variant="success"
					onClick={onClick}
					disabled={!permisisonCreateReferrals}
				>
					Refer on
				</Button>
			</div>
		);
	}

	function updateButton() {
		const onClick = (event) => {
			event.stopPropagation();
			navigate(`/patients/add?patientId=${patient.id}`);
		};

		return (
			<div className="flex-fill text-center referral-bar-item medr">
				<Button
					className="sel-button w80"
					variant="info"
					onClick={onClick}
					disabled={!permisisonManageReferrals}
				>
					Update
				</Button>
			</div>
		);
	}

	function deleteButton() {
		const deleteSuccess = (value) => {};

		const onClick = (event) => {
			event.stopPropagation();
			dispatch(deletePatientById(patient.id, deleteSuccess));
		};

		return (
			<div className="flex-fill text-center referral-bar-item">
				<Button
					className="sel-button w80"
					variant="danger"
					onClick={onClick}
					disabled={!permisisonManageReferrals}
				>
					Delete
				</Button>
			</div>
		);
	}

	return (
		<div
			className={`medr-rounded-inv obj-clickable ${isOpen ? 'bar-open' : ''}`}
			onClick={handleClickOpen}
			onKeyDown={handleClickOpen}
			role="button"
			tabIndex={0}
			id={`pxbar${patient.id}`}
		>
			<div className="d-flex align-items-center p-0 referral-bar">
				{pxName(!patientTitle)}
				{patientTitle ? pxDob : pxAge}
				<div className="d-flex align-items-center p-0 bar-buttons referral-bar-item-5">
					{seeMakeReferralButton ? makeReferButton() : null}
					{seeReferOnwardButton ? makeReferOnwardButton() : null}
					{seeUpdatePatientButton ? updateButton() : null}
					{seeDeletePatientButton ? deleteButton() : null}
				</div>
			</div>
			{isOpen ? (
				<div className="d-flex p-1 referral-info">
					{pxInfo}
					{patientContact ? pxContact : null}
					{patientContact ? pxAddress : null}
					{/* {seePxDetails ? pxContact : pxContactHidden}
                    {pxHistory}
                    {pxReason} */}
				</div>
			) : null}
		</div>
	);
}

PatientBar.propTypes = {
	patient: patientPropType.isRequired,
};

export default PatientBar;
