import React from "react";
import PropTypes from "prop-types";
import { Marker } from 'react-leaflet';
import './map.css';
import "leaflet/dist/leaflet.css";
import useTargetMarker from "./useTargetMarker";

function TargetMarker({ mapCenter }) {
    const {
        geocode,
        customIcon,
    } = useTargetMarker(mapCenter);

    return (
        <Marker 
            position={geocode}
            icon={customIcon}
            />
    );
}

TargetMarker.propTypes = {
    mapCenter: PropTypes.arrayOf(PropTypes.number).isRequired, 
}

export default TargetMarker;
