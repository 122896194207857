import React, { useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import InsuranceAgreementSearchBar from '../clinicians/InsuranceSearchBox/InsuranceAgreementSearchBar';
import GenderSearchBar from '../user/GenderSearchBar';
import {
	getCurrentPatient,
	postCreatePatient,
	updatePatientById,
} from '@actions/patientActions';
import TitleDropdown from '../user/TitleDropdown/TitleDropdown';
import { EmailFormField, PhoneFormField } from '../app/parts/FormFields';
import usePatientEntryForm from '@hooks/patients/usePatientEntryForm';
import useCurrentPatient from '@hooks/patients/useCurrentPatient';
// import { UPDATE_REFERRAL_CONTACTS } from '@constants/urls';

function PatientEntryForm({ inputPatientId = null, isSuccessful = null }) {
	const dispatch = useDispatch();

	const {
		patientFormData,
		isNewPatient,
		setIsNewPatient,
		resetFormData,
		loadPatientData,
		updatePatientAddress,
		updatePatientFormData,
		updatePatientConsent,
		setDOB,
		gpsError,
		isDOBValid,
		formChecks,
		formValid,
		getRequestData,
		getRequestPayload,
	} = usePatientEntryForm();

	const { currentPatient } = useCurrentPatient(inputPatientId);

	useEffect(() => {
		loadPatientData(currentPatient);
		if (!currentPatient || !currentPatient.id || currentPatient.id === 0) {
			setIsNewPatient(true);
			return;
		}
		setIsNewPatient(false);
	}, [currentPatient]);

	const {
		title,
		firstName,
		lastName,
		patientReference,
		dob,
		sex,
		primaryEmail,
		primaryPhoneNumber,
		address,
		consent,
		insuranceProviderId,
	} = patientFormData;

	const { firstLine, city, postcode } = address;

	const updateAddress = (e) =>
		updatePatientAddress({ [e.target.name]: e.target.value });
	const handleConsentChange = () => updatePatientConsent(!consent);

	const onSubmit = async (e) => {
		e.preventDefault();
		if (!consent || !formValid) return;

		if (isNewPatient) {
			// send request
			const requestFormData = getRequestData();
			await dispatch(postCreatePatient(requestFormData, isSuccessful));
			resetFormData();
		} else {
			const requestPayload = getRequestPayload();
			const ID = parseInt(patientFormData.id, 10);
			await dispatch(updatePatientById(ID, requestPayload, isSuccessful));
		}
	};

	return (
		<Form className="text-left">
			<div className="row">
				<div className="col-md-4 py-1">
					<Form.Group controlId="title">
						<Form.Label className="my-0">Title</Form.Label>
						<TitleDropdown title={title} sendTitle={updatePatientFormData} />
						{!formChecks.title ? (
							<p className="medr-invalid-text">Please select a title</p>
						) : null}
					</Form.Group>
				</div>
				<div className="col-md-8 py-1">
					<Form.Group controlId="firstName">
						<Form.Label className="my-0">First name (Required) </Form.Label>
						<Form.Control
							required
							type="name"
							placeholder="Enter first name"
							name="firstName"
							value={firstName}
							onChange={(e) =>
								updatePatientFormData({ [e.target.name]: e.target.value })
							}
							isInvalid={!formChecks.firstName}
						/>
						<Form.Control.Feedback type="invalid">
							Please enter a first name.
						</Form.Control.Feedback>
					</Form.Group>
				</div>
			</div>
			<div className="row">
				<div className="col-md-4 py-1">
					<Form.Group controlId="gender">
						<Form.Label className="my-0">Sex</Form.Label>
						<GenderSearchBar gender={sex} changeValue={updatePatientFormData} />
					</Form.Group>
					{!formChecks.sex ? (
						<p className="medr-invalid-text">Please select a sex</p>
					) : null}
				</div>
				<div className="col-md-8 py-1">
					<Form.Group controlId="lastName" className="mb-3">
						<Form.Label className="my-0">Last name (Required) </Form.Label>
						<Form.Control
							required
							type="name"
							placeholder="Enter last name"
							name="lastName"
							value={lastName}
							onChange={(e) =>
								updatePatientFormData({ [e.target.name]: e.target.value })
							}
							isInvalid={!formChecks.lastName}
						/>
						<Form.Control.Feedback type="invalid">
							Please enter a last name.
						</Form.Control.Feedback>
					</Form.Group>
				</div>
			</div>
			<div className="row">
				<div
					className="col-md-4 py-1"
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-end',
					}}
				>
					<Form.Group controlId="dob" className="mb-3">
						<Form.Label className="my-0">DOB (Required) </Form.Label>
						<Form.Control
							required
							type="date"
							name="dob"
							value={dob}
							onChange={(e) => setDOB(e)}
							isInvalid={!isDOBValid || !formChecks.dob}
						/>
						<Form.Control.Feedback type="invalid">
							{!isDOBValid
								? 'Check patient is over 18 years old.'
								: 'Please enter a date of birth.'}
						</Form.Control.Feedback>
					</Form.Group>
				</div>
				<div className="col-md-8 py-1">
					<Form.Group controlId="patientReference" className="mb-3">
						<Form.Label className="my-0">Patient Reference</Form.Label>
						<Form.Control
							type="name"
							placeholder="Enter patient reference"
							name="patientReference"
							value={patientReference}
							onChange={(e) =>
								updatePatientFormData({ [e.target.name]: e.target.value })
							}
						/>
					</Form.Group>
				</div>
			</div>

			<div className="mb-3">
				<EmailFormField
					email={primaryEmail}
					emailLabel="Patient Email (Required)"
					emailKey="primaryEmail"
					changeValues={updatePatientFormData}
				/>
				{!formChecks.email ? (
					<p className="medr-invalid-text">Please enter email</p>
				) : null}
			</div>
			<div className="mb-3">
				<PhoneFormField
					currentPhoneNo={primaryPhoneNumber}
					nameKey="primaryPhoneNumber"
					nameLabel="Phone number (Required)"
					changeValues={updatePatientFormData}
				/>
				{!formChecks.phone ? (
					<p className="medr-invalid-text">Please enter phone number</p>
				) : null}
			</div>

			<Form.Group controlId="firstLine" className="mb-3">
				<Form.Label className="my-0">Address First Line</Form.Label>
				<Form.Control
					type="name"
					placeholder="Enter first line of address"
					name="firstLine"
					value={firstLine}
					onChange={updateAddress}
				/>
			</Form.Group>

			<Form.Group controlId="city" className="mb-3">
				<Form.Label className="my-0">Address City</Form.Label>
				<Form.Control
					type="name"
					placeholder="Enter city/town"
					name="city"
					value={city}
					onChange={updateAddress}
				/>
			</Form.Group>

			<div className="row mb-3">
				<div className="col-md-6">
					<Form.Group controlId="postcode">
						<Form.Label className="my-0">
							Address Postcode (Required){' '}
						</Form.Label>
						<Form.Control
							required
							type="name"
							placeholder="Enter postcode"
							name="postcode"
							value={postcode}
							onChange={updateAddress}
							isInvalid={!formChecks.postcode || gpsError}
						/>
						<Form.Control.Feedback type="invalid">
							{gpsError ? 'Address not found' : 'Please enter a postcode.'}
						</Form.Control.Feedback>
					</Form.Group>
				</div>
				<div className="col-md-6">
					<Form.Group controlId="insurance_provider">
						<Form.Label className="my-0">
							Insurance provider (Required)
						</Form.Label>
						<InsuranceAgreementSearchBar
							agreement={insuranceProviderId}
							changeValues={updatePatientFormData}
						/>
					</Form.Group>
					{!formChecks.insuranceProviderId ? (
						<p className="medr-invalid-text">Please enter insurance status</p>
					) : null}
				</div>
			</div>

			<Row className="text-left mt-4">
				<Col className="my-0">
					<Form.Group controlId="consent" className="mb-3">
						<Form.Label className="my-0 custom-checkbox w100 text-left">
							<Form.Check className="custom-hidden-checkbox">
								<input
									type="checkbox"
									data-testid="consent-checkbox"
									id="consent"
									checked={consent}
									onChange={handleConsentChange}
								/>
								<div className="d-flex align-items-center">
									<i className="fa-regular fa-square-check big-checkbox custom-unchecked" />
									<i className="fa-solid fa-square-check big-checkbox custom-checked" />
									Please confirm patient has given consent
								</div>
							</Form.Check>
						</Form.Label>
					</Form.Group>
				</Col>
			</Row>
			{!formChecks.consent ? (
				<p className="medr-invalid-text">
					Please confirm patient has given consent
				</p>
			) : null}

			<Row className="text-center mt-4">
				<Col>
					<Button
						className="sel-button w80"
						variant="primary"
						onClick={onSubmit}
						disabled={!consent}
					>
						{isNewPatient ? 'Add Patient' : 'Update Patient'}
					</Button>
				</Col>
			</Row>
		</Form>
	);
}

PatientEntryForm.propTypes = {
	isSuccessful: PropTypes.func,
	inputPatientId: PropTypes.number,
};

export default PatientEntryForm;
