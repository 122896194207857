export const PLATFORM_STATS_REQUEST = 'PLATFORM_STATS_REQUEST';
export const PLATFORM_STATS_SUCCESS = 'PLATFORM_STATS_SUCCESS';
export const PLATFORM_STATS_FAIL = 'PLATFORM_STATS_FAIL';

export const ADMIN_USERS_LIST_REQUEST = 'ADMIN_USERS_LIST_REQUEST';
export const ADMIN_USERS_LIST_SUCCESS = 'ADMIN_USERS_LIST_SUCCESS';
export const ADMIN_USERS_LIST_FAIL = 'ADMIN_USERS_LIST_FAIL';

export const ADMIN_PATIENTS_LIST_REQUEST = 'ADMIN_PATIENTS_LIST_REQUEST';
export const ADMIN_PATIENTS_LIST_SUCCESS = 'ADMIN_PATIENTS_LIST_SUCCESS';
export const ADMIN_PATIENTS_LIST_FAIL = 'ADMIN_PATIENTS_LIST_FAIL';

export const ADMIN_REFERRALS_LIST_REQUEST = 'ADMIN_REFERRALS_LIST_REQUEST';
export const ADMIN_REFERRALS_LIST_SUCCESS = 'ADMIN_REFERRALS_LIST_SUCCESS';
export const ADMIN_REFERRALS_LIST_FAIL = 'ADMIN_REFERRALS_LIST_FAIL';

export const ADMIN_CLINICIANS_LIST_REQUEST = 'ADMIN_CLINICIANS_LIST_REQUEST';
export const ADMIN_CLINICIANS_LIST_SUCCESS = 'ADMIN_CLINICIANS_LIST_SUCCESS';
export const ADMIN_CLINICIANS_LIST_FAIL = 'ADMIN_CLINICIANS_LIST_FAIL';

export const ADMIN_SET_USER_VERIFIED_REQUEST =
	'ADMIN_SET_USER_VERIFIED_REQUEST';
export const ADMIN_SET_USER_VERIFIED_SUCCESS =
	'ADMIN_SET_USER_VERIFIED_SUCCESS';
export const ADMIN_SET_USER_VERIFIED_FAIL = 'ADMIN_SET_USER_VERIFIED_FAIL';

export const REQUEST_NEW_FEATURE_REQUEST = 'REQUEST_NEW_FEATURE_REQUEST';
export const REQUEST_NEW_FEATURE_SUCCESS = 'REQUEST_NEW_FEATURE_SUCCESS';
export const REQUEST_NEW_FEATURE_FAIL = 'REQUEST_NEW_FEATURE_FAIL';

export const ADMIN_INSURERS_REQUEST = 'ADMIN_INSURERS_REQUEST';
export const ADMIN_INSURERS_SUCCESS = 'ADMIN_INSURERS_SUCCESS';
export const ADMIN_INSURERS_FAIL = 'ADMIN_INSURERS_FAIL';

export const ADMIN_INSURER_VERIFY_REQUEST = 'ADMIN_INSURER_VERIFY_REQUEST';
export const ADMIN_INSURER_VERIFY_SUCCESS = 'ADMIN_INSURER_VERIFY_SUCCESS';
export const ADMIN_INSURER_VERIFY_FAIL = 'ADMIN_INSURER_VERIFY_FAIL';
