import { useState, useEffect } from 'react';


const useTitleDropdown = (title) => {
    const titles = ['Mr', 'Mrs', 'Ms', 'Dr'];
	const [titleOptions, setTitleOptions] = useState([]);
    const currentTitle = title || 'Select title';

    useEffect(() => {
        const opts = titles.map((title_) => ({label: title_, value: title_}));
        setTitleOptions(opts);
    }, []);

    const customStyles = {
        singleValue:(provided) => ({
          ...provided,
          color:'#247986',
        }),
      }
    return {
        titleOptions,
        customStyles,
        currentTitle
    }
};
export default useTitleDropdown;