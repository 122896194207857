import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { getUserAuthFromStorage } from './storageActions';

export function RedirectIfAuthDetailsAvailable() {
    const navigate = useNavigate();
    const authToken = Cookies.get('authToken');
    const isAuthenticated = authToken !== null && authToken !== undefined;

    useEffect(() => {
        // Check authentication status and navigate if authenticated
        if (isAuthenticated) {
            navigate('/dashboard');
        }
    }, [isAuthenticated, navigate]);

    return isAuthenticated;
};

export function CheckAuthFromStorage() {
    const authToken = Cookies.get('authToken');
    const isAuthenticated = authToken !== null && authToken !== undefined;
    return isAuthenticated;
}

export function CheckAdminAuthFromStorage() {
    const authToken = Cookies.get('authToken');
    const isAuthenticated = authToken !== null && authToken !== undefined;

    const auth = getUserAuthFromStorage();
    if(auth === null || auth === undefined) return false;
    
    const isAuthAdmin = auth.isAdmin && isAuthenticated;
    return isAuthAdmin;
}