import React, { useEffect, useState } from "react";
import { connect, useDispatch } from 'react-redux';
import Select from 'react-select';
import { funcPropType, clinicsPropType } from '@constants/propTypes';
import { getClinicsList } from '@actions/clinicianActions';


function AddressSearchBox({ changeValue, clinics }){

    const [options, setOptions] = useState([]);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getClinicsList());
    }, [dispatch]);

    useEffect(() => {
        if (clinics===null || clinics===undefined || clinics.length===0){
            return;
        }
        const clinicOptions = clinics.map((clinic) => ({
            label: clinic.centreName,
            value: clinic.id
        }));
        setOptions(clinicOptions);
    }, [clinics]);


    const sendValues = (id) => {
        const clinicSelected = clinics.find(clinic => clinic.id === id);
        if (clinicSelected===undefined){
            return;
        }
        const data = {
            centreName: clinicSelected.centreName,
            address: {
                addressType: clinicSelected.address.addressType,
                firstLine: clinicSelected.address.firstLine,
                city: clinicSelected.address.city,
                postcode: clinicSelected.address.postcode,
            }
        }
        changeValue(data);
    }

    return (
        <Select
            options={options}
            onChange={opt => sendValues(opt.value)}
            placeholder="Search for a clinic"
        />
    )
}

AddressSearchBox.propTypes = {
    changeValue: funcPropType.isRequired,
    clinics: clinicsPropType.isRequired,
};

const mapStateToProps = state => ({
    clinics: state.clinicians.doctor_clinics,
})

export default connect(mapStateToProps, {})(AddressSearchBox);