export const SET_PATIENT_REQUEST = 'SET_PATIENT_REQUEST';
export const SET_PATIENT_SUCCESS = 'SET_PATIENT_SUCCESS';
export const SET_PATIENT_FAIL = 'SET_PATIENT_FAIL';

export const SET_PATIENT_NULL_REQUEST = 'SET_PATIENT_NULL_REQUEST';
export const SET_PATIENT_NULL_SUCCESS = 'SET_PATIENT_NULL_SUCCESS';
export const SET_PATIENT_NULL_FAIL = 'SET_PATIENT_NULL_FAIL';

export const SET_CRP_REQUEST = 'SET_CRP_REQUEST';
export const SET_CRP_SUCCESS = 'SET_CRP_SUCCESS';
export const SET_CRP_FAIL = 'SET_CRP_FAIL';

export const SET_CRP_NULL_REQUEST = 'SET_CRP_NULL_REQUEST';
export const SET_CRP_NULL_SUCCESS = 'SET_CRP_NULL_SUCCESS';
export const SET_CRP_NULL_FAIL = 'SET_CRP_NULL_FAIL';

export const JOB_ROLE_SET_ID_REQUEST = 'JOB_ROLE_SET_ID_REQUEST';
export const JOB_ROLE_SET_ID_SUCCESS = 'JOB_ROLE_SET_ID_SUCCESS';
export const JOB_ROLE_SET_ID_FAIL = 'JOB_ROLE_SET_ID_FAIL';

export const SET_JOB_ROLE_REQUEST = 'SET_JOB_ROLE_REQUEST';
export const SET_JOB_ROLE_SUCCESS = 'SET_JOB_ROLE_SUCCESS';
export const SET_JOB_ROLE_FAIL = 'SET_JOB_ROLE_FAIL';

export const SET_JOB_ROLE_NULL_REQUEST = 'SET_JOB_ROLE_NULL_REQUEST';
export const SET_JOB_ROLE_NULL_SUCCESS = 'SET_JOB_ROLE_NULL_SUCCESS';
export const SET_JOB_ROLE_NULL_FAIL = 'SET_JOB_ROLE_NULL_FAIL';

export const SET_POSTCODE_REQUEST = 'SET_POSTCODE_REQUEST';
export const SET_POSTCODE_SUCCESS = 'SET_POSTCODE_SUCCESS';
export const SET_POSTCODE_FAIL = 'SET_POSTCODE_FAIL';

export const SET_GPS_REQUEST = 'SET_GPS_REQUEST';

export const DEFAULT_LAT = 51.501162;
export const DEFAULT_LNG = -0.141745;

export const SET_CLINICIAN_REQUEST = 'SET_CLINICIAN_REQUEST';
export const SET_CLINICIAN_SUCCESS = 'SET_CLINICIAN_SUCCESS';
export const SET_CLINICIAN_FAIL = 'SET_CLINICIAN_FAIL';

export const SET_CLINICIAN_NULL_REQUEST = 'SET_CLINICIAN_NULL_REQUEST';
export const SET_CLINICIAN_NULL_SUCCESS = 'SET_CLINICIAN_NULL_SUCCESS';
export const SET_CLINICIAN_NULL_FAIL = 'SET_CLINICIAN_NULL_FAIL';
