export const initUser = {
    id: null,
    username: "",
    email: "",
    first_name: "",
    last_name: "",
    is_staff: false,
};

export const initJobRole ={
    id: null,
    jobRole: "",
    specialty: "",
    registrationBody: "",
};

export const initUserProfile = {
    user: initUser,
    jobRole: initJobRole,
    title:"",
    firstName:"",
    lastName:"",
    profilePicture:"",
    isPatient:false,
    registrationNumber:"",
};

export const initPatient = {
    id: null,
    createdBy:initUserProfile,
    currentAge:null,
    viewType:"",
    detailsInImage:false,
    title:"",
    firstName:"",
    lastName:"",
    patientReference:"",
    sex:"",
    dob:"",
    primaryEmail:"",
    primaryPhoneNumber:"",
    address: {
        firstLine:"",
        city:"",
        postcode:"",
        longitude:null,
        latitude:null,
    },
    detailsImage:"",
    patientActive:false,
    createdOn:"",
    isSelfPayer:true,
    insurancePolicy:null
};

export const initCRP = {
    id:null,
    userProfile:initUserProfile,
    centreName:"",
    addressFirstLine:"",
    addressCity:"",
    addressPostcode:"",
    addressLongitude:null,
    addressLatitude:null,
}

export const initReferral = {
    id:null,
    patient:initPatient,
    referrer:initCRP,
    specifiedClinician:false,
    targetClinician:initCRP,
    mainDescription:"",
    histoy:"",
    isPrivateView:true,
    targetHasAccepted:false,
    createdOn:""
}

export const initCrpFormData = {
    centreName: "", 
    addressFirstLine: "", 
    addressCity: "", 
    addressPostcode: ""
};

export const initPostcode = {
    lat: 51.5074,
    lng: 0.1278,
    zoom: 11,
}