import React from "react";
import { Modal, Button } from "react-bootstrap";
// import { v4 as uuidv4 } from 'uuid';
import Avatar from 'react-avatar-edit';
import { userProfilePropType, boolPropType, funcPropType } from '@constants/propTypes';
import useProfilePicture from "./useProfilePicture";
import usePictureUploadModal, { checkProfileUrlString } from "./usePictureUploadModal";

function PictureUploadModal({ show, handleClose, userProfile }) {

    const {
        imageSrc,
        preview,
        bgColor,
        onBeforeFileLoad,
        onCrop,
        onClose,
        saveImage
    } = usePictureUploadModal(userProfile);

    return (
        <Modal size="l" show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title className='medr-text-inv'>Upload Profile Picture</Modal.Title>
            </Modal.Header>
            <Modal.Body className='text-center'>
                <div className="avatar-container">
                    <Avatar 
                        width={400}
                        height={300}
                        onBeforeFileLoad={onBeforeFileLoad}
                        onCrop={onCrop}
                        onClose={onClose}
                        src={imageSrc}
                        shadingColor={bgColor}
                        backgroundColor={bgColor}
                        />
                </div>
                <div className="below-avatar-container">
                    <Button variant="info" onClick={saveImage} disabled={!preview}>Upload</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

PictureUploadModal.propTypes = {
    show: boolPropType.isRequired,
    handleClose: funcPropType.isRequired,
    userProfile: userProfilePropType.isRequired,
};

function ProfilePictureDisplay({ userProfile, editable = false }) {
    const { 
        profilePicturePath,
        uploadPicture,
        uploadPictureKeyPress,
        showPicUpload,
        setShowPicUpload
    } = useProfilePicture(userProfile, editable);

    return (
        <div className='col-auto mb-2'>
            <div className='profile-picture-container'>
                {profilePicturePath ? 
                    <img className="profile-picture" src={checkProfileUrlString(userProfile.profilePicture)} alt="Profile" />
                    : <i className="fa-solid fa-user profile-picture-icon"/>
                }
                {editable && <i className="fa-solid fa-camera  camera-update-icon"
                                onClick={uploadPicture}
                                onKeyDown={uploadPictureKeyPress}
                                role="button"
                                tabIndex="0"
                                aria-label="Edit Profile Picture"
                                />}
                <PictureUploadModal show={showPicUpload} handleClose={() => setShowPicUpload(false)} userProfile={userProfile} />
            </div>
        </div>
  );
}

ProfilePictureDisplay.propTypes = {
    userProfile: userProfilePropType.isRequired,
    editable: boolPropType,
};


export default ProfilePictureDisplay;