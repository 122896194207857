import { useState, useEffect } from "react";

const useAdminCliniciansList = (cliniciansList, start, end) => {
    const [clinicians, setClinicians] = useState([]);
    const [startSlice, setStartSlice] = useState(start);
    const [endSlice, setEndSlice] = useState(end);
    const cliniciansLength = cliniciansList.length;

    const updateSlice = (startInd, endInd) => {
        setStartSlice(startInd);
        setEndSlice(endInd);
    };

    useEffect(() => {
        if (!cliniciansList || cliniciansList.length === 0) {
            setClinicians([]);
        }
        const slice = cliniciansList.slice(startSlice, endSlice);
        setClinicians(slice);
    }, [clinicians, startSlice, endSlice]);
    
    return [clinicians, updateSlice, cliniciansLength];
};

export default useAdminCliniciansList;