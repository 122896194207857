import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { getNameString } from '@actions/general';
import UserAnalyticsSummary from '@components/user/UserAnalyticsSummary';
// import UpdateProfilePopup from '@components/user/UpdateProfilePopup';
import AppUpdatesPanel from '@components/app/AppUpdatesPanel';
import useDelegateUser from '@hooks/user/useDelegateUser';

function DashboardDelegate() {
	const navigate = useNavigate();
	// const [displayReminder, setDisplayReminder] = useState(false);

	const { userProfile, clinicianUserProfile } = useDelegateUser();

	// useEffect(() => {
	// 	if (!userProfile || userProfile === undefined) return;
	// 	const { clinicianDescription, profilePicture } = userProfile;
	// 	setDisplayReminder(
	// 		clinicianDescription === '' ||
	// 			profilePicture.includes('profile-ppr-default')
	// 	);
	// }, [userProfile]);

	const handleNavigationNewReferral = () => navigate('/referral/add');
	const handleNavigationViewReferrals = () => navigate('/referrals');

	return (
		<>
			{/* <UpdateProfilePopup
				userProfile={userProfile}
				showModal={displayReminder}
				handleClose={() => setDisplayReminder(false)}
			/> */}
			<Row className="medr-layout text-center">
				<Col>
					<h4>
						Welcome {userProfile && getNameString(userProfile)}, this is a
						delegate account for{' '}
						{clinicianUserProfile && getNameString(clinicianUserProfile)}
					</h4>
				</Col>
			</Row>
			<Row className="medr-layout text-center">
				<Col>
					<Button
						type="button"
						onClick={handleNavigationNewReferral}
						className="sel-button w100"
					>
						Make referral
					</Button>
				</Col>
				<Col>
					<Button
						type="button"
						onClick={handleNavigationViewReferrals}
						className="sel-button w100"
					>
						View referrals
					</Button>
				</Col>
			</Row>

			<Row>
				<Col xs={12} md={9}>
					<UserAnalyticsSummary />
				</Col>
				<Col xs={12} md={3}>
					<AppUpdatesPanel />
				</Col>
			</Row>
		</>
	);
}

export default DashboardDelegate;
