import { useState, useEffect } from "react";

const useGeoLocation = (viewLocation) => {
    const [userLocation, setUserLocation] = useState({
        loaded: false,
        coordinates: { lat: "", lng: "" },
        mapCenter: [0, 0],
    });
    const [location, setLocation] = useState({
        loaded: false,
        coordinates: { lat: "", lng: "" },
        mapCenter: [0, 0],
    });


    const onSuccess = (currentLocation) => {
        setUserLocation({
            loaded: true,
            coordinates: {
                lat: currentLocation.coords.latitude,
                lng: currentLocation.coords.longitude,
            },
            mapCenter: [currentLocation.coords.latitude, currentLocation.coords.longitude],
        });
    };

    const onError = (error) => {
        setUserLocation({
            loaded: true,
            error,
        });
    }

    useEffect(() => {
        if (!("geolocation" in navigator)) {
            onError({
                code: 0,
                message: "Geoloation not supported"
            },)
        }

        navigator.geolocation.getCurrentPosition(onSuccess, onError);
    }, []);

    useEffect(() => {
        if (!viewLocation || viewLocation.searchLatitude === 0 || viewLocation.searchLongitude === 0) return;
        if (location.mapCenter[0] === viewLocation.searchLatitude && location.mapCenter[1] === viewLocation.searchLongitude) return;
        setLocation({
            loaded: true,
            coordinates: {
                lat: viewLocation.searchLatitude,
                lng: viewLocation.searchLongitude,
            },
            mapCenter: [viewLocation.searchLatitude, viewLocation.searchLongitude],
        });
    }, [viewLocation]);
    
    useEffect(() => {
        if (!userLocation.loaded || userLocation.error) return;
        if (location.mapCenter[0] === userLocation.mapCenter[0] && location.mapCenter[1] === userLocation.mapCenter[1]) return;
        if (viewLocation && viewLocation.searchLatitude === location.mapCenter[0] && viewLocation.searchLongitude === location.mapCenter[1]) return;
        setLocation({
            loaded: true,
            coordinates: {
                lat: userLocation.mapCenter[0],
                lng: userLocation.mapCenter[1],
            },
            mapCenter: userLocation.mapCenter,
        });

    }, [userLocation]);

    return { 
        location
    };
}

export default useGeoLocation;