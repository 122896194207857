import { combineReducers } from 'redux';
import message from './messageReducer';
import admin from './adminReducer';
import auth from './authReducer';
import profile from './profileReducer';
import patients from './patientReducer';
import referrals from './referralReducer';
import clinicians from './cliniciansReducer';
import search from './searchReducers';

const reducer = combineReducers({
	message,
	auth,
	admin,
	profile,
	patients,
	referrals,
	clinicians,
	search,
});

export default reducer;
