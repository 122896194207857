import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import  { referralPropType, boolPropType, funcPropType } from '@constants/propTypes';

function ReferralDetailsPopup({ referral, showModal, handleClose }){
    if (!referral || referral===undefined) {
        return null;
    }

    const { history, mainDescription } = referral;

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className='bluegreen'>Referral information</Modal.Title>
            </Modal.Header>
            <Modal.Body  className='bluegreen'>
                <h4 className='bluegreen'>History</h4>
                <p>{history}</p>
                <h4 className='bluegreen'>Reason for referral</h4>
                <p>{mainDescription}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button className='sel-button mt-2 w80' variant="info" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

ReferralDetailsPopup.propTypes = {
    referral: referralPropType.isRequired,
    showModal: boolPropType.isRequired,
    handleClose: funcPropType.isRequired
}

export default ReferralDetailsPopup;