import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { clinicianProfilesPropType } from '@constants/propTypes';
import { getClinicianByUserId } from '@actions/clinicianActions';
import UserClinicianProfiles from '@components/clinicians/UserClinicianProfiles';


function UserCrpsScreen({ crps }) {

	const { id } = useParams();
	const dispatch = useDispatch();
	const updateClinicianInfo = () => {
		const ID = parseInt(id, 10);
		dispatch(getClinicianByUserId(ID));
	}
	useEffect(() => {
		updateClinicianInfo();
	}, []);
	
	return (
		<div>
			<Row className="text-center py-1">
				<Col>
					<h1>Clinician Information</h1>
				</Col>
			</Row>
			<div className="d-flex justify-content-center">
				<UserClinicianProfiles 
					crps={crps} 
					/>
			</div>
		</div>
	);
}

UserCrpsScreen.propTypes = {
	crps: clinicianProfilesPropType.isRequired,
};

const mapStateToProps = (state) => ({
	crps: state.clinicians.clinicianCrps,
});

export default connect(mapStateToProps, {})(UserCrpsScreen);
