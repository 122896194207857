import { Icon } from 'leaflet';
import iconUrl from './target-marker-icon.png';

const useTargetMarker = (mapCenter) => {

    const geocode = mapCenter;

    const customIcon = new Icon({
        iconUrl: iconUrl,
        iconSize: [25, 25],
    });

    return {
        geocode,
        customIcon,
    }
}

export default useTargetMarker;