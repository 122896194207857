import React, { useEffect } from "react";
import { Row, Col } from 'react-bootstrap';
import PatientSearchBar from "../../patient/PatientSearchBar";
import PatientEntryForm from "../../patient/PatientEntryForm";
import { boolPropType, funcPropType, numberPropType } from '@constants/propTypes';

function ReferralStepPatient({ currentPatientId = null, patientSelected = false, addNewPatientSuccess }){
    useEffect(() => {window.scroll(0,0)}, []);

    return (
		<div className='medr-layout text-center newpatient-page' style={{maxWidth:'700px', marginLeft: 'auto', marginRight: 'auto'}}>
            <Row>
                <Col>
                    <h4>Select patient to refer</h4>
                </Col>
            </Row>
            
            <PatientSearchBar currentPatientId={currentPatientId}/>

            <Row className='mt-5'>
                <Col>
                    <h4>{patientSelected===false ? "Or add new patient" : "Update selected patient"}</h4>
                </Col>
            </Row>
            <PatientEntryForm inputPatientId={currentPatientId} isSuccessful={addNewPatientSuccess} />
        </div>

    )
};

ReferralStepPatient.propTypes = {
    currentPatientId: numberPropType,
    patientSelected: boolPropType,
    addNewPatientSuccess: funcPropType.isRequired,
};


export default ReferralStepPatient;
