import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSearchGps } from '@actions/searchActions';
import { getCliniciansList } from '@actions/clinicianActions';
import useJobRoles from '@hooks/clinicians/useJobRoles';

const useReferralForm = (pxIsReady) => {
	const dispatch = useDispatch();

	const initData = {
		// Step 1
		selectedPatient: false,
		isNewPatient: false,
		patientId: 0,
		currentPatient: null,
		currentPostcode: '',
		searchLatitude: 0,
		searchLongitude: 0,
		insuranceProviderId: 0,

		// Step 2
		patientHistory: '',
		mainDescription: '',

		// Step 3
		jobRoleId: 0,
		jobRole: {},
		selectedJobRole: null,
		selectedJobSpecialty: null,
		selectedJobSubSpecialty: null,
		specifiedClinician: null,
		targetClinicianId: null,
		targetClinician: null,
	};
	const currentPx = useSelector((state) => state.patients.currentPatient);
	const [step2Ready, setStep2Ready] = useState(pxIsReady);
	const [referralData, setReferralData] = useState(initData);
	const [pxAdded, setPxAdded] = useState(false);
	const updateReferralData = (data) =>
		setReferralData((prevState) => ({ ...prevState, ...data }));

	// step management
	const [activeStep, setActiveStep] = useState(0);
	const minStep = 0;
	const maxStep = 3;

	const handleNext = () =>
		activeStep < maxStep
			? setActiveStep((previousStep) => previousStep + 1)
			: null;
	const handleNextTo = (step) => setActiveStep(step);
	const handlePrevious = () => {
		if (activeStep > minStep) {
			if (activeStep === maxStep) {
				setReferralData({ ...referralData, isReview: false });
			}
			setActiveStep((previousStep) => previousStep - 1);
		}
	};

	// patient

	const updatePatient = () => {
		if (!step2Ready) {
			if (currentPx && !currentPx.id) {
				updateReferralData({
					selectedPatient: false,
					patientId: 0,
					currentPatient: null,
					currentPostcode: '',
					searchLatitude: 0,
					searchLongitude: 0,
					insuranceProviderId: 0,
				});
				setStep2Ready(true);
			}

			return;
		}

		if (
			!currentPx ||
			currentPx === undefined ||
			!currentPx.id ||
			currentPx.id === 0
		) {
			updateReferralData({
				selectedPatient: false,
				patientId: 0,
				currentPatient: null,
				currentPostcode: '',
				searchLatitude: 0,
				searchLongitude: 0,
				insuranceProviderId: 0,
			});
			return;
		}
		if (currentPx.id === referralData.patientId) return;

		const { address, isSelfPayer, insurancePolicy } = currentPx;
		const insId = isSelfPayer ? 0 : insurancePolicy.id;
		updateReferralData({
			selectedPatient: true,
			patientId: currentPx.id,
			currentPatient: currentPx,
			currentPostcode: address.postcode,
			searchLatitude: address.latitude,
			searchLongitude: address.longitude,
			insuranceProviderId: insId,
		});
		dispatch(
			setSearchGps(address.postcode, address.latitude, address.longitude)
		);
		handleNext();
		setPxAdded(true);
	};

	useEffect(() => updatePatient(), [currentPx]);

	// target clinician
	const {
		jobRoleId,
		insuranceProviderId,
		searchLatitude,
		searchLongitude,
		selectedJobRole,
		selectedJobSpecialty,
	} = referralData;

	const refreshClinicians = () =>
		dispatch(
			getCliniciansList(
				jobRoleId,
				insuranceProviderId,
				searchLatitude,
				searchLongitude,
				selectedJobRole,
				selectedJobSpecialty
			)
		);

	useEffect(() => {
		if (activeStep == 2) {
			refreshClinicians();
		}
	}, [
		jobRoleId,
		insuranceProviderId,
		searchLatitude,
		searchLongitude,
		selectedJobRole,
		selectedJobSpecialty,
		activeStep,
	]);

	const setTargetClinicianOpen = () => {
		if (referralData.jobRoleId === 0) return;
		updateReferralData({
			specifiedClinician: false,
			targetClinicianId: 0,
			targetClinician: null,
		});
		handleNext();
	};

	const { getJobRoleById, getJobRoleParams } = useJobRoles();

	const setTargetClinician = (clinician) => {
		if (!clinician || clinician === undefined || !clinician.id) return;

		// NO job role set
		if (jobRoleId === 0) {
			const jrParams = getJobRoleParams(clinician.userProfile.jobRole[0]);
			const jr = getJobRoleById(jrParams.jobRoleId);
			updateReferralData({
				...jrParams,
				jobRole: jr,
				specifiedClinician: true,
				targetClinician: clinician,
				targetClinicianId: clinician.id,
				selectedJobRole: jr.jobRole,
				selectedJobSpecialty: jr.specialty === 'nan' ? '' : jr.specialty,
				selectedJobSubSpecialty:
					jr.subSpecialty === 'nan' ? '' : jr.subSpecialty,
			});

			// job role set
		} else {
			const job = clinician.userProfile.jobRole.filter(
				(jr) => jr.id === jobRoleId
			);
			// return if not selected job
			if (!job || job === undefined || job.length === 0) {
				// console.log('jr: ', jobRoleId);
				return;
			}
			updateReferralData({
				specifiedClinician: true,
				targetClinician: clinician,
				targetClinicianId: clinician.id,
			});
		}
		handleNext();
	};

	// request

	const validateReferralData = () => {
		const errorReturn = { valid: false, data: null };

		const {
			currentPatient,
			patientHistory,
			mainDescription,
			jobRole,
			targetClinician,
			specifiedClinician,
		} = referralData;
		const insuranceProvider =
			currentPatient?.insurancePolicy?.provider ?? 'Self-Payer';

		if (
			currentPatient === null ||
			currentPatient === undefined ||
			patientHistory === null ||
			patientHistory === undefined ||
			mainDescription === null ||
			mainDescription === undefined ||
			jobRole === null ||
			jobRole === undefined ||
			specifiedClinician === null ||
			specifiedClinician === undefined
		)
			return errorReturn;

		if (
			specifiedClinician === true &&
			(targetClinician === null || targetClinician === undefined)
		)
			return errorReturn;

		const data = {
			currentPatient,
			patientHistory,
			mainDescription,
			jobRole,
			targetClinician,
			specifiedClinician,
			insuranceProvider,
		};

		return { valid: true, data };
	};

	const getRequestData = (crpId) => {
		let requestData = {
			patientId: referralData.patientId,
			crpId,
			jobRoleId: referralData.jobRole.id,
			mainDescription: referralData.mainDescription,
			patientHistory: referralData.patientHistory,
			specifiedClinician: referralData.specifiedClinician,
		};

		if (referralData.specifiedClinician) {
			requestData = {
				...requestData,
				receiverCrpId: referralData.targetClinician.id,
			};
		}
		return requestData;
	};

	// useEffect(() => {
	// 	console.log('referralData: ', referralData);
	// }, [referralData]);

	return {
		referralData,
		updateReferralData,
		activeStep,
		handleNext,
		handleNextTo,
		handlePrevious,
		validateReferralData,
		getRequestData,
		// setPatient,
		setTargetClinicianOpen,
		setTargetClinician,
		refreshClinicians,
		pxAdded,
	};
};

export default useReferralForm;
