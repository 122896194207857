import Cookies from "js-cookie";

export const getUserAuthFromStorage = () => {

    const authStr = localStorage.getItem('auth');
    if (!authStr) {return null;}

    const auth = JSON.parse(authStr);
    if (!auth || !auth.user) {return null;} // throw new Error('Invalid auth data structure');}

    return auth;
};


export const getUserProfileFromStorage = () => {

    const profileStr = localStorage.getItem('profile');
    if (!profileStr) {return null;}
        
    const profile = JSON.parse(profileStr);
    if (!profile || !profile.userProfile) {return null;}
        
    return profile;

};


export const unauthResponseClear = () => {  
    Cookies.remove('authToken');
    localStorage.clear();
};