import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { getInsuranceAgreementsList } from '@actions/clinicianActions';


const useInsuranceSearchBar = (agreement, insuranceAgreements) => {
    const [currentAgreement, setCurrentAgreement] = useState(null);
    const [insuranceOptions, setInsuranceOptions] = useState([]);
    
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getInsuranceAgreementsList());
    }, []);

    function getInsuranceAgreementSearchLabel(insuranceAgreement){
        if (insuranceAgreement===null || insuranceAgreement===undefined) return 'Select insurance agreement';
        if (insuranceAgreement.id===0) return 'Self-payer';
        if (insuranceAgreement.policyName === 'Standard') return `${insuranceAgreement.provider}`;
        return `${insuranceAgreement.provider} (${insuranceAgreement.policyName})`;
    }

    function getInsuranceOptions(){
        if (insuranceAgreements===null || insuranceAgreements===undefined || insuranceAgreements.length===0){
            return { valid: false, providerOptions: []}
        };
        
        const policies = insuranceAgreements.map((insuranceAgreement) => ({
            label: getInsuranceAgreementSearchLabel(insuranceAgreement),
            value: insuranceAgreement.id
        }));

        return { valid: true, providerOptions: [{label: "Self-payer", value: 0}, ...policies]};
    }

    function getInsuranceAgreementStrById(){
        if (agreement==null || agreement===undefined || agreement === -1) {
            return 'Select insurance agreement';
        }
        if (agreement === 0) {return 'Self-payer';}

        const insPol = insuranceAgreements.find((policy) => policy.id === agreement);
        return getInsuranceAgreementSearchLabel(insPol);
    }

    useEffect(() => {
        const { valid, providerOptions } = getInsuranceOptions();
        if (valid) setInsuranceOptions(providerOptions);
    }, [insuranceAgreements]);

    useEffect(() => {
        setCurrentAgreement(getInsuranceAgreementStrById());
    }, [agreement]);
    

    const customStyles = {
        singleValue:(provided) => ({
          ...provided,
          color:'#247986',
        }),
      }
    return {
        insuranceOptions,
        customStyles,
        currentAgreement,
    }
}



export default useInsuranceSearchBar;