import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Footer() {
	return (
		<footer id='footer' className='medr-light' >
			<Container>
				<Row>
					<Col className="text-center">
						<Link to="/policy">Terms and conditions</Link>
					</Col>
					<Col className="text-center">Copyright &copy; 2024 MedR</Col>
					<Col className="text-center">
						<a href="mailto:info@medr.co.uk">Contact us</a>
					</Col>
				</Row>
				<Row>
					<Col className="text-center">
					</Col>
					<Col className="text-center"> </Col>
					<Col className="text-center">
						<Link to="/faqs">FAQs</Link>
					</Col>
				</Row>
			</Container>

		</footer>
	);
}

export default Footer;
