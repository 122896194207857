import * as CONSTS from '@constants/adminConstants';
import * as URL from '@constants/urls';
import { authGetRequest, authPostRequest } from './axiosActions';

export const getPlatformStats = () => async (dispatch) => {
	const inputData = {
		URL: `${URL.PLATFORM_STATS}`,
		GET_REQUEST: CONSTS.PLATFORM_STATS_REQUEST,
		GET_SUCCESS: CONSTS.PLATFORM_STATS_SUCCESS,
		GET_FAIL: CONSTS.PLATFORM_STATS_FAIL,
	};

	dispatch(authGetRequest(inputData));
};

export const getAdminUsersList = () => async (dispatch) => {
	const inputData = {
		URL: `${URL.ADMIN_USERS}`,
		GET_REQUEST: CONSTS.ADMIN_USERS_LIST_REQUEST,
		GET_SUCCESS: CONSTS.ADMIN_USERS_LIST_SUCCESS,
		GET_FAIL: CONSTS.ADMIN_USERS_LIST_FAIL,
	};

	dispatch(authGetRequest(inputData));
};

export const getAdminPatientsList = () => async (dispatch) => {
	const inputData = {
		URL: `${URL.ADMIN_PATIENTS}`,
		GET_REQUEST: CONSTS.ADMIN_PATIENTS_LIST_REQUEST,
		GET_SUCCESS: CONSTS.ADMIN_PATIENTS_LIST_SUCCESS,
		GET_FAIL: CONSTS.ADMIN_PATIENTS_LIST_FAIL,
	};

	dispatch(authGetRequest(inputData));
};

export const getAdminReferralsList = () => async (dispatch) => {
	const inputData = {
		URL: `${URL.ADMIN_REFERRALS}`,
		GET_REQUEST: CONSTS.ADMIN_REFERRALS_LIST_REQUEST,
		GET_SUCCESS: CONSTS.ADMIN_REFERRALS_LIST_SUCCESS,
		GET_FAIL: CONSTS.ADMIN_REFERRALS_LIST_FAIL,
	};

	dispatch(authGetRequest(inputData));
};

export const getAdminClinicansList = () => async (dispatch) => {
	const inputData = {
		URL: `${URL.ADMIN_CLINICIANS}`,
		GET_REQUEST: CONSTS.ADMIN_CLINICIANS_LIST_REQUEST,
		GET_SUCCESS: CONSTS.ADMIN_CLINICIANS_LIST_SUCCESS,
		GET_FAIL: CONSTS.ADMIN_CLINICIANS_LIST_FAIL,
	};

	dispatch(authGetRequest(inputData));
};

export const getAdminInsurers = () => async (dispatch) => {
	const inputData = {
		URL: `${URL.ADMIN_INSURERS}`,
		GET_REQUEST: CONSTS.ADMIN_INSURERS_REQUEST,
		GET_SUCCESS: CONSTS.ADMIN_INSURERS_SUCCESS,
		GET_FAIL: CONSTS.ADMIN_INSURERS_FAIL,
	};

	dispatch(authGetRequest(inputData));
};

export const verifyInsurer = (insId) => async (dispatch) => {
	const inputData = {
		URL: `${URL.ADMIN_INSURER_VERIFY}${insId}/`,
		GET_REQUEST: CONSTS.ADMIN_INSURER_VERIFY_REQUEST,
		GET_SUCCESS: CONSTS.ADMIN_INSURER_VERIFY_SUCCESS,
		GET_FAIL: CONSTS.ADMIN_INSURER_VERIFY_FAIL,
		payload: {},
	};

	dispatch(authPostRequest(inputData));
};

export const setUserVerified = (id, setVerified) => async (dispatch) => {
	const isSuccessful = () => {
		dispatch(getAdminUsersList());
	};

	const inputData = {
		URL: `${URL.ADMIN_USER_VERIFY}${id}/`,
		GET_REQUEST: CONSTS.ADMIN_SET_USER_VERIFIED_REQUEST,
		GET_SUCCESS: CONSTS.ADMIN_SET_USER_VERIFIED_SUCCESS,
		GET_FAIL: CONSTS.ADMIN_SET_USER_VERIFIED_FAIL,
		payload: { setVerified },
	};

	dispatch(authPostRequest(inputData, isSuccessful));
};

export const requestNewFeature =
	(payload, isSuccessfull) => async (dispatch) => {
		const inputData = {
			URL: `${URL.REQUEST_NEW_FEATURE}`,
			GET_REQUEST: CONSTS.REQUEST_NEW_FEATURE_REQUEST,
			GET_SUCCESS: CONSTS.REQUEST_NEW_FEATURE_SUCCESS,
			GET_FAIL: CONSTS.REQUEST_NEW_FEATURE_FAIL,
			payload,
		};

		dispatch(authPostRequest(inputData, isSuccessfull));
	};
