import React  from 'react';
import { Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
import useTitleDropdown from './useTitleDropdown';


function TitleDropdown({ title = null, sendTitle, valueKey = 'title' }){
    const {
        titleOptions,
        customStyles,
        currentTitle
    } = useTitleDropdown(title);

    return (
        <Row className="mb-2 custom-dropdown">
            <Col>
                <Select
                    options={titleOptions}
                    onChange={opt => sendTitle({[valueKey]: opt.value})}
                    placeholder={currentTitle}
                    styles={customStyles}
                    className="custom-dropdown-select"
                />
            </Col>
        </Row>
    )
}

TitleDropdown.propTypes = {
    title: PropTypes.string,
	sendTitle: PropTypes.func.isRequired,
    valueKey: PropTypes.string
};


export default TitleDropdown;