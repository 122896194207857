import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateReferralContacts } from '@actions/profileActions';

const useReferralContacts = () => {
	const dispatch = useDispatch();
	const clinicianProfiles = useSelector(
		(state) => state.profile.clinicianProfiles
	);
	if (clinicianProfiles.length === 0) return {};
	const rxContacts = clinicianProfiles[0].userProfile.appointmentsContact;

	const [referralContacts, setReferralContacts] = useState([]);

	// const userEmail = useSelector(state => state.profile.userProfile.user.email);
	const delegates = useSelector((state) => state.profile.delegates);
	const delegateEmails = delegates.map(
		(dl) => dl.delegateUserProfile.user.email
	);
	const requiredEmails = [...delegateEmails];

	useEffect(() => {
		if (rxContacts) {
			setReferralContacts(rxContacts);
		} else {
			setReferralContacts([]);
		}
	}, [rxContacts]);

	const updateAppointmentContacts = (contacts) => {
		dispatch(updateReferralContacts(contacts));
	};

	return { referralContacts, requiredEmails, updateAppointmentContacts };
};

export default useReferralContacts;
