import axios from 'axios';
import Cookies from 'js-cookie';
import * as CONSTS from '@constants/patientConstants';
import * as MSG from '@constants/messageConstants';
import * as URL from '@constants/urls';
import {
	getMessageUnknownError,
	authPutRequest,
	anyPostRequest,
	authGetRequest,
	authDeleteRequest,
} from './axiosActions';

export const getClinicianPatientList = () => async (dispatch) => {
	const inputData = {
		URL: `${URL.PATIENTS}`,
		GET_REQUEST: CONSTS.CLINICIAN_PATIENTS_REQUEST,
		GET_SUCCESS: CONSTS.CLINICIAN_PATIENTS_SUCCESS,
		GET_FAIL: CONSTS.CLINICIAN_PATIENTS_FAIL,
	};

	dispatch(authGetRequest(inputData));
};

export const postCreatePatient =
	(formData, isSuccessfull) => async (dispatch) => {
		dispatch({ type: CONSTS.CREATE_PATIENT_REQUEST });

		const payload = formData;
		try {
			const token = Cookies.get('authToken');

			const headers = {
				headers: {
					Authorization: `Token ${token}`,
					'Content-Type': 'multipart/form-data',
				},
			};

			const { data } = await axios.post(
				`${URL.PATIENT_CREATE}`,
				payload,
				headers
			);

			dispatch({
				type: CONSTS.CREATE_PATIENT_SUCCESS,
				payload: data,
			});

			if ('message' in data) {
				dispatch({
					type: MSG.MESSAGE_RECEIVED,
					payload: data.message,
				});
			}

			isSuccessfull(true);
		} catch (error) {
			dispatch({
				type: CONSTS.CREATE_PATIENT_FAIL,
			});

			const msg = getMessageUnknownError(error);

			dispatch({
				type: MSG.MESSAGE_RECEIVED,
				payload: msg,
			});

			isSuccessfull(false);
		}
	};

export const postCreatePatientByImage =
	(formData, isSuccessfull) => async (dispatch) => {
		dispatch({ type: CONSTS.CREATE_PATIENT_BY_IMAGE_REQUEST });

		const payload = formData;
		try {
			const token = Cookies.get('authToken');

			const headers = {
				headers: {
					Authorization: `Token ${token}`,
					'Content-Type': 'multipart/form-data',
				},
			};

			const { data } = await axios.post(
				`${URL.PATIENT_CREATE_BY_IMAGE}`,
				payload,
				headers
			);

			dispatch({
				type: CONSTS.CREATE_PATIENT_BY_IMAGE_SUCCESS,
				payload: data,
			});

			if ('message' in data) {
				dispatch({
					type: MSG.MESSAGE_RECEIVED,
					payload: data.message,
				});
			}

			isSuccessfull(true);
		} catch (error) {
			dispatch({
				type: CONSTS.CREATE_PATIENT_BY_IMAGE_FAIL,
			});

			const msg = getMessageUnknownError(error);

			dispatch({
				type: MSG.MESSAGE_RECEIVED,
				payload: msg,
			});

			isSuccessfull(false);
		}
	};

export const togglePatientActiveStatus = (patientId) => async (dispatch) => {
	const inputData = {
		URL: `${URL.PATIENT_TOGGLE_ACTIVE}${patientId}/`,
		GET_REQUEST: CONSTS.PATIENT_ACTIVE_TOGGLE_REQUEST,
		GET_SUCCESS: CONSTS.PATIENT_ACTIVE_TOGGLE_SUCCESS,
		GET_FAIL: CONSTS.PATIENT_ACTIVE_TOGGLE_FAIL,
	};

	dispatch(authPutRequest(inputData));
};

export const getGpsCoords = (postCode) => async (dispatch) => {
	const payload = { postCode };

	const inputData = {
		URL: `${URL.GET_GPS}`,
		GET_REQUEST: CONSTS.GPS_COORDS_REQUEST,
		GET_SUCCESS: CONSTS.GPS_COORDS_SUCCESS,
		GET_FAIL: CONSTS.GPS_COORDS_FAIL,
		payload,
	};

	dispatch(anyPostRequest(inputData));
};

export const getCurrentPatient = (patientId) => async (dispatch) => {
	const inputData = {
		URL: `${URL.PATIENT_GET_CURRENT}${patientId}/`,
		GET_REQUEST: CONSTS.CURRENT_PATIENT_REQUEST,
		GET_SUCCESS: CONSTS.CURRENT_PATIENT_SUCCESS,
		GET_FAIL: CONSTS.CURRENT_PATIENT_FAIL,
	};

	dispatch(authGetRequest(inputData));
};

export const resetCurrentPatient = () => async (dispatch) => {
	dispatch({ type: CONSTS.CURRENT_PATIENT_RESET });
};

export const deletePatientById =
	(patientId, isSuccessfull) => async (dispatch) => {
		const inputData = {
			URL: `${URL.PATIENT_DELETE}${patientId}/`,
			GET_REQUEST: CONSTS.PATIENT_DELETE_REQUEST,
			GET_SUCCESS: CONSTS.PATIENT_DELETE_SUCCESS,
			GET_FAIL: CONSTS.PATIENT_DELETE_FAIL,
			payload: {},
		};
		dispatch(authDeleteRequest(inputData, isSuccessfull));
	};

export const updatePatientById =
	(id, payload, isSuccessful) => async (dispatch) => {
		const inputData = {
			URL: `${URL.PATIENT_UPDATE}${id}/`,
			GET_REQUEST: CONSTS.PATIENT_UPDATE_REQUEST,
			GET_SUCCESS: CONSTS.PATIENT_UPDATE_SUCCESS,
			GET_FAIL: CONSTS.PATIENT_UPDATE_FAIL,
			payload,
		};

		dispatch(authPutRequest(inputData, isSuccessful));
	};

export const getPatientReferralsById = (id) => async (dispatch) => {
	const inputData = {
		URL: `${URL.PATIENT_REFERRALS}${id}/`,
		GET_REQUEST: CONSTS.PATIENT_REFERRALS_REQUEST,
		GET_SUCCESS: CONSTS.PATIENT_REFERRALS_SUCCESS,
		GET_FAIL: CONSTS.PATIENT_REFERRALS_FAIL,
	};

	dispatch(authGetRequest(inputData));
};
