import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'react-bootstrap';
import {
	boolPropType,
	clinicanProfileRankPropType,
	funcPropType,
} from '@constants/propTypes';
import { getNameString } from '@actions/general';
import FavouriteClinicianStar from '@components/app/parts/FavouriteStar/FavouriteClinicianStar';
import useClinicianCard from './useClinicianCard';

function ClinicianCard({
	clinician,
	hoveredClinicianUserProfileIds,
	updateHovered,
	onClinicianSelect,
	refreshClinicians,
	showReferralButton = true,
}) {
	const {
		onMouseEnter,
		onMouseLeave,
		clinicianIsHovered,
		gotToDirectReferral,
		clinicNames,
	} = useClinicianCard(
		clinician,
		hoveredClinicianUserProfileIds,
		updateHovered
	);

	const clinicianRoles = clinician.userProfile.jobRole.map(
		(jobRole) => jobRole.jobTitle
	);
	const clinicianRolesString = clinicianRoles.join(', ');

	const clinSel = (e) => {
		if (e?.target?.className.includes('btn-')) return;
		onClinicianSelect(clinician.id);
	};
	return (
		<Card
			className={`mb-2 rounded d-flex flex-fill ${
				clinicianIsHovered ? 'map-clinician-card' : ''
			}`}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onClick={clinSel}
			style={{ padding: '0.5rem', cursor: 'pointer' }}
		>
			<div className="d-flex justify-content-between align-items-center">
				<Card.Title style={{ marginBottom: '0rem', marginTop: '0rem' }}>
					{getNameString(clinician.userProfile)}
				</Card.Title>
				<Card.Subtitle
					className="text-muted"
					style={{ marginTop: '0rem', marginBottom: '0rem' }}
				>
					{clinician.distance.toFixed(1)}km
				</Card.Subtitle>
			</div>
			<Card.Subtitle className="mb-3 mt-1 text-muted">
				{clinicianRolesString}
			</Card.Subtitle>
			<ul className="list-unstyled mb-1">
				{clinicNames.map((name) => (
					<li key={name} className="d-flex mb-2">
						<div className="flex-shrink-0 d-flex align-items-center">
							<i className="fa-solid fa-house-medical text-muted mcc-icon" />
						</div>
						<div className="d-flex align-items-center flex-grow-1">
							<p className="mb-0 text-muted mcc-text">{name}</p>
						</div>
					</li>
				))}
			</ul>
			{showReferralButton ? (
				<div className="d-flex justify-content-between align-items-center">
					<Button
						className="map-clinician-card-rx-btn"
						variant="primary"
						onClick={gotToDirectReferral}
					>
						Refer patient
					</Button>
					<FavouriteClinicianStar
						clinician={clinician}
						requestMade={refreshClinicians}
					/>
				</div>
			) : (
				<div className="d-flex justify-content-end align-items-center">
					<FavouriteClinicianStar
						clinician={clinician}
						requestMade={refreshClinicians}
					/>
				</div>
			)}
		</Card>
	);
}

ClinicianCard.propTypes = {
	clinician: clinicanProfileRankPropType.isRequired,
	hoveredClinicianUserProfileIds: PropTypes.arrayOf(PropTypes.number)
		.isRequired,
	updateHovered: funcPropType.isRequired,
	onClinicianSelect: funcPropType.isRequired,
	refreshClinicians: funcPropType.isRequired,
	showReferralButton: boolPropType,
};

export default ClinicianCard;

//     <div
//         className={`medr-rounded-inv bg-medr-gray mb-2 select-clinicial-card ${clinicianIsHovered ? 'active' : ''}`}
//         onMouseEnter={onMouseEnter}
//         onMouseLeave={onMouseLeave}
//         >
//         <div className='row'>
//             <div className='col-3'>
//                 <div className='text-center'>
//                     <FavouriteClinicianStar
//                         clinician={clinician}
//                         togglePopup={togglePopup}
//                         requestMade={handleRequestMade}
//                         />
//                 </div>
//                 <div className='gap'> </div>
//                 <div className='clinician-distance'>{clinician.distance.toFixed(1)}km</div>
//                 <div className='gap'> </div>
//                 {showReferralButton && (<Button variant='primary' className="cc-rx-btn" onClick={gotToDirectReferral}>Refer patient</Button>)}
//             </div>
//             <div className='col-9 p-1'
//                 onClick={() => onClinicianSelect(clinician.id)}
//                 onKeyDown={() => onClinicianSelect(clinician.id)}
//                 aria-label='Clickable Div'
//                 role='button'
//                 tabIndex="0"
//                 >
//                 <h6>{getNameString(clinician.userProfile)}</h6>
//                 {clinician.userProfile.jobRole.map((jobRole) => (
//                     <p className="cc-clinic-names" key={uuidv4()}>{jobRole.jobTitle}</p>
//                 ))}
//                 {clinicNames.map((name) => (
//                     <p className="cc-clinic-names" key={uuidv4()}>{name}</p>
//                 ))
//             }
//             </div>
//         </div>
//     </div>
// </>
