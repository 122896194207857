import { useState, useEffect } from "react";

const useAdminUsersList = (usersList, start, end) => {
    const [users, setUsers] = useState([]);
    const [startSlice, setStartSlice] = useState(start);
    const [endSlice, setEndSlice] = useState(end);
    const usersLength = usersList.length;

    const updateSlice = (startInd, endInd) => {
        setStartSlice(startInd);
        setEndSlice(endInd);
    };

    useEffect(() => {
        if (!usersList || usersList.length === 0) {
            setUsers([]);
        }
        const slice = usersList.slice(startSlice, endSlice);
        setUsers(slice);
    }, [users, startSlice, endSlice]);
    
    return [users, updateSlice, usersLength];
};

export default useAdminUsersList;