import { useState, useEffect } from "react";

const usePatientsList = (patientsList, start, end, filter='') => {
    const [patients, setPatients] = useState([]);
    const [startSlice, setStartSlice] = useState(start);
    const [endSlice, setEndSlice] = useState(end);
    const patientsLength = patients.length;

    const updateSlice = (startInd, endInd) => {
        setStartSlice(startInd);
        setEndSlice(endInd);
    };

    const filterPatientList = () => {
        if (!filter || filter === '') return patientsList;
        return patientsList.filter(
            (patient) =>
                `${patient.title} ${patient.firstName} ${patient.lastName}`.toLowerCase().includes(filter.toLowerCase())
        );
    };

    const updatePatients = () => {
        if (!patientsList || patientsList.length === 0) {
            setPatients([]);
            return;
        }
        const filtPx = filterPatientList();
        const slice = filtPx.slice(startSlice, endSlice);
        setPatients(slice);
    }


    useEffect(() => {
        updatePatients();
    }, [patientsList, startSlice, endSlice, filter]);
    
    return [patients, updateSlice, patientsLength];
};

export default usePatientsList;