import { useState } from 'react';

const useMapView = (handleShowFavourites) => {
	const [searchedLocation, setSearchedLocation] = useState(null);

	const updateShowOnlyFavourites = (tabRef) => {
		const onlyFav = tabRef === 'favouriteClinicians';
		handleShowFavourites(onlyFav);
	};

	const receivePostcodeSearch = (e) => setSearchedLocation(e);

	return {
		updateShowOnlyFavourites,
		receivePostcodeSearch,
		searchedLocation,
	};
};

export default useMapView;
