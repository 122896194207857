import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import ReferralStepPatient from '@components/referrals/entry/ReferralStepPatient';
import ReferralStepInfo from '@components/referrals/entry/ReferralStepInfo';
import ReferralStepReview from '@components/referrals/entry/ReferralStepReview';
import useDirectReferralForm from '@hooks/referrals/useDirectReferralForm';

function DirectReferralEntry() {
	const { clinicianId } = useParams();

	const {
		handleNext,
		handlePrevious,
		activeStep,
		referralData,
		updateReferralData,
		validateReferralData,
		getRequestData,
	} = useDirectReferralForm(clinicianId);
	const { patientId, selectedPatient } = referralData;

	const successfulPxForm = (value) => {
		if (!value && activeStep === 1) handlePrevious(); // handleNext();
	};

	return (
		<>
			{activeStep === 0 && (
				<ReferralStepPatient
					currentPatientId={patientId}
					patientSelected={selectedPatient}
					addNewPatientSuccess={successfulPxForm}
				/>
			)}
			{activeStep === 1 && (
				<ReferralStepInfo
					values={referralData}
					changeValue={updateReferralData}
					sendNext={handleNext}
				/>
			)}
			{activeStep === 2 && (
				<ReferralStepReview
					validateReferralData={validateReferralData}
					getRequestData={getRequestData}
					values={referralData}
				/>
			)}

			<div
				className="medr-layout text-center ref-info-page"
				style={{ maxWidth: '700px', marginLeft: 'auto', marginRight: 'auto' }}
			>
				<Row className="mt-2">
					<Col>
						{activeStep !== 0 && (
							<Button
								className="sel-button w80"
								variant="dark"
								onClick={handlePrevious}
							>
								Previous
							</Button>
						)}
						{activeStep === 0 && selectedPatient === true && (
							<Button
								className="sel-button w80"
								variant="primary"
								disabled={!selectedPatient}
								onClick={handleNext}
							>
								Next
							</Button>
						)}
					</Col>
				</Row>
			</div>
		</>
	);
}

export default DirectReferralEntry;
