import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const useDelegaetUser = () => {
    const up = useSelector(state => state.auth.user);
    const crps = useSelector(state => state.profile.clinicianProfiles);

    const [userProfile, setUserProfile] = useState(null);
    const [clinicianUserProfile, setClinicianUserProfile] = useState(null);

    useEffect(() => {
        if (up && up!==undefined) {
            setUserProfile(up);
        }
        if (crps && crps!==undefined && crps.length>0 && crps[0].userProfile) {
            setClinicianUserProfile(crps[0].userProfile);
        }
    }, [up, crps]);        
    return {
        userProfile,
        clinicianUserProfile,
    };
}

export default useDelegaetUser;