// Auth Dispatch States
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const REGISTER_DELEGATE_REQUEST = 'REGISTER_DELEGATE_REQUEST';
export const REGISTER_DELEGATE_SUCCESS = 'REGISTER_DELEGATE_SUCCESS';
export const REGISTER_DELEGATE_FAIL = 'REGISTER_DELEGATE_FAIL';

export const REGISTER_DELEGATE_INFO_REQUEST = 'REGISTER_DELEGATE_INFO_REQUEST';
export const REGISTER_DELEGATE_INFO_SUCCESS = 'REGISTER_DELEGATE_INFO_SUCCESS';
export const REGISTER_DELEGATE_INFO_FAIL = 'REGISTER_DELEGATE_INFO_FAIL';

export const VERIFY_DELEGATE_REQUEST = 'VERIFY_DELEGATE_REQUEST';
export const VERIFY_DELEGATE_SUCCESS = 'VERIFY_DELEGATE_SUCCESS';
export const VERIFY_DELEGATE_FAIL = 'VERIFY_DELEGATE_FAIL';

export const UPDATE_DELEGATE_PERMISSION_REQUEST = 'UPDATE_DELEGATE_PERMISSION_REQUEST';
export const UPDATE_DELEGATE_PERMISSION_SUCCESS = 'UPDATE_DELEGATE_PERMISSION_SUCCESS';
export const UPDATE_DELEGATE_PERMISSION_FAIL = 'UPDATE_DELEGATE_PERMISSION_FAIL';

export const REGISTER_CHECK_EMAIL_REQUEST = 'REGISTER_CHECK_EMAIL_REQUEST';
export const REGISTER_CHECK_EMAIL_SUCCESS = 'REGISTER_CHECK_EMAIL_SUCCESS';
export const REGISTER_CHECK_EMAIL_FAIL = 'REGISTER_CHECK_EMAIL_FAIL';

export const REGISTER_CHECK_CLINIC_REQUEST = 'REGISTER_CHECK_CLINIC_REQUEST';
export const REGISTER_CHECK_CLINIC_SUCCESS = 'REGISTER_CHECK_CLINIC_SUCCESS';
export const REGISTER_CHECK_CLINIC_FAIL = 'REGISTER_CHECK_CLINIC_FAIL';

export const VERIFY_EMAIL_REQUEST = 'VERIFY_EMAIL_REQUEST';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAIL = 'VERIFY_EMAIL_FAIL';

export const NOTIFY_MEDR_FAILED_REGISTRATION_REQUEST = 'NOTIFY_MEDR_FAILED_REGISTRATION_REQUEST';
export const NOTIFY_MEDR_FAILED_REGISTRATION_SUCCESS = 'NOTIFY_MEDR_FAILED_REGISTRATION_SUCCESS';
export const NOTIFY_MEDR_FAILED_REGISTRATION_FAIL = 'NOTIFY_MEDR_FAILED_REGISTRATION_FAIL';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const CHECK_AUTH_REQUEST = 'CHECK_AUTH_REQUEST';
export const CHECK_AUTH_SUCCESS = 'CHECK_AUTH_SUCCESS';
export const CHECK_AUTH_FAIL = 'CHECK_AUTH_FAIL';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const RESET_PASSWORD_SET_NEW_REQUEST = 'RESET_PASSWORD_SET_NEW_REQUEST';
export const RESET_PASSWORD_SET_NEW_SUCCESS = 'RESET_PASSWORD_SET_NEW_SUCCESS';
export const RESET_PASSWORD_SET_NEW_FAIL = 'RESET_PASSWORD_SET_NEW_FAIL';

export const AUTH_FROM_STORAGE = 'AUTH_FROM_STORAGE';

export const FULL_STATE_RESET = 'FULL_STATE_RESET';

