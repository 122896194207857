import React from "react";

export const jobRoles = [
	'Consultant Spinal Surgeon',
	'Spinal Surgeon',
	'GP',
	'Physiotherapist',
];
const titles = ['Mr', 'Mrs', 'Ms', 'Dr', 'Prof'];

export const hidden = (<p><i className="fa-solid fa-circle-xmark"/><i className="fa-solid fa-circle-xmark"/><i className="fa-solid fa-circle-xmark"/><i className="fa-solid fa-circle-xmark"/><i className="fa-solid fa-circle-xmark"/><i className="fa-solid fa-circle-xmark"/><i className="fa-solid fa-circle-xmark"/><i className="fa-solid fa-circle-xmark"/></p>);

export default titles;
