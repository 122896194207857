import React  from 'react';
import { Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';


function ProfileTabsDropDown({ viewName, viewOptions, updateView }) {
    // const options = viewOptions.map((view) => ({label: view, value: view}));
    const customStyles = {
        singleValue:(provided) => ({
          ...provided,
          color:'#247986',
        }),
      }

    return (
        <Row className="mb-2 custom-dropdown">
            <Col>
                <Select
                    options={viewOptions}
                    onChange={opt => updateView(opt.value)}
                    placeholder={viewName}
                    styles={customStyles}
                    className="custom-dropdown-select"
                />
            </Col>
        </Row>
    )
}

ProfileTabsDropDown.propTypes = {
    viewName: PropTypes.string.isRequired,
    viewOptions: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired
    })).isRequired,
	updateView: PropTypes.func.isRequired,
};


export default ProfileTabsDropDown;