import { useSelector } from "react-redux";

const useUserProfile = () => {
    const userProfile = useSelector(state => state.auth.user);
        
    return {
        userProfile,
    };
}

export default useUserProfile;