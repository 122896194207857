import React from 'react';
import { Alert } from 'react-bootstrap';

function BetaVersionFreeAccountAlert() {
	const text = "Thank you for being part of the MedR beta experience! As a beta user, you won't incur any charges while using the platform. Stay tuned for updates as we prepare for the full release of the application."
	
	return (
			<Alert variant='info' className='mt-4' dismissible>
				{text}
			</Alert>
	);
}

export default BetaVersionFreeAccountAlert;