import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './map.css';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, useMap, useMapEvents } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { divIcon, point } from 'leaflet';
import { v4 as uuidv4 } from 'uuid';
import ClinicMarker from './ClinicMarker';
import TargetMarker from './TargetMarker';
import { clinicsPropType, funcPropType } from '@constants/propTypes';
import useGeoLocation from './useGeolocation';
import Loader from '@components/app/Loader';

function Map({
	clinics,
	hoveredAddressIds,
	updateHovered,
	updateMapViewCoords,
	viewLocation = null,
}) {
	const { location } = useGeoLocation(viewLocation);
	const [lastPan, setLastPan] = React.useState(null);

	const createClusterCustomIcon = function (cluster) {
		// eslint-disable-line func-names
		// TODO: Add on hover for marker cluster
		const size = 33;

		return new divIcon({
			// eslint-disable-line new-cap
			html: `<span class="cluster-icon">${cluster.getChildCount()}</span>`,
			className: 'custom-marker-cluster',
			iconSize: point(size, size, true),
		});
	};

	function FlyMapTo() {
		const map = useMap();

		const currentZoom = map.getZoom();
		useEffect(() => {
			if (lastPan && lastPan === location.mapCenter) return;
			setLastPan(location.mapCenter); // stops fly to moving after initial load
			map.flyTo(location.mapCenter, currentZoom);
		}, [location.mapCenter]);

		return null;
	}
	function MapEventHandler() {
		const map = useMapEvents({
			moveend: () => {
				const bounds = map.getBounds();
				const northEast = bounds.getNorthEast();
				const southWest = bounds.getSouthWest();

				// console.log('Map moved. New corner coordinates:');
				// console.log('NorthEast:', northEast.lat, northEast.lng);
				// console.log('SouthWest:', southWest.lat, southWest.lng);
				updateMapViewCoords({
					ne: { lat: northEast.lat, lng: northEast.lng },
					sw: { lat: southWest.lat, lng: southWest.lng },
				});
			},
		});

		return null;
	}

	const jawgAccesToken = import.meta.env.REACT_APP_JAWG_ACCESS_TOKEN;

	return (
		<div id="map">
			{location.loaded && !location.error ? (
				<MapContainer center={location.mapCenter} zoom={11}>
					<TileLayer
						// attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>"
						// url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
						url="https://tile.jawg.io/jawg-streets/{z}/{x}/{y}{r}.png?access-token={accessToken}"
						attribution='<a href="https://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
						minZoom={0}
						maxZoom={22}
						accessToken={jawgAccesToken}
					/>
					<MarkerClusterGroup
						chunkedLoading
						iconCreateFunction={createClusterCustomIcon} // eslint-disable-line  react/jsx-no-bind
					>
						{clinics.map((clinic) => (
							<ClinicMarker
								key={uuidv4()}
								clinic={clinic}
								hoveredAddressIds={hoveredAddressIds}
								updateHovered={updateHovered}
							/>
						))}
					</MarkerClusterGroup>
					<TargetMarker mapCenter={location.mapCenter} />
					<FlyMapTo />
					<MapEventHandler />
				</MapContainer>
			) : (
				<Loader text="Loading clinician map..." />
			)}
		</div>
	);
}

Map.propTypes = {
	clinics: clinicsPropType.isRequired,
	hoveredAddressIds: PropTypes.arrayOf(PropTypes.number).isRequired,
	updateHovered: funcPropType.isRequired,
	updateMapViewCoords: funcPropType.isRequired,
	viewLocation: PropTypes.shape({
		searchLatitude: PropTypes.number,
		searchLongitude: PropTypes.number,
		postcode: PropTypes.string,
	}),
};

export default Map;
