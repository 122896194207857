import { useState, useEffect } from "react";

const useAdminReferralsList = (referralsList, start, end) => {
    const [referrals, setReferrals] = useState([]);
    const [startSlice, setStartSlice] = useState(start);
    const [endSlice, setEndSlice] = useState(end);
    const referralsLength = referralsList.length;

    const updateSlice = (startInd, endInd) => {
        setStartSlice(startInd);
        setEndSlice(endInd);
    };

    useEffect(() => {
        if (!referralsList || referralsList.length === 0) {
            setReferrals([]);
        }
        const slice = referralsList.slice(startSlice, endSlice);
        setReferrals(slice);
    }, [referrals, startSlice, endSlice]);
    
    return [referrals, updateSlice, referralsLength];
};

export default useAdminReferralsList;