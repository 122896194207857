import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import  { clinicPropType, boolPropType, funcPropType } from '@constants/propTypes';

function NewCrpPopup({ clinic, sendClinic, showModal, handleClose }){
    if (!clinic || clinic===undefined) {
        return null;
    }

    const { centreName, clinicName, address} = clinic;
    const { firstLine, city, postcode } = address;

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className='medr-inv'>New clinician profile</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>Clinic</Col>
                    <Col>
                        <p>{clinicName}</p>
                        <p>{centreName}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>Address</Col>
                    <Col>{firstLine}</Col>
                </Row>
                <Row>
                    <Col> </Col>
                    <Col>{city}</Col>
                </Row>
                <Row>
                    <Col> </Col>
                    <Col>{postcode}</Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => sendClinic(clinic)}>
                    Add new clinician profile
                </Button>
                <Button variant="info" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

NewCrpPopup.propTypes = {
    clinic: clinicPropType.isRequired,
    showModal: boolPropType.isRequired,
    sendClinic: funcPropType.isRequired,
    handleClose: funcPropType.isRequired
}

export default NewCrpPopup;