import React, { useEffect } from"react";
import { Row, Col ,Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { funcPropType } from '@constants/propTypes';
import InsuranceSelectorGrid from "./InsuranceSelectorGrid/InsuranceSelectorGrid";
import InsuranceProviderForm from "../clinicians/InsuranceProviderDisplay/InsuranceProviderForm";

function RegisterInsuranceDetails({ values, updateExisitingProviders, updateNewProviders, handleNext }){
    
    useEffect(() => {window.scroll(0,0)}, []);

    return (
        <div className='insurance-page'>
            <Row className='my-3'>
                <Col className='text-center'>
                    <h4>Add accepted insurances</h4>
                </Col>
            </Row>

            <InsuranceSelectorGrid 
                currentProviders={values.existingProviders} 
                updateCurrentProviders={updateExisitingProviders} 
                />
            <InsuranceProviderForm updateNewProviders={updateNewProviders}/>
            <Row className='my-3'>
                <Col className='text-center'>
                    <Button className="sel-button w80" variant='primary' onClick={handleNext}>Next</Button>
                </Col>
            </Row>
        </div>

    )
};

RegisterInsuranceDetails.propTypes = {
    values: PropTypes.shape({
        isReview: PropTypes.bool.isRequired,
        existingProviders: PropTypes.arrayOf(PropTypes.shape({
            provider: PropTypes.string.isRequired,
        })).isRequired,
        newProviders: PropTypes.arrayOf(PropTypes.shape({
            provider: PropTypes.string.isRequired,
        })).isRequired,
    }).isRequired,
	updateExisitingProviders: funcPropType.isRequired,
    updateNewProviders: funcPropType.isRequired,
	handleNext: funcPropType.isRequired,
};


export default RegisterInsuranceDetails;