import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { funcPropType, boolPropType } from '@constants/propTypes';


function ReferralSentPopup({ showModal, handleClose }) {
	return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title className='medr-inv'>Your referral has been sent successfully!</Modal.Title>
            </Modal.Header>
                <Modal.Footer>
                    <Button variant="info" onClick={handleClose}>
                        Close
                    </Button>
            </Modal.Footer>
        </Modal>
	);
}

ReferralSentPopup.propTypes = {
    showModal: boolPropType.isRequired,
    handleClose: funcPropType.isRequired
}

export default ReferralSentPopup;
