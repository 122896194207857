import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { loadUser, updateUserNotifications } from '@actions/profileActions';
import { notificationSettingsProptype } from '@constants/propTypes';


function NotificaitonToggleBar({ field, label, value = false, onChange }) {
    const sendNewValue = (newValue) => onChange({field, value: newValue});
    
    const [checked, setChecked] = useState(value);
    useEffect(() => {
        setChecked(value);
    }, [value]);

    return (
        <div className="medr-rounded-inv notif-setting-bar">
            <div className='notif-setting-left'>
                <Button variant={checked ? 'success': 'outline-success'} className='medr-text notif-button' onClick={() => sendNewValue(true)}>
                    Enabled 
                </Button>
                <Button variant={!checked ? 'danger': 'outline-danger'} className='medr-text notif-button' onClick={() => sendNewValue(false)}>
                    Disabled 
                </Button>
            </div>
            <div className='notif-setting-right medr-text'>
                {label}
            </div>
        </div>
    );
}

NotificaitonToggleBar.propTypes = {
    field: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};



function ProfileChangeNotifications({ notificationSettings }) {  
    
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(loadUser());
    }, [dispatch]);

    if (!notificationSettings){
        return null;
    }
    
    const {
        id, 
        userProfile,
        userCreatedReferral, 
        userReceivedReferral, 
        targetHasToggledReferral,
        userHasToggledReferral,
        newOpenReferral
    }  = notificationSettings;

    const onChange = (newSettings) => {
        dispatch(updateUserNotifications({
            user_profile_id: userProfile, 
            ...newSettings
        }));
    }


	return (
        <>
            <h6 className='text-left'>Receive notifications when:</h6>
            <NotificaitonToggleBar 
                field='userCreatedReferral'
                notificaitonId={id}
                label='Referral sent' 
                value={userCreatedReferral} 
                onChange={onChange}
                />
            <NotificaitonToggleBar 
                field='userReceivedReferral'
                notificaitonId={id}
                label='Referral received' 
                value={userReceivedReferral} 
                onChange={onChange}
                />
            <NotificaitonToggleBar 
                field='targetHasToggledReferral'
                notificaitonId={id}
                label='Clinician accepted/rejected referral' 
                value={targetHasToggledReferral} 
                onChange={onChange}
                />
            <NotificaitonToggleBar 
                field='userHasToggledReferral'
                notificaitonId={id}
                label='Accept/reject a referral' 
                value={userHasToggledReferral} 
                onChange={onChange}
                />
            <NotificaitonToggleBar 
                field='newOpenReferral'
                notificaitonId={id}
                label='A new open referral is available' 
                value={newOpenReferral} 
                onChange={onChange}
                />
        </>
	);
}

ProfileChangeNotifications.propTypes = {
    notificationSettings: notificationSettingsProptype.isRequired,
};


const mapStateToProps = (state) => ({
	notificationSettings: state.profile.notificationSettings,
});

export default connect(mapStateToProps, {})(ProfileChangeNotifications);