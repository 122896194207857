import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";

const useUserPermissions = () => {
    const isDelegate = useSelector(state => state.profile.isDelegate);
    const referralPermissions = useSelector(state => state.profile.referralPermissions);

    const [permisisonCreateReferrals, setPermisisonCreateReferrals] = useState(false);
    const [permisisonManageReferrals, setPermisisonManageReferrals] = useState(false);
    const [permissionViewReferrals, setPermissionViewReferrals] = useState(false);
        
    useEffect(() => {
        if (!referralPermissions) {
            setPermisisonCreateReferrals(false);
            setPermisisonManageReferrals(false);
            setPermissionViewReferrals(false);
            return; 
        }
        setPermisisonCreateReferrals(referralPermissions.permisisonCreateReferrals);
        setPermisisonManageReferrals(referralPermissions.permisisonManageReferrals);
        setPermissionViewReferrals(referralPermissions.permissionViewReferrals);
    }, [referralPermissions])

    return {isDelegate, permisisonCreateReferrals, permisisonManageReferrals, permissionViewReferrals }
}

export default useUserPermissions;