
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPatientNameString, reformatDate } from '@actions/general';
import { getClinicianPatientList, resetCurrentPatient, getCurrentPatient } from '@actions/patientActions';

const usePatientSearchBar = (currentPatientId) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getClinicianPatientList());
    }, [dispatch]);


    const activePatients = useSelector((state) => state.patients.activePatients);
    const pastPatients = useSelector((state) => state.patients.pastPatients);
    const acceptedPatients = useSelector((state) => state.patients.acceptedPatients);
    const currentPatient = useSelector((state) => state.patients.currentPatient);
    const [patients, setPatients] = useState([]); // [activePatients, pastPatients, acceptedPatients]
    const [patientsOptions, setPatientsOptions] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [inputPxId, setInputPxId] = useState(null);
    
    const getPxOption = (px) => (
        {
            label:  (<><strong style={{fontWeight: 'bold' }}>{getPatientNameString(px)}</strong> - DOB:{reformatDate(px.dob)} - {px.address.postcode}</>),
            value: `${getPatientNameString(px)} - DOB:${reformatDate(px.dob)} - ${px.address.postcode}`,
            pid: px.id,
        }
    );

    useEffect(() => {
        if (!activePatients && !pastPatients && !acceptedPatients) return;
        const pxList = [...activePatients, ...pastPatients, ...acceptedPatients];
        const optsList = pxList.map(px => getPxOption(px));
        setPatients(pxList);
        setPatientsOptions(optsList);
    }, [activePatients, pastPatients, acceptedPatients]);

    const selectPatientByOption = (opt) => {
        const px = patients.find(patient => patient.id === opt.pid);
        if (px === null || px === undefined) return;
        setSelectedPatient(opt);
        if (currentPatient && currentPatient.id === px.id) return;
        dispatch(getCurrentPatient(px.id));
        // setCurrentPatient(px);
    };

    const selectPatientByInputId = () => {
        if (!inputPxId || inputPxId===undefined) return;
        if (inputPxId === 0) {
            setSelectedPatient(null);
            return;
        }
        if (currentPatient && currentPatient.id === inputPxId) return;
        dispatch(getCurrentPatient(currentPatient.id));
    };

    useEffect(() => {
        if (!currentPatientId || currentPatientId===undefined || currentPatientId===0) {
            dispatch(resetCurrentPatient());
        };
        setInputPxId(currentPatientId);
    }, [currentPatientId]);
    useEffect(() => {selectPatientByInputId();}, [inputPxId]);


    useEffect(() => {
        if (!currentPatient || currentPatient === undefined) return;
        if (!currentPatient.id || currentPatient.id === 0) {
            setSelectedPatient(null);
            return;
        };

        setSelectedPatient(getPxOption(currentPatient));
    }, [currentPatient]);

    return { 
        patients,
        patientsOptions,
        selectPatientByOption,
        selectedPatient,
        currentPatient
    };
};

export default usePatientSearchBar;