import React from "react";
import PropTypes from "prop-types";
import {Marker, Popup} from 'react-leaflet';
import './map.css';
import "leaflet/dist/leaflet.css";
import { clinicPropType, funcPropType } from '@constants/propTypes';
import useClinicMarker from "./useClinicMarker";

function ClinicMarker({ clinic, hoveredAddressIds, updateHovered }) {
    const {
        geocode,
        puname,
        customIcon,
        onMouseEnter,
        onMouseLeave,
    } = useClinicMarker(clinic, hoveredAddressIds, updateHovered);

    return (
        <Marker 
            position={geocode}
            icon={customIcon}
            eventHandlers={{
                mouseover: () => onMouseEnter(),
                mouseout: () => onMouseLeave()
              }}
            clinicId={clinic.id}
            >
            <Popup>
                {puname}
            </Popup>
        </Marker>
    );
}

ClinicMarker.propTypes = {
    clinic: clinicPropType.isRequired,
    hoveredAddressIds: PropTypes.arrayOf(PropTypes.number).isRequired, 
    updateHovered: funcPropType.isRequired
}

export default ClinicMarker;
