import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { DobFormField } from '../../app/parts/FormFields';
import JobRoleSearchBar from '../../clinicians/JobRoleSearchBar';
import ClinicFormDoctor from '../../clinicians/ClinicFormDoctor';
import ClinicFormHcp from '../../clinicians/ClinicFormHcp';
import ClinicsDisplayBox from '../../clinicians/ClinicsDisplayBox';
import {
	funcPropType,
	clinicsPropType,
	jobRolesPropType,
} from '@constants/propTypes';
import ReferralContactsDisplay from '../ReferralContacts/ReferralContactsDisplay';
import ClinicianDescriptionField from '@components/user/UpdateUserDetails/ClinicianDescriptionField';

function RegisterClinicianDetailsForm({
	values,
	addJobRole,
	removeJobRole,
	addClinic,
	editClinic,
	removeClinic,
	updateAppointmentContacts,
	changeValues,
	handleNext,
}) {
	const [disableNext, setDisableNext] = useState(true);

	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	const { isDoctor, registrationBody } = values;

	useEffect(() => {
		if (values.clinics === null || values.clinics === undefined) {
			return;
		}
		const roleSelected = values.jobRoles.length > 0;
		const clinicAdded = values.clinics.length > 0;
		const registrationAdded = values.registrationNumber !== '';
		const dobAdded = values.dob !== '';
		const descriptionAdded = values.clinicianDescription !== '';
		const ready =
			clinicAdded &&
			roleSelected &&
			registrationAdded &&
			dobAdded &&
			descriptionAdded;
		setDisableNext(!ready);
	}, [values]);

	function setJobRoles() {
		if (values.jobRoles.length === 0) return null;

		const roleList = values.jobRoles.map((role) => (
			<div
				key={`jr${role.id}`}
				className="mt-1 medr-rounded-white"
				style={{ paddingTop: '0px', paddingBottom: '0px' }}
			>
				<Row className="w100">
					<Col className="job-role-bar-left">
						<p className="medr-text-inv m-2 w100" style={{ color: '#247986' }}>
							{role.jobTitle}
						</p>
					</Col>
					<Col className="mt-1 job-role-bar-right">
						<Button
							className="sel-button p-1"
							variant="danger"
							onClick={() => removeJobRole(role)}
							style={{ fontSize: '14px', maxHeight: '40px' }}
						>
							Remove
						</Button>
					</Col>
				</Row>
			</div>
		));
		return roleList;
	}

	return (
		<>
			<div className="register-clinician-page">
				<Form>
					<Form.Group controlId="selectedRole" className="mt-2">
						<Form.Label className="my-0">
							{values.jobRoles.length === 0
								? 'Select a clinician role'
								: 'Select additional clinician roles'}
						</Form.Label>
					</Form.Group>
					<JobRoleSearchBar
						changeValues={addJobRole}
						isRegistration
						clearAfterSelect
					/>

					{values.jobRoles.length > 0 && (
						<Form.Group controlId="selectedRoles" className="mt-2">
							<Form.Label className="my-0">Selected clinician roles</Form.Label>
						</Form.Group>
					)}
					{setJobRoles()}

					<div className="row">
						<div
							className="col-md-7"
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'flex-end',
							}}
						>
							<Form.Group controlId="registrationNumber" className="mt-2">
								<Form.Label className="my-0">
									{registrationBody} Registration number
								</Form.Label>
								<Form.Control
									required
									type="name"
									placeholder="Enter registration no"
									defaultValue={values.registrationNumber}
									onChange={(e) =>
										changeValues({ registrationNumber: e.target.value })
									}
								/>
							</Form.Group>
						</div>
						<div className="col-md-5 mt-2">
							<DobFormField
								dob={values.dob}
								dobKey="dob"
								changeValues={changeValues}
							/>
						</div>
					</div>
					<div className="mt-2">
						<ClinicianDescriptionField
							clinicianDescription={values.clinicianDescription}
							updateFormData={changeValues}
						/>
					</div>
					<ReferralContactsDisplay
						contacts={values.appointmentsContacts}
						updateAppointmentContacts={updateAppointmentContacts}
						requiredEmails={[]}
					/>
				</Form>

				<Row className="mt-3">
					<Col>
						{isDoctor ? (
							<ClinicFormDoctor handleAddClinic={addClinic} />
						) : (
							<ClinicFormHcp handleAddClinic={addClinic} />
						)}
					</Col>
				</Row>
				{values.clinics.length > 0 && (
					<Row className="mt-3">
						<Col>
							<ClinicsDisplayBox
								clinics={values.clinics}
								editable
								removeClinic={removeClinic}
								editClinic={editClinic}
								isDoctor={isDoctor}
							/>
						</Col>
					</Row>
				)}
			</div>
			<Row>
				<Col className="text-center">
					<Button
						className="sel-button w80 my-4"
						variant="primary"
						onClick={handleNext}
						disabled={disableNext}
					>
						Next
					</Button>
				</Col>
			</Row>
		</>
	);
}

RegisterClinicianDetailsForm.propTypes = {
	values: PropTypes.shape({
		isReview: PropTypes.bool.isRequired,
		isDoctor: PropTypes.bool.isRequired,
		jobRoleId: PropTypes.number.isRequired,
		jobRoles: jobRolesPropType.isRequired,
		registrationNumber: PropTypes.string.isRequired,
		registrationBody: PropTypes.string.isRequired,
		dob: PropTypes.string.isRequired,
		clinicianDescription: PropTypes.string.isRequired,
		clinics: clinicsPropType.isRequired,
		appointmentsContacts: PropTypes.arrayOf(
			PropTypes.shape({
				type: PropTypes.string.isRequired,
				email: PropTypes.string.isRequired,
				phoneNumber: PropTypes.string.isRequired,
			}).isRequired
		).isRequired,
	}).isRequired,
	changeValues: funcPropType.isRequired,
	addJobRole: funcPropType.isRequired,
	removeJobRole: funcPropType.isRequired,
	addClinic: funcPropType.isRequired,
	removeClinic: funcPropType.isRequired,
	updateAppointmentContacts: funcPropType.isRequired,
	handleNext: funcPropType.isRequired,
};

export default RegisterClinicianDetailsForm;
