import { useState } from 'react';
import { registerCheckClinic } from '@actions/authActions';
import CheckAgeOver18FromDob from '@services/AgeCheck';

const usePatientEntryForm = () => {
    const initData = {
        id: 0,
        title: '',
        firstName: '',
        lastName: '',
        patientReference: '',
        sex: '',
        dob: '',
        primaryEmail: '',
        primaryPhoneNumber: '',
        address: {
            firstLine:'', 
            city: '',
            postcode:'',
        },
        insurancePolicy:{ id: -1},
        insuranceProviderId: -1,
        consent: false,
    };

    const initFormChecks = {
            title: true,
            firstName: true,
            lastName: true,
            sex: true,
            dob: true,
            email: true,
            phone: true,
            postcode: true,
            insuranceProviderId: true,
            consent: true,
        };

    const [patientFormData, setPatientFormData] = useState(initData);
    const [pxToBeLoaded, setPxToBeLoaded] = useState(false);
    const [isNewPatient, setIsNewPatient] = useState(true);
    const [formChecks, setFormChecks] = useState(initFormChecks);
    const [gpsError, setGpsError] = useState(false);
    const [isDOBValid, setIsDOBValid] = useState(true);
    const [formValid, setFormValid] = useState(false);

    const resetFormData = () => setPatientFormData(initData);
    const updatePatientFormData = (data) => {
        setPatientFormData(prevState => ({...prevState, ...data}));
    }
    
	const setDOB = (e) => {
        const { isValid } = CheckAgeOver18FromDob(e.target.value);
		setIsDOBValid(isValid);
		updatePatientFormData({ dob: e.target.value });
	}

    const checkAddress = async (addr) => {
        const { firstLine, city, postcode } = addr;
      
        if (!firstLine || !city || !postcode) {
          return false;
        }
      
        if (postcode === '') {
          return false;
        }
      
        try {
          const addressValid = await registerCheckClinic(firstLine, city, postcode);
          setGpsError(!addressValid);
          return addressValid;
        } catch (error) {
          setGpsError(true);
          return false;
        }
      };
    
    const updatePatientAddress = (data) => {
        setPatientFormData(
            prevState => {
                const addr = {...prevState.address, ...data};
                const newState = {...prevState, address: addr};
                checkAddress(addr)
                return newState;
            }
            );
    }

    const loadPatientData = (px) => {
        if (!px || px===undefined || !px.id || px.id===0) return;
        const insId = px.isSelfPayer ? 0 : px.insurancePolicy.id;
        setPatientFormData(prevState => ({...prevState, ...px, insuranceProviderId: insId}));
    }

    const getRequestData = () => {
        const selfPay = patientFormData.insuranceProviderId===0;
        const formData = new FormData();
        formData.append('title', patientFormData.title);
        formData.append('firstName', patientFormData.firstName);
        formData.append('lastName', patientFormData.lastName);
        formData.append('patientReference', patientFormData.patientReference);
        formData.append('dob', patientFormData.dob);
        formData.append('sex', patientFormData.sex);
        formData.append('primaryEmail', patientFormData.primaryEmail);
        formData.append('primaryPhoneNumber', patientFormData.primaryPhoneNumber);
        formData.append('firstLine', patientFormData.address.firstLine);
        formData.append('city', patientFormData.address.city);
        formData.append('postcode', patientFormData.address.postcode);
        formData.append('isSelfPayer', selfPay);
        formData.append('insuranceProviderId', patientFormData.insuranceProviderId);
        formData.append('consent', patientFormData.consent);
        return formData;
    };

    const getRequestPayload = () => {
        const selfPay = patientFormData.insuranceProviderId===0;
        return {
            title: patientFormData.title,
            firstName: patientFormData.firstName,
            lastName: patientFormData.lastName,
            patientReference: patientFormData.patientReference,
            dob: patientFormData.dob,
            sex: patientFormData.sex,
            primaryEmail: patientFormData.primaryEmail,
            primaryPhoneNumber: patientFormData.primaryPhoneNumber,
            firstLine: patientFormData.address.firstLine,
            city: patientFormData.address.city,
            postcode: patientFormData.address.postcode,
            isSelfPayer: selfPay,
            insuranceProviderId: patientFormData.insuranceProviderId,
            consent: patientFormData.consent,
        };
    }


    const checkFormValid = () => {
        const titleCheck = patientFormData.title!=='';
        const firstNameCheck = patientFormData.firstName!=='';
        const lastNameCheck = patientFormData.lastName!=='';
        const dobCheck = patientFormData.dob!=='';
        const sexCheck = patientFormData.sex!=='';
        const emailCheck = patientFormData.primaryEmail!=='';
        const phoneCheck = patientFormData.primaryPhoneNumber!=='';
        const postcodeCheck = patientFormData.address.postcode!=='';
        const insuranceProviderCheck = patientFormData.insuranceProviderId>-1;

        setFormChecks(prevState => ({
            ...prevState,
            title: titleCheck,
            firstName: firstNameCheck,
            lastName: lastNameCheck,
            dob: dobCheck,
            sex: sexCheck,
            email: emailCheck,
            phone: phoneCheck,
            postcode: postcodeCheck,
            insuranceProviderId: insuranceProviderCheck,
        }));
        const ready = titleCheck && firstNameCheck && lastNameCheck && dobCheck && sexCheck && emailCheck && phoneCheck && postcodeCheck && insuranceProviderCheck; 
        setFormValid(ready);
        return ready;
    }

    const updatePatientConsent = (consent) => {

        if (consent===false)  {
            setPatientFormData(prevState => ({...prevState, consent}));
            return;
        }
        const ready = checkFormValid();
        if (ready) {
            setPatientFormData(prevState => ({...prevState, consent}));
        }
    }

    return { 
        patientFormData,
        pxToBeLoaded, 
        setPxToBeLoaded,
        isNewPatient, 
        setIsNewPatient,
        resetFormData,
        updatePatientFormData,
        updatePatientAddress,
        updatePatientConsent,
        loadPatientData,
        setDOB,
        gpsError,
        setGpsError,
        isDOBValid, 
        setIsDOBValid,
        formChecks,
        checkFormValid,
        formValid,
        getRequestData,
        getRequestPayload,
     };
}

export default usePatientEntryForm;
