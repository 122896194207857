import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useClinician from "../clinicians/useClinician";

const useDirectReferralForm = (clinicianUserProfileId) => {
    
    const initData = {
        // Step 1
		selectedPatient: false,
		isNewPatient: false,
		patientId:0,
		currentPatient: null,

		// Step 2
		patientHistory: "",
		mainDescription: "",

		// Step 3
		jobRoleId: 0,
		jobRole: {},
		targetClinicianId:null,
		targetClinician:null,
    };
    
    const [referralData, setReferralData] = useState(initData);
    const updateReferralData = (data) => setReferralData(prevState => ({...prevState, ...data}));
    const { clinician } = useClinician(clinicianUserProfileId);
    const currentPx = useSelector(state => state.patients.currentPatient);

    // STEP MANAGEMENT
    const [activeStep, setActiveStep ] = useState(0);
    const minStep = 0;
    const maxStep = 3;

    const handleNext = () => activeStep < maxStep ? setActiveStep((previousStep) => previousStep + 1) : null;
    const handleNextTo = (step) => setActiveStep(step);
    const handlePrevious = () => {
        if (activeStep > minStep){
            if (activeStep===maxStep){
                setReferralData({...referralData, isReview: false});
            }
            setActiveStep((previousStep) => previousStep - 1);   
        }
    };


    // SET TARGET CLINICIAN
    const setTargetClinician = () => {
        if (clinician && clinician.id) {
            updateReferralData({
                specifiedClinician: true,
                jobRoleId: clinician.userProfile.jobRole[0].id, 
                jobRole: clinician.userProfile.jobRole[0],
                targetClinicianId: clinician.id,
                targetClinician: clinician
            });
        }
    }
    useEffect(() => setTargetClinician(), [clinician]);


    // SET PATIENT
    const updatePatient = () => {
        // NO PX IN STATE
        if (!currentPx || currentPx===undefined || !currentPx.id || currentPx.id===0) {
            if (!referralData.selectedPatient) {
                updateReferralData({
                    selectedPatient: false,
                    patientId:0,
                    currentPatient: null,
                })
            }
            return;
        }

        // PX IN STATE AND SAME AS CURRENT PX
        if (currentPx.id === referralData.patientId) {
            if (JSON.stringify(currentPx) === JSON.stringify(referralData.currentPatient)) return;
        }

        // PX IN STATE AND DIFFERENT TO CURRENT PX
        updateReferralData({
            selectedPatient: true,
            patientId: currentPx.id,
            currentPatient: currentPx,
        })
        console.log(99999999)
        handleNext();
    }

    useEffect(() => updatePatient(), [currentPx]);



    // request

    const validateReferralData = () => {
        const errorReturn = {valid: false, data: null};
        
        const { currentPatient, patientHistory, mainDescription, jobRole, targetClinician, specifiedClinician } = referralData;
        const insuranceProvider = currentPatient?.insurancePolicy?.provider ?? "Self-Payer";
        
        if (currentPatient===null || currentPatient===undefined ||
            patientHistory===null || patientHistory===undefined ||
            mainDescription===null || mainDescription===undefined ||
            jobRole===null || jobRole===undefined ||
            specifiedClinician===null || specifiedClinician===undefined
            ) return errorReturn;
            
        if (specifiedClinician===true && (targetClinician===null || targetClinician===undefined)) return errorReturn;

        const data = {
            currentPatient,
            patientHistory,
            mainDescription,
            jobRole,
            targetClinician,
            specifiedClinician,
            insuranceProvider
        }

        return {valid: true, data};
    }

    const getRequestData = (crpId) => {
        let requestData = {
            patientId: referralData.patientId,
            crpId,
            jobRoleId: referralData.jobRole.id,
            mainDescription: referralData.mainDescription,
            patientHistory: referralData.patientHistory,
            specifiedClinician: referralData.specifiedClinician,
        };
    
        if (referralData.specifiedClinician){
            requestData = {...requestData, receiverCrpId: referralData.targetClinician.id};
        }
        return requestData;
    };

    useEffect(() => console.log(referralData), [referralData]);

    return {
        referralData,
        updateReferralData,
        activeStep,
        handleNext,
        handleNextTo,
        handlePrevious,
        validateReferralData,
        getRequestData,
        setTargetClinician
    };
};

export default useDirectReferralForm;