export const PATIENTS_REQUEST = 'PATIENTS_REQUEST';
export const PATIENTS_SUCCESS = 'PATIENTS_SUCCESS';
export const PATIENTS_FAIL = 'PATIENTS_FAIL';

export const CURRENT_PATIENT_REQUEST = 'CURRENT_PATIENT_REQUEST';
export const CURRENT_PATIENT_SUCCESS = 'CURRENT_PATIENT_SUCCESS';
export const CURRENT_PATIENT_FAIL = 'CURRENT_PATIENT_FAIL';
export const CURRENT_PATIENT_RESET = 'CURRENT_PATIENT_RESET';

export const CREATE_PATIENT_REQUEST = 'CREATE_PATIENT_REQUEST';
export const CREATE_PATIENT_SUCCESS = 'CREATE_PATIENT_SUCCESS';
export const CREATE_PATIENT_FAIL = 'CREATE_PATIENT_FAIL';

export const CREATE_PATIENT_BY_IMAGE_REQUEST = 'CREATE_PATIENT_BY_IMAGE_REQUEST';
export const CREATE_PATIENT_BY_IMAGE_SUCCESS = 'CREATE_PATIENT_BY_IMAGE_SUCCESS';
export const CREATE_PATIENT_BY_IMAGE_FAIL = 'CREATE_PATIENT_BY_IMAGE_FAIL';

export const CLINICIAN_PATIENTS_REQUEST = 'CLINICIAN_PATIENTS_REQUEST';
export const CLINICIAN_PATIENTS_SUCCESS = 'CLINICIAN_PATIENTS_SUCCESS';
export const CLINICIAN_PATIENTS_FAIL = 'CLINICIAN_PATIENTS_FAIL';

export const PATIENT_ACTIVE_TOGGLE_REQUEST = 'PATIENT_ACTIVE_TOGGLE_REQUEST';
export const PATIENT_ACTIVE_TOGGLE_SUCCESS = 'PATIENT_ACTIVE_TOGGLE_SUCCESS';
export const PATIENT_ACTIVE_TOGGLE_FAIL = 'PATIENT_ACTIVE_TOGGLE_FAIL';

export const PATIENT_LIST_REQUEST = 'PATIENT_LIST_REQUEST';
export const PATIENT_LIST_SUCCESS = 'PATIENT_LIST_SUCCESS';
export const PATIENT_LIST_FAIL = 'PATIENT_LIST_FAIL';

export const PATIENT_DELETE_REQUEST = 'PATIENT_DELETE_REQUEST';
export const PATIENT_DELETE_SUCCESS = 'PATIENT_DELETE_SUCCESS';
export const PATIENT_DELETE_FAIL = 'PATIENT_DELETE_FAIL';

export const PATIENT_UPDATE_REQUEST = 'PATIENT_UPDATE_REQUEST';
export const PATIENT_UPDATE_SUCCESS = 'PATIENT_UPDATE_SUCCESS';
export const PATIENT_UPDATE_FAIL = 'PATIENT_UPDATE_FAIL';

export const PATIENT_REFERRALS_REQUEST = 'PATIENT_REFERRALS_REQUEST';
export const PATIENT_REFERRALS_SUCCESS = 'PATIENT_REFERRALS_SUCCESS';
export const PATIENT_REFERRALS_FAIL = 'PATIENT_REFERRALS_FAIL';

export const GPS_COORDS_REQUEST = 'GPS_COORDS_REQUEST';
export const GPS_COORDS_SUCCESS = 'GPS_COORDS_SUCCESS';
export const GPS_COORDS_FAIL = 'GPS_COORDS_FAIL';
