import axios from 'axios';
import Cookies from 'js-cookie';
import * as CONSTS from '@constants/userConstants';
import * as URL from '@constants/urls';
import * as MSG from '@constants/messageConstants';
import { loadUser } from './profileActions';

export const postUserProfilePicture =
	(formData) =>
	async (dispatch) => {

		dispatch({ type: CONSTS.UPDATE_PROFILE_PIC_REQUEST });

		const payload = formData;

		try {
			const token = Cookies.get('authToken');
			const headers = {headers: {
				Authorization: `Token ${token}`,
				'Content-Type': 'multipart/form-data'
			}};

			const { data } = await axios.post(
				`${URL.USER_PIC_UPLOAD}`, payload, headers);

			dispatch({
				type: CONSTS.UPDATE_PROFILE_PIC_SUCCESS,
				payload: data,
			});

            if ("message" in data){
                dispatch({
                    type: MSG.MESSAGE_RECEIVED,
                    payload: data.message
                });
            }

			dispatch(loadUser());
		} catch (error) {
			dispatch({
				type: CONSTS.UPDATE_PROFILE_PIC_FAIL,
			});

			const msg = {variant:'danger', message:"Error uploading profile picture"}

			dispatch({
				type: MSG.MESSAGE_RECEIVED,
				payload: msg
			});
		}
	};

export const updateUserProfile = (title, firstName, lastName) => async(dispatch) => {
    dispatch({type: CONSTS.USER_UPDATE_PROFILE_REQUEST});

	const payload = {
		title,
		firstName,
		lastName,
	};

	try {
		const token = Cookies.get('authToken');
		// const state = getState();
		// const { auth } = state;
		// const { token } = auth;
		const headers = {headers: {Authorization: `Token ${token}`}};

		const { data } = await axios.put(
			`${URL.USER_PROFILE_UPDATE}`, payload, headers);
		
        dispatch({
            type: CONSTS.USER_UPDATE_PROFILE_SUCCESS,
            payload: data,
        });

		dispatch(loadUser());
		
		if ("message" in data){
			dispatch({
				type: MSG.MESSAGE_RECEIVED,
				payload: data.message
			});
		}
		
	} catch (error) {
		dispatch({
			type: CONSTS.USER_UPDATE_PROFILE_FAIL,
		});
		const msg = {variant:'danger', message:"Error updating profile"}

		dispatch({
			type: MSG.MESSAGE_RECEIVED,
			payload: msg
		});
	}

};

export const updateUserPassword = (currentPassword, newPassword) => async(dispatch) => {
    dispatch({type: CONSTS.USER_UPDATE_PASSWORD_REQUEST});

	const payload = { currentPassword, newPassword };

	try {
		const token = Cookies.get('authToken');
		// const state = getState();
		// const { auth } = state;
		// const { token } = auth;
		const headers = {headers: {Authorization: `Token ${token}`}};

		const { data } = await axios.put(
			`${URL.USER_UPDATE_PASSWORD}`, payload, headers);

        dispatch({
            type: CONSTS.USER_UPDATE_PASSWORD_SUCCESS
    	});

		if ("message" in data){
			dispatch({
				type: MSG.MESSAGE_RECEIVED,
				payload: data.message
			});
		}

	
	} catch (error) {
		dispatch({type: CONSTS.USER_UPDATE_PASSWORD_FAIL});
		const msg = {variant:'danger', message:"Error uploading password"}

		dispatch({
			type: MSG.MESSAGE_RECEIVED,
			payload: msg
		});
	}

};
