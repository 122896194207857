import React, { useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import ReferralStepPatient from '@components/referrals/entry/ReferralStepPatient';
import ReferralStepInfo from '@components/referrals/entry/ReferralStepInfo';
import ReferralStepSelectClinician from '@components/referrals/entry/ReferralStepSelectClinician';
import ReferralStepReview from '@components/referrals/entry/ReferralStepReview';
// import { SET_POSTCODE_SUCCESS } from '@constants/searchConstants';
import useReferralForm from '@hooks/referrals/useReferralForm';
import useUserPatients from '@hooks/patients/useUserPatients';

function ReferralEntry() {
	const [searchParams] = useSearchParams();
	const searchPxId = searchParams.get('patientId');
	const [isInitialised, setIsInitialised] = React.useState(false);

	const { resetCurrentPx, setCurrentPatientById } = useUserPatients();

	let pxIsReady = false;
	const initialise = () => {
		if (!searchPxId) {
			resetCurrentPx();
			pxIsReady = false;
		} else {
			const pxId = parseInt(searchPxId, 10);
			setCurrentPatientById(pxId);
			pxIsReady = true;
		}
		setIsInitialised(true);
	};

	useEffect(() => initialise(), []);

	const {
		activeStep,
		handleNext,
		handlePrevious,
		referralData,
		updateReferralData,
		validateReferralData,
		getRequestData,
		setTargetClinicianOpen,
		setTargetClinician,
		refreshClinicians,
		pxAdded,
	} = useReferralForm(pxIsReady);

	const { selectedPatient, patientId, currentPostcode } = referralData;

	const changeGps = (e) => {
		if (
			!e ||
			e === undefined ||
			!e.searchLatitude ||
			!e.searchLongitude ||
			e.searchLatitude === undefined ||
			e.searchLongitude === undefined ||
			e.searchLatitude === 0 ||
			e.searchLongitude
		)
			return;
		updateReferralData(e);
	};

	const successfulPxForm = (value) => {
		if (value && pxAdded) handleNext();
	};

	return (
		<>
			{isInitialised && activeStep === 0 && (
				<ReferralStepPatient
					currentPatientId={patientId}
					patientSelected={selectedPatient}
					addNewPatientSuccess={successfulPxForm}
				/>
			)}
			{isInitialised && activeStep === 1 && (
				<ReferralStepInfo
					values={referralData}
					changeValue={updateReferralData}
					sendNext={handleNext}
				/>
			)}
			{isInitialised && activeStep === 2 && (
				<ReferralStepSelectClinician
					setTargetClinicianOpen={setTargetClinicianOpen}
					setTargetClinician={setTargetClinician}
					values={referralData}
					currentPostcode={currentPostcode}
					changeValue={updateReferralData}
					changeGPS={changeGps}
					refreshClinicians={refreshClinicians}
				/>
			)}
			{isInitialised && activeStep === 3 && (
				<ReferralStepReview
					validateReferralData={validateReferralData}
					getRequestData={getRequestData}
					values={referralData}
				/>
			)}

			<div
				className="medr-layout text-center ref-info-page"
				style={{ maxWidth: '700px', marginLeft: 'auto', marginRight: 'auto' }}
			>
				<Row className="mt-2">
					<Col>
						{activeStep !== 0 ? (
							<Button
								className="sel-button w80"
								variant="dark"
								onClick={handlePrevious}
							>
								Previous
							</Button>
						) : null}
						{activeStep === 0 && selectedPatient === true ? (
							<Button
								className="sel-button w80"
								variant="primary"
								disabled={!selectedPatient}
								onClick={handleNext}
							>
								Next
							</Button>
						) : null}
					</Col>
				</Row>
			</div>
		</>
	);
}

export default ReferralEntry;
