import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { getPatientNameString } from '@actions/general';
import  { patientPropType, boolPropType, funcPropType } from '@constants/propTypes';


function PatientImagePopup({ patient, showModal, handleClose }){
    if (!patient || patient===undefined) {
        return null;
    }
    
    const { detailsImage } = patient;

    const imagePath = detailsImage!==null ? detailsImage :'/logos/color1-white_logo_transparent_background.png';

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className='medr-inv'>{getPatientNameString(patient)}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='text-center'>
                <img className="px-details-picture" src={imagePath} alt="Patient details" />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="info" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

PatientImagePopup.propTypes = {
    patient: patientPropType.isRequired,
    showModal: boolPropType.isRequired,
    handleClose: funcPropType.isRequired
}

export default PatientImagePopup;