import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAdminInsurers, verifyInsurer } from "@/actions/adminActions";


export function useAdminInsurersTable() {

    const dispatch = useDispatch();
    useEffect(() => {
        const fetchInsurers = async () => {
            await dispatch(getAdminInsurers());
        };

        fetchInsurers();
    }, []);

    const insurers = useSelector((state) => state.admin.insurers.insurers);    
    const unverifiedInsurers = useSelector((state) => state.admin.insurers.unverifiedInsurers);

    const setInsurerVerified = async (id: number) => {
        try {
            // Wait for the first dispatch to complete
            await dispatch(verifyInsurer(id));
            
            // Once the first dispatch is complete, run the second dispatch
            await dispatch(getAdminInsurers());
        } catch (error) {
            console.error("Error verifying insurer:", error);
            // Handle any errors here
        }
    }

    return { 
        insurers,
        unverifiedInsurers,
        setInsurerVerified
     };
}
