import React, { useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getAdminClinicansList } from '@actions/adminActions';
import AdminCrpStaffTable from '@components/admin/AdminCrpStaffTable';
import AdminCrpNonVerifiedTable from '@components/admin/AdminCrpNonVerifiedTable';
import AdminCrpCliniciansTable from '@components/admin/AdminCrpCliniciansTable';


function AdminCliniciansListScreen() {
	/**
	 * 1. Load admin clinician lists
	 * 
	 */

	const dispatch = useDispatch();
	const refreshcliniciansLists = () => dispatch(getAdminClinicansList());

	useEffect(() => {
		refreshcliniciansLists();
	}, [dispatch]);

		
	const staffTable = <AdminCrpStaffTable />
	const nonVerifiedTable = <AdminCrpNonVerifiedTable />
	const cliniciansTable = <AdminCrpCliniciansTable />
	
	return (
		<>
			<h1>Clinicians</h1>
			<Tabs
				defaultActiveKey="verifiedClinicians"
				id="custom-tabs"
				className="custom-tabs mb-3"
				>
				<Tab eventKey="verifiedClinicians" title="Verified Clinicians" className="custom-tabs">
					{cliniciansTable}
				</Tab>
				<Tab eventKey="nonVerified" title="Unverified Accounts" className="custom-tabs">
					{nonVerifiedTable}
				</Tab>
				<Tab eventKey="staff" title="MedR Staff" className="custom-tabs">
					{staffTable}
				</Tab>
			</Tabs>
		</>
	);
}

export default AdminCliniciansListScreen;
