import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useCliniciansList = () => {
	const fullClinicianList = useSelector(
		(state) => state.clinicians.clinicianList
	);
	const fullClinicAddresses = useSelector(
		(state) => state.clinicians.addressesList
	);

	const [clinicianList, setClinicianList] = useState([]);
	const [clinicAddresses, setClinicAddresses] = useState([]);
	const [showOnlyFavourites, setShowOnlyFavourites] = useState(false);
	const [filterString, setFilterString] = useState('');
	const [hoveredClinicianUserProfileIds, setHoveredClinicianUserProfileIds] =
		useState([]);
	const [hoveredAddressIds, setHoveredAddressIds] = useState([]);
	const [filterViewCoords, setFilterViewCoords] = useState(null);

	const updateMapViewCoords = (coords) => {
		if (!coords || coords === undefined) return;
		setFilterViewCoords(coords);
	};

	const filterCliniciansViewCoords = (clinicians) => {
		if (!filterViewCoords || filterViewCoords === undefined) return clinicians;
		return clinicians.filter((clinician) => {
			const lat = clinician.clinic.address.latitude;
			const lng = clinician.clinic.address.longitude;
			const mainInView = (
				lat >= filterViewCoords.sw.lat &&
				lat <= filterViewCoords.ne.lat &&
				lng >= filterViewCoords.sw.lng &&
				lng <= filterViewCoords.ne.lng
			);
			if (mainInView) return true;
			
			if (clinician.otherClinics && Array.isArray(clinician.otherClinics)) {

				return clinician.otherClinics.some((otherClinic) => {
					return (
						otherClinic.clinic.address.latitude >= filterViewCoords.sw.lat &&
						otherClinic.clinic.address.latitude <= filterViewCoords.ne.lat &&
						otherClinic.clinic.address.longitude >= filterViewCoords.sw.lng &&
						otherClinic.clinic.address.longitude <= filterViewCoords.ne.lng
					);
				});
			}
			return false;
		});
	};

	const filterCliniciansFavourites = (clinicians) => {
		if (!showOnlyFavourites) return clinicians;
		return clinicians.filter((clinician) => clinician.isFavourite);
	};

	const filterCliniciansByString = (clinicians) => {
		if (!filterString || filterString === undefined || filterString === '')
			return clinicians;
		return clinicians.filter((clinician) =>
			`${clinician.userProfile.title} ${clinician.userProfile.firstName} ${clinician.userProfile.lastName}`
				.toLowerCase()
				.includes(filterString.toLowerCase())
		);
	};

	const filterClinicians = () => {
		const favLevel = filterCliniciansFavourites(
			fullClinicianList,
			showOnlyFavourites
		);
		const strLevel = filterCliniciansByString(favLevel, filterString);
		const coordLevel = filterCliniciansViewCoords(strLevel);
		return coordLevel;
	};

	const filterClinicAddresses = (clinicianUserProfileIds) => {
		const newAddresses = fullClinicAddresses.filter((address) =>
			address.userProfileIds.some((id) => clinicianUserProfileIds.includes(id))
		);
		return newAddresses;
	};

	const updateClinicianList = () => {
		if (fullClinicianList === null || fullClinicianList === undefined) return;
		const clins = filterClinicians();
		const clinIds = clins.map((clinician) => clinician.userProfile.id);

		setClinicianList(clins);

		if (fullClinicAddresses === null || fullClinicAddresses === undefined)
			return;
		const adds = filterClinicAddresses(clinIds);
		setClinicAddresses(adds);
	};

	useEffect(() => {
		updateClinicianList();
	}, [
		fullClinicianList,
		showOnlyFavourites,
		filterString,
		fullClinicAddresses,
		filterViewCoords,
	]);

	const toggleShowOnlyFavourites = (value) => setShowOnlyFavourites(value);

	const updateHovered = (data) => {
		if (
			!data ||
			data === undefined ||
			!data.hoveredClinicianUserProfileIds ||
			!data.hoveredAddressIds
		)
			return;

		/**
		 * Theocharis:
		 * Added this check so the hoveredAddressIds array is updated only if we have different count of hovered markers
		 * Theoretically there is a chance of having two markers very close to each other, and a very fast cursor move could be fail to update the marker.
		 * But I think it is ok for this use case.
		 *
		 */

		if (
			!hoveredClinicianUserProfileIds.some((r) =>
				data.hoveredClinicianUserProfileIds.includes(r)
			)
		) {
			setHoveredClinicianUserProfileIds(data.hoveredClinicianUserProfileIds);
		}

		if (!hoveredAddressIds.some((r) => data.hoveredAddressIds.includes(r))) {
			setHoveredAddressIds(data.hoveredAddressIds);
		}
	};

	const getClinicianById = (id) => {
		const clins = clinicianList.filter((clinician) => clinician.id === id);
		if (clins.length === 0) return null;
		return clins[0];
	};

	return {
		clinicianList,
		clinicAddresses,
		getClinicianById,
		setFilterString,
		showOnlyFavourites,
		toggleShowOnlyFavourites,
		hoveredClinicianUserProfileIds,
		hoveredAddressIds,
		updateHovered,
		updateMapViewCoords,
	};
};

export default useCliniciansList;
