import { registerCheckClinic } from '@actions/authActions';

const useAddressChecker = () => {
	const checkAddress = async (address) => {
		if (address.postcode === '') {
			return;
		}
		registerCheckClinic(address.firstLine, address.city, address.postcode)
			.then((addressValid) => {
				return addressValid;
			})
			.catch((error) => {
				console.log(error);
				return false;
			});
	};
	return { checkAddress };
};

export default useAddressChecker;
