import axios from 'axios';
import * as CONSTS from '@constants/authConstants';
import * as CONSTSUSR from '@constants/userConstants';
import * as URL from '@constants/urls';
import { unauthResponseClear } from './storageActions';

import {  authPostRequest, anyPostRequest, authPutRequest, authGetRequest, anyGetRequest, anyPutRequest } from './axiosActions';

export const login = (username, password, isSuccessfull) => async (dispatch) => {
	const payload = { username, password };

	const inputData = {
		URL: `/${URL.LOGIN}`,
		GET_REQUEST: CONSTS.LOGIN_REQUEST,
		GET_SUCCESS: CONSTS.LOGIN_SUCCESS,
		GET_FAIL: CONSTS.LOGIN_FAIL,
		payload
	};

	await dispatch(anyPostRequest(inputData, isSuccessfull));
};

export const checkAuth = (isSuccess) => async (dispatch) => {
	const inputData = {
		URL: `${URL.CHECK_AUTH}`,
		GET_REQUEST: CONSTS.CHECK_AUTH_REQUEST,
		GET_SUCCESS: CONSTS.CHECK_AUTH_SUCCESS,
		GET_FAIL: CONSTS.CHECK_AUTH_FAIL,
	};
	
	dispatch(authGetRequest(inputData, isSuccess));
}

export const register = (data, sendIsSuccess) => async (dispatch) => {
	const payload = data;

	const inputData = {
		URL: `${URL.REGISTER}`,
		GET_REQUEST: CONSTS.REGISTER_REQUEST,
		GET_SUCCESS: CONSTS.REGISTER_SUCCESS,
		GET_FAIL: CONSTS.REGISTER_FAIL,
		payload
	};

	dispatch(anyPostRequest(inputData, sendIsSuccess));
};

export const registerDelegateUser = (data, sendIsSuccess) => async (dispatch) => {
	const payload = data;

	const inputData = {
		URL: `${URL.REGISTER_DELEGATE}`,
		GET_REQUEST: CONSTS.REGISTER_DELEGATE_REQUEST,
		GET_SUCCESS: CONSTS.REGISTER_DELEGATE_SUCCESS,
		GET_FAIL: CONSTS.REGISTER_DELEGATE_FAIL,
		payload
	};

	dispatch(authPostRequest(inputData, sendIsSuccess));
};

export const getVerifyDelegateUser = (userHash, timeHash, code, sendIsSuccess) => async (dispatch) => {

	const inputData = {
		URL: `${URL.REGISTER_DELEGATE_INFO}user=${userHash}&time=${timeHash}&code=${code}/`,
		GET_REQUEST: CONSTS.REGISTER_DELEGATE_INFO_REQUEST,
		GET_SUCCESS: CONSTS.REGISTER_DELEGATE_INFO_SUCCESS,
		GET_FAIL: CONSTS.REGISTER_DELEGATE_INFO_FAIL,
	};

	dispatch(anyGetRequest(inputData, sendIsSuccess));
};

export const verifyDelegateUser = (payload, sendIsSuccess) => async (dispatch) => {

	const inputData = {
		URL: `${URL.VERIFY_DELEGATE}`,
		GET_REQUEST: CONSTS.VERIFY_DELEGATE_REQUEST,
		GET_SUCCESS: CONSTS.VERIFY_DELEGATE_SUCCESS,
		GET_FAIL: CONSTS.VERIFY_DELEGATE_FAIL,
		payload
	};

	dispatch(anyPutRequest(inputData, sendIsSuccess));
};
export const updateDelegateUserPermission = (payload, sendIsSuccess) => async (dispatch) => {

	const inputData = {
		URL: `${URL.UPDATE_DELEGATE_PERMISSION}`,
		GET_REQUEST: CONSTS.UPDATE_DELEGATE_PERMISSION_REQUEST,
		GET_SUCCESS: CONSTS.UPDATE_DELEGATE_PERMISSION_SUCCESS,
		GET_FAIL: CONSTS.UPDATE_DELEGATE_PERMISSION_FAIL,
		payload
	};

	dispatch(authPostRequest(inputData, sendIsSuccess));
};

export const registerCheckEmail = async (email) => {
	const payload = {email};
	try {
		const { data } = await axios.post(URL.REGISTER_CHECK_EMAIL, payload);
		const { emailValid } = data;
		return emailValid;
	} catch (error) {
		return false;
	}
};

export const registerCheckClinic = async (firstLine, city, postcode) => {
	const payload = {firstLine, city, postcode};
	try {
		const { data } = await axios.post(URL.REGISTER_CHECK_CLINIC, payload);
		const { addressValid } = data;
		return addressValid;
	} catch (error) {
		return false;
	}
};

export const notifyMedrFailedRegistration = (userHash, timeHash, verificationCode) => async (dispatch) => {
	const payload = {userHash, timeHash, verificationCode};

	const inputData = {
		URL: `${URL.NOTIFY_MEDR_FAILED_REGISTRATION}`,
		GET_REQUEST: CONSTS.NOTIFY_MEDR_FAILED_REGISTRATION_REQUEST,
		GET_SUCCESS: CONSTS.NOTIFY_MEDR_FAILED_REGISTRATION_SUCCESS,
		GET_FAIL: CONSTS.NOTIFY_MEDR_FAILED_REGISTRATION_FAIL,
		payload
	};
	dispatch(anyPostRequest(inputData));
}

export const verifyUserEmail = (userHash, timeHash, verificationCode,  sendIsSuccess) => async (dispatch) => {
	const payload = {};

	const inputData = {
		URL: `${URL.VERIFY_EMAIL}user=${userHash}&time=${timeHash}&code=${verificationCode}/`,
		GET_REQUEST: CONSTS.VERIFY_EMAIL_REQUEST,
		GET_SUCCESS: CONSTS.VERIFY_EMAIL_SUCCESS,
		GET_FAIL: CONSTS.VERIFY_EMAIL_FAIL,
		payload
	};

	const isSuccess = (value) => {
		sendIsSuccess(value);
		if (value===false) {
			dispatch(notifyMedrFailedRegistration(userHash, timeHash, verificationCode));
		}

	}
	dispatch(anyPostRequest(inputData, isSuccess));
};

export const logout = () => async (dispatch) => {
	const inputData = {
		URL: `${URL.LOGOUT}`,
		GET_REQUEST: CONSTS.LOGOUT_REQUEST,
		GET_SUCCESS: CONSTS.LOGOUT_SUCCESS,
		GET_FAIL: CONSTS.LOGOUT_FAIL,
		payload: {}
	};
	
	dispatch(authPostRequest(inputData));

	unauthResponseClear();
};

export const resetPassword = (email, isSuccessfull) => async (dispatch) => {
	const payload = { email };

	const inputData = {
		URL: `${URL.RESET_PASSWORD}`,
		GET_REQUEST: CONSTS.RESET_PASSWORD_REQUEST,
		GET_SUCCESS: CONSTS.RESET_PASSWORD_SUCCESS,
		GET_FAIL: CONSTS.RESET_PASSWORD_FAIL,
		payload
	};

	dispatch(anyPostRequest(inputData, isSuccessfull));
}

export const resetPasswordSetNew = (payload, sendIsSuccess) => async (dispatch) => {
	
	const inputData = {
		URL: `${URL.USER_RESET_SET_PASSWORD}`,
		GET_REQUEST: CONSTS.RESET_PASSWORD_SET_NEW_REQUEST,
		GET_SUCCESS: CONSTS.RESET_PASSWORD_SET_NEW_SUCCESS,
		GET_FAIL: CONSTS.RESET_PASSWORD_SET_NEW_FAIL,
		payload
	};
	
	dispatch(anyPostRequest(inputData, sendIsSuccess));
}

export const sendResetPassword = (data, isSuccess) => async (dispatch) => {
	const payload = data;

	const inputData = {
		URL: `${URL.USER_UPDATE_PASSWORD}`,
		GET_REQUEST: CONSTSUSR.USER_UPDATE_PASSWORD_REQUEST,
		GET_SUCCESS: CONSTSUSR.USER_UPDATE_PASSWORD_SUCCESS,
		GET_FAIL: CONSTSUSR.USER_UPDATE_PASSWORD_FAIL,
		payload
	};

	dispatch(authPutRequest(inputData, isSuccess));
}