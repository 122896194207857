import { useState } from 'react';

const useInsuranceProviderForm = () => {
    const initNewProvider = {
        provider: '',
    }
    const [showForm, setShowForm] = useState(false);
    const [currentNewProvider, setCurrentNewProvider] = useState(initNewProvider); // [ {provider: 'Aetna'}, {provider: 'Blue Cross'}
    const [newProviders, setNewProviders] = useState([]);

    const toggleShowForm = () => {
        setShowForm(state => !state);
    }
    const updateCurrentNewProvider = (e) => {
        setCurrentNewProvider({...currentNewProvider, provider: e.provider});
    }

    const addNewProvider = () => {
        if (currentNewProvider.provider === '') return;
        if (newProviders.find(item => item.provider === currentNewProvider.provider)) {
            setCurrentNewProvider(initNewProvider);
            return;
        };
        const newList = [...newProviders, currentNewProvider];
        setNewProviders(newList);
        setCurrentNewProvider(initNewProvider);
    }
    const removeNewProvider = (newInsurance) => {
        const newList = newProviders.filter(item => item.provider !== newInsurance.provider);
        setNewProviders(newList);
    }

    return {
        newProviders, 
        addNewProvider, 
        removeNewProvider, 
        showForm, 
        toggleShowForm, 
        currentNewProvider,
        updateCurrentNewProvider
    };
}

export default useInsuranceProviderForm;