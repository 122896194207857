import React, { useState } from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';

function PasswordField({
	controlId,
	label,
	password,
	keyLabel,
	changeValues,
	autoComplete = '',
	isInvalid = false,
	invalidString = '',
}) {
	const [passwordToggle, setPaswordToggle] = useState(false);
	const togglePasswordVisibility = () => {
		setPaswordToggle((prev) => !prev);
	};
	return (
		<Form.Group controlId={controlId} className="mt-2">
			<Form.Label className="my-0">{label}</Form.Label>
			<InputGroup>
				<Form.Control
					className="password-input-box"
					required
					type={passwordToggle ? 'text' : 'password'}
					placeholder="Enter Password"
					value={password}
					onChange={(e) => changeValues({ [keyLabel]: e.target.value })}
					isInvalid={isInvalid}
					autoComplete={autoComplete}
				/>

				<Button
					className="password-input-btn"
					onClick={togglePasswordVisibility}
				>
					{passwordToggle ? (
						<i className="fa-solid fa-eye-slash" />
					) : (
						<i className="fa-solid fa-eye" />
					)}
				</Button>
				<Form.Control.Feedback type="invalid">
					{invalidString}
				</Form.Control.Feedback>
			</InputGroup>
		</Form.Group>
	);
}

export default PasswordField;
