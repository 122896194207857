import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSearchParams, useNavigate } from 'react-router-dom';
import PatientEntryForm from '@components/patient/PatientEntryForm';
import useUserPatients from '@hooks/patients/useUserPatients';

function PatientEntryScreen() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [isInitialised, setIsInitialised] = useState(false);
	const inputPatientId = searchParams.get('patientId');
	const { resetCurrentPx, setCurrentPatientById } = useUserPatients();

	const initialise = () => {
		if (!inputPatientId) {
			resetCurrentPx();
		} else {
			const pxId = parseInt(inputPatientId, 10);
			setCurrentPatientById(pxId);
		}
		setIsInitialised(true);
	};
	useEffect(() => initialise(), []);

	const successfullRequest = (value) => {
		if (value === true) {
			navigate('/patients');
		}
	};

	return (
		<div
			className="medr-layout text-center newpatient-page"
			style={{ maxWidth: '700px', marginLeft: 'auto', marginRight: 'auto' }}
		>
			<Row className="medr-layout text-center">
				<Col>
					<h4>Enter Patient Information</h4>
				</Col>
			</Row>

			{isInitialised && (
				<PatientEntryForm
					inputPatientId={inputPatientId}
					isSuccessful={successfullRequest}
				/>
			)}
		</div>
	);
}

export default PatientEntryScreen;
