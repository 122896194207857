import { useSelector } from "react-redux";

const useUserClinicianProfiles = () => {
    const clinicianProfiles = useSelector((state) => state.profile.clinicianProfiles);
    
    const validateClinicianProfiles = () => {
        if (clinicianProfiles===null || clinicianProfiles===undefined) return false;
        if (clinicianProfiles.length===0) return false;
        return true;
    };
    
    return { 
        clinicianProfiles,
        validateClinicianProfiles
     };
}

export default useUserClinicianProfiles;