import React, { useState } from "react";
import { clinicanProfilePropType, funcPropType } from '@constants/propTypes';
import FavouriteClinicianStarPopup from "../FavClinicianStarPopup";
import './star.css';

function FavouriteClinicianStar({ clinician, requestMade = () => {} }) {
    const [showPopup, setShowPopup] = useState(false);
    const { isFavourite } = clinician;

    const onClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShowPopup(true);
    }
    const onKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ' ') {
            onClick(e);
        }
    };

    return (
        <>
            <FavouriteClinicianStarPopup clinician={clinician} showPopup={showPopup} handleClose={() => setShowPopup(false)} requestMade={requestMade} />
            <div 
                className={`${isFavourite ? '' : 'un'}star-button`} 
                onClick={onClick} 
                onKeyDown={onKeyDown}
                role="button"
                tabIndex="0"
                aria-label='Star'
                >
                <i className={`${isFavourite ? 'fas' : 'far'} fa-star`} />
            </div>
        </>
    )
}


FavouriteClinicianStar.propTypes = {
    clinician: clinicanProfilePropType.isRequired,
    requestMade: funcPropType
};

export default FavouriteClinicianStar;