import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { 
    getCurrentPatient,
    resetCurrentPatient,
 } from '@actions/patientActions';


const useCurrentPatient = (pxId) => {
    const dispatch = useDispatch();

    const currentPatient = useSelector(state => state.patients.currentPatient);

    const updatePatientById = (id) => {
        if (!id || id===0) return;
        dispatch(getCurrentPatient(id));
    }

    useEffect(() => {
        if(!pxId || pxId===0) {
            dispatch(resetCurrentPatient());
            return;
        }
        updatePatientById(pxId);
    }, [pxId]);


    return { 
        currentPatient,
        updatePatientById,
     };
}

export default useCurrentPatient;