import React, { useState } from 'react';
import  { Card, Row, Col, Button } from 'react-bootstrap';
// import InviteUserForm from '../user/InviteUserForm';
import FeatureSuggestionPopup from './FeatureSuggestionPoppup';

function AppUpdatesPanel() {

    // const [displayInvitUserForm, setDisplayInvitUserForm] = useState(false);
    const [displaySuggestionsForm, setDisplaySuggestionsForm] = useState(false);

	return (
        <>
            {displaySuggestionsForm ? <FeatureSuggestionPopup showModal={displaySuggestionsForm} handleClose={() => setDisplaySuggestionsForm(false)}/> : null}
            <div className='medr-layout text-center'style={{marginLeft: 'auto', marginRight: 'auto', width: '100%', height: '100%'}}>
                <Card className="my-3 p-3 rounded d-flex flex-fill medr-card" id="updatesCard" style={{height: 'calc(100% - 46px)'}}> {/* 46px is the height of the card title */}
                    <Card.Title>Recent Updates</Card.Title>
                    <Card.Body className="d-flex flex-column text-left">
                        <Row>
                            <Col>
                                <p className='updates-tab-title'>Improved map</p>
                                <p className='updates-tab-content'>Effortlessly match patients with specialists using our enhanced clinician finder.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className='updates-tab-title'>Direct referrals</p>
                                <p className='updates-tab-content'>Streamlining the referral process when you have already identified a specialist.</p>
                            </Col>
                        </Row>
                        <Row className='mb-2'>
                            <Button className='insurance-check-btn' variant='info' onClick={() => setDisplaySuggestionsForm(true)}>Request a feature</Button>          
                        </Row>
                        <div className="mt-auto"> </div>
                    </Card.Body>
                </Card>
            </div>
        </>
	);
}


export default AppUpdatesPanel;
