import { useEffect, useState } from 'react';

const useReferralsContactsDisplay = (contacts, updateAppointmentContacts, requiredEmails) => {
    const initNewContact = {
        type: "email",
        email: "",
        phoneNumber: '',
    };
    const [newContacts, setNewContacts] = useState(null);
    const [showForm, setShowForm] = useState(null);
    const [newContactFormData, setNewContactFormData] = useState(initNewContact);

    useEffect(() => {
        if (contacts) {
            const nc = contacts.map(item =>  {
                const isMain = requiredEmails.some(em => em === item.email);
                return {...item, isMain };
            }
            );
            setNewContacts(nc);
        } else {
            setNewContacts([]);
        }
    }, [contacts]);

    const updateNewContactEmail = (e) => setNewContactFormData({type: 'email', email: e.email, phoneNumber:''});
    const updateNewContactPhoneNumber = (e) => setNewContactFormData({type: 'phoneNumber', email: '', phoneNumber: e.phoneNumber});   
    const addNewContact = () => {
        if (!newContactFormData) return;
        if (newContactFormData.email === '' && newContactFormData.phoneNumber === '') return;
        if (newContacts.find(item => item.type === newContactFormData.type  && item.email === newContactFormData.email && item.phoneNumber === newContactFormData.phoneNumber)) {
            setNewContactFormData(initNewContact);
            return;
        }
        const newList = [...newContacts, newContactFormData];
        setNewContacts(newList);
        setNewContactFormData(initNewContact);
        updateAppointmentContacts(newList);
    }
    const removeNewContact = (contact) => {
        setTimeout(() => {
            const newList = newContacts.filter(item => item !== contact);
            setNewContacts(newList);
            updateAppointmentContacts(newList);
        }, 1000);
    }
    return { 
        newContacts,
        newContactFormData, 
        showForm, 
        setShowForm, 
        updateNewContactEmail, 
        updateNewContactPhoneNumber,
        addNewContact,
        removeNewContact,
    };
}

export default useReferralsContactsDisplay;