import React, { useEffect } from "react";
import { Button, Form, Row, Col } from 'react-bootstrap';
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import useInsuranceProviderForm from "./useInsuranceProviderForm";

function NewInsuranceProviderBar({newInsurance, removeProvider}){
    if (!newInsurance ) {
        return (
            <div>
                Missing new insurance provider
            </div>
        )
    }
    const { provider } = newInsurance;
    return (
        <div className='medr-rounded-inv notif-setting-bar d-flex flex-align-items-center px-3' style={{justifyContent:'space-between', width:'unset'}}>
            <div>
               {provider}
            </div>
            <div >
                <Button variant='danger' onClick={removeProvider}>Remove</Button>
            </div>
        </div>
    );
}

NewInsuranceProviderBar.propTypes = {
    newInsurance: PropTypes.shape({
        provider: PropTypes.string.isRequired,
    }).isRequired,
    removeProvider: PropTypes.func.isRequired,
};

function InsuranceProviderForm({ updateNewProviders }){
    const { 
        newProviders, 
        addNewProvider, 
        removeNewProvider, 
        showForm,
        toggleShowForm,
        currentNewProvider,
        updateCurrentNewProvider
    } = useInsuranceProviderForm();

    const { provider } = currentNewProvider;

    useEffect(() => {
        updateNewProviders(newProviders);
    }, [newProviders]);

    return (
        <div className='medr-rounded bg-medr-gray mb-4 text-left'>
            <Row className='my-1'>
                <Col className='text-center'>
                    <h4 className='bluegreen'>Added Insurance Providers</h4>
                </Col>
            </Row>
            {newProviders && newProviders.map((newInsurance) => (
                <NewInsuranceProviderBar 
                        key={uuidv4()} 
                        newInsurance={newInsurance} 
                        removeProvider={() => removeNewProvider(newInsurance)}
                        />
            ))}
            <Row className='my-3'>
                <Col className='text-center'>
                    <Button className="sel-button w80" variant='primary' onClick={toggleShowForm}>{showForm ? "Hide form" : "Add new provider"}</Button>
                </Col>
            </Row>
            {showForm && (
                <Form>
                    <div className='d-flex' style={{alignItems:'end'}}>
                        <div className='col-md-8'>
                            <Form.Group controlId="providerName">
                                <Form.Label className='my-0 bluegreen'>Provider</Form.Label>
                                <Form.Control
                                    required
                                    type="name"
                                    placeholder="Enter insurance provider"
                                    value={provider}
                                    onChange={(e) => updateCurrentNewProvider({provider: e.target.value})}
                                />
                            </Form.Group>
                        </div>
                        <div className='col-md-4'>
                            <Button className='sel-button w80' onClick={addNewProvider}>Save new provider</Button>
                        </div>
                    </div>
                </Form>
            )}

        </div>
    )
};

InsuranceProviderForm.propTypes = {
    updateNewProviders: PropTypes.func.isRequired,
};

export default InsuranceProviderForm;