import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import UserProfileCard from '@components/user/UserProfileCard';
import { clinicanProfilePropType } from '@constants/propTypes';
import { getClinicianById } from '@actions/clinicianActions';

function ClinicianScreen({ clinician }) {

	const { id } = useParams();
	const dispatch = useDispatch();

	useEffect(() => {
		const ID = parseInt(id, 10);
		dispatch(getClinicianById(ID));
	}, [dispatch, id]);


	return (
		<div>
			<Row className="text-center py-1">
				<Col>
					<h1>Clinician Information</h1>
				</Col>
			</Row>
			<Row className="text-center py-1">
				<Col>
					 <UserProfileCard clinicianProfile={clinician}/>
				</Col>
			</Row>
		</div>
	);
}

ClinicianScreen.propTypes = {
	clinician: clinicanProfilePropType.isRequired,
};

const mapStateToProps = (state) => ({
	clinician: state.clinicians.clinician,
});

export default connect(mapStateToProps, {})(ClinicianScreen);
