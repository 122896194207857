import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import UserProfileCard from '../user/UserProfileCard';
import { clinicianProfilesPropType } from '@constants/propTypes';
import Loader from '../app/Loader';


function UserClinicianProfiles({ crps, isUser = false }) {
    if (!crps || crps === undefined || crps.length === 0) {
        return <Loader text="Loading clinician profiles..." />
    }

	return (
		<div>
            <Row className="text-center py-1" key={uuidv4()}>
                <Col>
                    <UserProfileCard    
                        clinicianProfiles={crps} 
                        isUser={isUser}
                        />
                </Col>
            </Row>
			
		</div>
	);
}

UserClinicianProfiles.propTypes = {
	crps: clinicianProfilesPropType.isRequired,
    isUser: PropTypes.bool,
};

export default UserClinicianProfiles;
