import { useEffect, useState } from 'react';
import { Icon } from 'leaflet';
import iconUrl from './marker-icon.png';

const useClinicMarker = (clinic, hoveredAddressIds, updateHovered) => {

    const geocode = [clinic.latitude, clinic.longitude];
    const puname = clinic.postcode;

    const customIconNormal = new Icon({
        iconUrl: iconUrl,
        iconSize: [25, 25],
    });

    const customIconHovered = new Icon({
        iconUrl: iconUrl,
        iconSize: [50, 50],
    });

    const [customIcon, setCustomIcon] = useState(customIconNormal);
    const [addressIsHovered, setAddressIsHovered] = useState(false);
    
    const checkIsHovered = () => {
        if (!hoveredAddressIds || hoveredAddressIds === undefined) {
            setAddressIsHovered(false);
            setCustomIcon(customIconNormal);
            return;
        }
        const hovered = hoveredAddressIds.some(id => id === clinic.id);
        setAddressIsHovered(hovered);
        setCustomIcon(hovered ? customIconHovered : customIconNormal);
    }

    useEffect(() => checkIsHovered(), [hoveredAddressIds]);

    const onMouseEnter = () => {
        updateHovered({
            hoveredClinicianUserProfileIds: clinic.userProfileIds, 
            hoveredAddressIds: [clinic.id]});
       }

    const onMouseLeave = () => {
        updateHovered({
            hoveredClinicianUserProfileIds: [], 
            hoveredAddressIds: []});
    }

    return {
        geocode,
        puname, 
        addressIsHovered,
        customIcon,
        onMouseEnter,
        onMouseLeave, 
    }
}

export default useClinicMarker;