import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from './reducers/index';

// const userInfoFromStorage = localStorage.getItem('userInfo')
// 	? JSON.parse(localStorage.getItem('userInfo'))
// 	: null;

// const initialState = {
// 	userLogin: { userInfo: userInfoFromStorage },
// };

const middleware = [thunk];

const store = createStore(
	reducer,
	composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
