import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import ReferralCard from '@components/referrals/ReferralCard';
import { getReferralById } from '@actions/referralActions';
import { referralPropType } from '@constants/propTypes';

function ReferralScreen({ referral }) {

	const { id } = useParams();
	const dispatch = useDispatch();

	useEffect(() => {
		const ID = parseInt(id, 10);
		dispatch(getReferralById(ID));
	}, [dispatch, id]);

	return (
		<div>
			<Row className="text-center py-1">
				<Col>
					<h1 className='text-center'>Referral Information</h1>
				</Col>
			</Row>
			<Row className="text-center py-1">	
				<Col className='col-4 medr-card-col'>
				{referral ? 
					<ReferralCard eleId="referralCardDisplay" referral={referral} />
				: <p>Referral has not been found</p>}
				</Col>
			</Row>
		</div>
	);
}

ReferralScreen.propTypes = {
	referral: referralPropType.isRequired,
};

const mapStateToProps = (state) => ({
	referral: state.referrals.referral,
});

export default connect(mapStateToProps, {})(ReferralScreen);
