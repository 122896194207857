import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getVerifyDelegateUser, verifyDelegateUser } from '@actions/authActions';


const useDelegateVerificationForm = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getSuccess = (value) => {
        if (!value) {
            navigate('/register-delegate/verify-email-error');
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        const { userHash, timeHash, verificationCode } = params;
        dispatch(getVerifyDelegateUser(userHash, timeHash, verificationCode, getSuccess));
    }, [])

    const initData = {
        title: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        rePassword: '',
        clinician: {
            'title': '',
            'firstName': '',
            'lastName': '',
        }
    }
    const delegateRegistration = useSelector(state => state.auth.delegateRegistration);
    const [userData, setUserData] = useState(initData);
    const [passwordsMatch, setPasswordsMatch] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);


    useEffect(() => {
        if (delegateRegistration && delegateRegistration.clinician && delegateRegistration.delegate) {
            setUserData(prevState => ({
                ...prevState,
                title: delegateRegistration.delegate.title,
                firstName: delegateRegistration.delegate.firstName,
                lastName: delegateRegistration.delegate.lastName, 
                email: delegateRegistration.delegate.user.email,
                clinician: delegateRegistration.clinician
            }))
        }
    }, [delegateRegistration])

    useEffect(() => {
        const match = userData.password === userData.rePassword;
        setPasswordsMatch(match);
        if (!match && termsAccepted) {
            setTermsAccepted(false);
        }
    }, [userData.password, userData.rePassword])

    const updateUserData = (data) => setUserData(prevState => ({...prevState, ...data}));

    const updateTermsAccepted = () => {
        const ready = passwordsMatch;
        if (!ready) {
            setTermsAccepted(false);
            return;
        }
        setTermsAccepted(prevState => !prevState);
    }
    
    const onSubmit = (e) => {
        e.preventDefault();
        dispatch(verifyDelegateUser(userData, setShowSuccess));
    }

    return {
        userData,
        updateUserData,
        termsAccepted,
        setTermsAccepted,
        showTerms,
        setShowTerms,
        passwordsMatch,
        updateTermsAccepted,
        onSubmit,
        showSuccess,
    }

}

export default useDelegateVerificationForm;