import { useSelector } from "react-redux";

const useJobRoles = () => {
    const jobRoles = useSelector((state) => state.clinicians.jobRoles);
    const getJobRoleById = (id) => {
        const jrs = jobRoles.filter((jobRole) => jobRole.id === id);
        if (jrs.length === 0) return null;
        return jrs[0];
    }

    const getJobRoleParams = (jobRole) => {
        const params = { 
            jobRoleId: jobRole.id,
            selectedJobRole: jobRole.jobRole,
            selectedJobSpecialty: '',
            selectedJobSubSpecialty: '',
        };
        if (jobRole.jobSpecialty==="nan") return params;
        params.selectedJobSpecialty = jobRole.jobSpecialty;

        if (jobRole.jobSubSpecialty==='nan') return params;
        params.selectedJobSubSpecialty = jobRole.jobSubSpecialty;
        return params;
    }


    return {
        jobRoles,
        getJobRoleById,
        getJobRoleParams
    };
};

export default useJobRoles;


