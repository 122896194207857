import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	referralPropType,
	clinicianProfilesPropType,
} from '@constants/propTypes';
import { getNameString, reformatDateTime } from '@actions/general';
import {
	postReferralToggleAccept,
	postReferralCancel,
	postReferralToggleComplete,
} from '@actions/referralActions';
import useUserPermissions from '@hooks/user/useUserPermissions';

function ReferralBar({ referral, crps }) {
	const {
		// permisisonCreateReferrals,
		permisisonManageReferrals,
		// permissionViewReferrals
	} = useUserPermissions();
	if (!referral) {
		return <div id="referral_missing">Loading...</div>;
	}

	const showView = {
		Sent: {
			seePxDetails: true,
			seeReferrerDetails: false,
			seeTargetClinicianDetails: true,
			seeCancelReferralButton: true,
			seeAcceptReferralButton: false,
			seeCompleteReferralButton: false,
			seeRejectReferralButton: false,
		},
		Pending: {
			seePxDetails: false,
			seeReferrerDetails: true,
			seeTargetClinicianDetails: false,
			seeCancelReferralButton: false,
			seeAcceptReferralButton: true,
			seeCompleteReferralButton: false,
			seeRejectReferralButton: true,
		},
		Accepted: {
			seePxDetails: true,
			seeReferrerDetails: true,
			seeTargetClinicianDetails: false,
			seeCancelReferralButton: false,
			seeAcceptReferralButton: false,
			seeCompleteReferralButton: true,
			seeRejectReferralButton: true,
		},
		Open: {
			seePxDetails: false,
			seeReferrerDetails: true,
			seeTargetClinicianDetails: false,
			seeCancelReferralButton: false,
			seeAcceptReferralButton: true,
			seeCompleteReferralButton: false,
			seeRejectReferralButton: false,
		},
	};

	const [isOpen, setIsOpen] = useState(false);
	const handleClickOpen = () => setIsOpen(!isOpen);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [viewType, setViewType] = useState(showView[referral.displayType]);
	const {
		seePxDetails,
		seeReferrerDetails,
		seeTargetClinicianDetails,
		seeAcceptReferralButton,
		seeCancelReferralButton,
		seeRejectReferralButton,
		seeCompleteReferralButton,
	} = viewType;

	useEffect(() => {
		setViewType(showView[referral.displayType]);
	}, [referral.displayType]);

	const {
		patient,
		mainDescription,
		referrer,
		history,
		specifiedClinician,
		targetClinician,
	} = referral;

	const pxName = (hidden) => (
		<div className="flex-fill referral-bar-item lines-max-2 referral-bar-item-1">
			{hidden ? (
				<p className="blur-text">Mr Example Person</p>
			) : (
				<p className="m-0">{getNameString(patient)}</p>
			)}
		</div>
	);

	const pxDob = (hidden) => (
		<>
			<div className="flex-fill text-left referral-bar-item lines-max-1 referral-bar-item-2">
				{hidden ? (
					<p className="m-0 blur-text">01/01/1990</p>
				) : (
					<p className="m-0">{reformatDateTime(patient.dob)}</p>
				)}
			</div>
			<div className="flex-fill text-left referral-bar-item lines-max-1 referral-bar-item-3">
				<p className="m-0">{patient.sex}</p>
			</div>
		</>
	);

	const pxContact = (
		<div className="flex-fill referral-bar-bottom">
			<p className="m-0 p-1 text-left">
				Insurance:{' '}
				{patient.insurancePolicy
					? `${patient.insurancePolicy.provider}`
					: 'Self-payer'}
			</p>
			<p className="m-0 p-1 text-left">Contact:</p>
			<p className="m-0 p-1 text-left">
				<a
					onClick={(event) => event.stopPropagation()}
					href={`mailto:"${patient.primaryEmail}`}
				>
					{patient.primaryEmail}
				</a>
			</p>
			<p className="m-0 p-1 text-left">
				<a
					onClick={(event) => event.stopPropagation()}
					href={`tel:"${patient.primaryPhoneNumber}`}
				>
					{patient.primaryPhoneNumber}
				</a>
			</p>
			{patient.address.firstline && patient.address.fristLine !== '' && (
				<p className="m-0 p-1 text-left">{patient.address.firstLine}</p>
			)}
			{patient.address.city && patient.address.city !== '' && (
				<p className="m-0 p-1 text-left">{patient.address.city}</p>
			)}
			<p className="m-0 p-1 text-left">{patient.address.postcode}</p>
			<p className="m-0 p-1 text-left">
				Created: {reformatDateTime(referral.createdOn)}
			</p>
			<p className="m-0 p-1 text-left">
				{referral.acceptedOn &&
					`Accepted: ${reformatDateTime(referral.acceptedOn)}`}
			</p>
			<p className="m-0 p-1 text-left">
				{referral.completedOn &&
					`Completed: ${reformatDateTime(referral.completedOn)}`}
			</p>
			{/* <p className='patient-referal-send-on'>Sent On: {reformatDateTime(referral.createdOn)}</p> */}
		</div>
	);

	const pxContactHidden = (
		<div className="flex-fill referral-bar-bottom">
			<p className="m-0 p-1 text-left">
				Insurance:{' '}
				{patient.insurancePolicy
					? `${patient.insurancePolicy.provider}`
					: 'Self-payer'}
			</p>
			<p className="m-0 p-1 text-left">Contact:</p>
			<p className="m-0 p-1 text-left blur-text">newemail@example.com</p>
			<p className="m-0 p-1 text-left blur-text">0123456789</p>
			<p className="m-0 p-1 text-left blur-text">10 Downing Street</p>
			<p className="m-0 p-1 text-left blur-text">London</p>
			<p className="m-0 p-1 text-left blur-text">SW1A 2AB</p>
			<p className="m-0 p-1 text-left">
				Created: {reformatDateTime(referral.createdOn)}
			</p>
		</div>
	);

	const referrerName = (
		<div className="flex-fill referral-bar-item lines-max-1">
			<p className="m-0">
				{isOpen === true ? (
					<a
						onClick={(event) => event.stopPropagation()}
						href={`/clinicians/${referrer.id}`}
					>
						{getNameString(referrer.userProfile)}
					</a>
				) : (
					getNameString(referrer.userProfile)
				)}
			</p>
		</div>
	);

	const getTargetClinician = () => {
		if (isOpen === true)
			return (
				<a
					onClick={(event) => event.stopPropagation()}
					href={`/clinicians/${referral.targetClinician.id}`}
				>
					{getNameString(referral.targetClinician.userProfile)}
				</a>
			);
		return getNameString(referral.targetClinician.userProfile);
	};

	const targetName = (
		<div className="flex-fill text-left  referral-bar-item lines-max-1 referral-bar-item-4">
			<p className="m-0">
				{specifiedClinician === true
					? getTargetClinician()
					: 'Open to all clinicians'}
			</p>
		</div>
	);

	const pxHistory = (
		<div className="flex-fill text-center referral-bar-bottom">
			<p className="m-0 p-1 text-left">History:</p>
			<p className="m-0  p-1 text-left">{history}</p>
		</div>
	);

	const pxReason = (
		<div className="flex-fill text-center referral-bar-bottom">
			<p className="m-0 p-1 text-left">Reason for referral:</p>
			<p className="m-0 p-1 text-left">{mainDescription}</p>
		</div>
	);

	// BUTTON FUNCTIONS

	function checkUrlPath() {
		const currentPathname = window.location.pathname;
		const pathParts = currentPathname.split('/');
		const lastPart = pathParts[pathParts.length - 1];
		return lastPart === 'referrals';
	}

	// const [acceptOpenRef, setAcceptOpenRef] = useState(false);

	const isAcceptSuccessfull = (value) => {};

	const acceptFromOpenRef = (crpId) => {
		dispatch(
			postReferralToggleAccept(
				referral.id,
				{ toAccept: true, crpId },
				isAcceptSuccessfull
			)
		);
		// setAcceptOpenRef(false);
	};

	function acceptButton() {
		const onClick = (event) => {
			event.stopPropagation();

			if (specifiedClinician) {
				dispatch(
					postReferralToggleAccept(
						referral.id,
						{ toAccept: true, crpId: targetClinician.id },
						isAcceptSuccessfull
					)
				);
			} else {
				acceptFromOpenRef(crps[0].id);
				// setAcceptOpenRef(true);
			}
		};

		return (
			<div className="flex-fill text-center referral-bar-item">
				<Button
					className="sel-button w80"
					variant="success"
					onClick={onClick}
					disabled={!permisisonManageReferrals}
				>
					Accept
				</Button>
			</div>
		);
	}

	function completeButton() {
		const onClick = (event) => {
			event.stopPropagation();
			const crpId = targetClinician.id;
			dispatch(
				postReferralToggleComplete(referral.id, { toComplete: true, crpId })
			);
		};

		return (
			<div className="flex-fill text-center referral-bar-item">
				<Button
					className="sel-button w80"
					variant="success"
					onClick={onClick}
					disabled={!permisisonManageReferrals}
				>
					Complete
				</Button>
			</div>
		);
	}
	function setActiveButton() {
		const onClick = (event) => {
			event.stopPropagation();
			const crpId = targetClinician.id;
			dispatch(
				postReferralToggleComplete(referral.id, { toComplete: false, crpId })
			);
		};

		return (
			<div className="flex-fill text-center referral-bar-item">
				<Button
					className="sel-button w80"
					variant="info"
					onClick={onClick}
					disabled={!permisisonManageReferrals}
				>
					Set active
				</Button>
			</div>
		);
	}

	function rejectButton() {
		const isAcceptToggleSuccessfull = (value) => {};

		// const onClick = () => dispatch(postReferralToggleAccept(referral.id, {toAccept: false, crpId: targetClinician.id}, isAcceptToggleSuccessfull));
		const onClick = (event) => {
			event.stopPropagation();
			dispatch(
				postReferralToggleAccept(
					referral.id,
					{ toAccept: false, crpId: targetClinician.id },
					isAcceptToggleSuccessfull
				)
			);
		};

		return (
			<div className="flex-fill text-center referral-bar-item">
				<Button
					className="sel-button w80"
					variant="danger"
					onClick={onClick}
					disabled={!permisisonManageReferrals}
				>
					Reject
				</Button>
			</div>
		);
	}

	function cancelButton() {
		const isCancelSuccessfull = (value) => {};

		// const onClick = () => dispatch(postReferralCancel(referral.id, isCancelSuccessfull));
		const onClick = (event) => {
			event.stopPropagation();
			dispatch(postReferralCancel(referral.id, isCancelSuccessfull));
		};

		return (
			<div className="flex-fill text-center referral-bar-item">
				<Button
					className="sel-button w80"
					variant="danger"
					onClick={onClick}
					disabled={!permisisonManageReferrals}
				>
					Cancel
				</Button>
			</div>
		);
	}

	const toggleDetailsButton = (
		<div className="flex-fill text-center referral-bar-item">
			<Button
				className="sel-button w80"
				variant="info"
				onClick={handleClickOpen}
			>
				{isOpen ? 'Hide' : 'Details'}
			</Button>
		</div>
	);

	const referralAccepted = referral.targetHasAccepted;
	const referralCompleted = referral.completed;

	return (
		<div
			className={`medr-rounded-inv obj-clickable ${isOpen ? 'bar-open' : ''}`}
			role="button"
			tabIndex={0}
			id={`refbar${referral.id}`}
		>
			<div className="d-flex align-items-center referral-bar">
				{pxName(!seePxDetails)}
				{pxDob(!seePxDetails)}
				{seeReferrerDetails && referrerName}
				{seeTargetClinicianDetails && targetName}
				<div className="d-flex align-items-center p-0 bar-buttons referral-bar-item-5">
					{toggleDetailsButton}
					{seeAcceptReferralButton ? acceptButton() : null}
					{/* {acceptOpenRef ? acceptOpenRefPopup : null} */}
					{seeCompleteReferralButton && referralAccepted && !referralCompleted
						? completeButton()
						: null}
					{seeCompleteReferralButton && referralAccepted && referralCompleted
						? setActiveButton()
						: null}
					{seeRejectReferralButton && !referralCompleted
						? rejectButton()
						: null}
					{seeCancelReferralButton && !referralAccepted ? cancelButton() : null}
				</div>
			</div>
			{isOpen ? (
				<div className="d-flex p-1 referral-info">
					{seePxDetails ? pxContact : pxContactHidden}
					{pxHistory}
					{pxReason}
				</div>
			) : null}
		</div>
	);
}

ReferralBar.propTypes = {
	referral: referralPropType.isRequired,
	crps: clinicianProfilesPropType.isRequired,
};

const mapStateToProps = (state) => ({
	crps: state.profile.clinicianProfiles,
});

export default connect(mapStateToProps, {})(ReferralBar);
