import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const useUserRegistration = () => {
	const initData = {
		isReview: false,

		// Step 1
		email: '',
		title: '',
		firstName: '',
		lastName: '',
		password: '',
		rePassword: '',

		// Step 2
		jobRoleId: 0,
		jobRoles: [],
		isDoctor: false,
		registrationNumber: '',
		registrationBody: '',
		dob: '',
		clinicianDescription: '',
		clinics: [],
		appointmentsContacts: [],

		//  Step 3
		existingProviders: [],
		newProviders: [],
	};

	// general data
	const [registrationData, setRegistrationData] = useState(initData);
	const resetRegistrationData = () => setRegistrationData(initData);
	const updateRegistrationData = (newData) =>
		setRegistrationData((prevState) => ({ ...prevState, ...newData }));

	const updateAppContactsOnMainEmailChange = () => {
		const appCons = registrationData.appointmentsContacts;
		if (appCons.some((item) => item.isMain)) {
			const newFiltered = appCons.map((item) =>
				item.isMain ? { ...item, email: registrationData.email } : item
			);
			updateRegistrationData({ appointmentsContacts: newFiltered });
			return;
		}
		const mainEmailContact = {
			type: 'email',
			email: registrationData.email,
			phoneNumber: '',
			isMain: true,
		};
		const newList = [mainEmailContact, ...appCons];
		updateRegistrationData({ appointmentsContacts: newList });
	};

	useEffect(() => {
		updateAppContactsOnMainEmailChange();
	}, [registrationData.email]);

	// step management
	const minStep = 0;
	const maxStep = 3;
	const [activeStep, setActiveStep] = useState(0);
	const setNextStep = () =>
		activeStep < maxStep
			? setActiveStep((previousStep) => previousStep + 1)
			: null;
	const setPreviousStep = () => {
		if (activeStep > minStep) {
			if (activeStep === maxStep) {
				updateRegistrationData({ isReview: false });
			}
			setActiveStep((previousStep) => previousStep - 1);
		}
	};

	// job roles
	const jobRoles = useSelector((state) => state.clinicians.jobRoles);
	const doctorIds = useSelector((state) => state.clinicians.doctorIds);

	const checkIsDoctor = (rolesList) => {
		const roleIds = rolesList.map((jr) => jr.id);
		updateRegistrationData({
			isDoctor: roleIds.some((id) => doctorIds.includes(id)),
		});
	};

	const addJobRole = (jr) => {
		if (!jr || jr === undefined || !jr.jobRoleId) return;
		const { jobRoleId } = jr;

		const newRoles = jobRoles.filter((jobr) => jobr.id === jobRoleId);
		if (newRoles.length < 1) return;

		const alreadyAdded = registrationData.jobRoles.some(
			(jobr) => jobr.id === jobRoleId
		);
		if (alreadyAdded) return;

		const newRolesList = [...registrationData.jobRoles, newRoles[0]];
		updateRegistrationData({
			jobRoles: newRolesList,
			registrationBody: newRoles[0].registrationBody,
		});
		checkIsDoctor(newRoles);
	};

	const removeJobRole = (e) => {
		const roles = registrationData.jobRoles.filter((item) => item.id !== e.id);
		updateRegistrationData({ jobRoles: roles });
	};

	// clinics
	const addClinic = (clinic) => {
		const newList = [...registrationData.clinics, clinic];
		const newListIndexed = newList.map((item, index) => ({
			...item,
			listInd: index,
		}));
		console.log(newListIndexed);
		updateRegistrationData({ clinics: newListIndexed });
	};

	const editClinic = (clinic) => {
		const newList = registrationData.clinics.map((item) => {
			if (item.listInd === clinic.listInd) {
				return clinic;
			}
			return item;
		});
		const newListIndexed = newList.map((item, index) => ({
			...item,
			listInd: index,
		}));
		updateRegistrationData({ clinics: newListIndexed });
	};

	const removeClinic = (e) => {
		const newList = registrationData.clinics.filter(
			(item) =>
				!(
					item.clinicName === e.clinicName &&
					item.centreName === e.centreName &&
					item.firstLine === e.firstLine &&
					item.city === e.city &&
					item.postcode === e.postcode &&
					item.addressType === e.addressType
				)
		);
		const newListIndexed = newList.map((item, index) => ({
			...item,
			listInd: index,
		}));
		updateRegistrationData({ clinics: newListIndexed });
	};

	// appointments contacts
	const updateAppointmentContacts = (contacts) => {
		if (!contacts || contacts === undefined || contacts.length < 1) {
			updateRegistrationData({
				appointmentsContacts: [
					{
						type: 'email',
						email: registrationData.email,
						phoneNumber: '',
						isMain: true,
					},
				],
			});
			return;
		}
		updateRegistrationData({ appointmentsContacts: contacts });
	};

	// insurance providers
	const updateExisitingProviders = (providers) => {
		if (!providers || providers === undefined) {
			updateRegistrationData({ existingProviders: [] });
			return;
		}
		updateRegistrationData({ existingProviders: providers });
	};

	const updateNewProviders = (newProviders) => {
		if (!newProviders || newProviders === undefined) {
			updateRegistrationData({ newProviders: [] });
			return;
		}
		updateRegistrationData({ newProviders });
	};

	useEffect(() => {
		if (activeStep === maxStep) {
			updateRegistrationData({ isReview: activeStep === maxStep });
		}
	}, [activeStep]);

	return {
		activeStep,
		setNextStep,
		setPreviousStep,
		registrationData,
		resetRegistrationData,
		updateRegistrationData,
		addJobRole,
		removeJobRole,
		addClinic,
		editClinic,
		removeClinic,
		updateAppointmentContacts,
		updateExisitingProviders,
		updateNewProviders,
	};
};

export default useUserRegistration;
