import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { registerDelegateUser, registerCheckEmail } from '@actions/authActions';

const useRegisterDelegate = (delegatingClinicianUserProfileId) => {
	const initData = {
		title: '',
		firstName: '',
		lastName: '',
		email: '',
		delegatingClinicianUserProfileId,
		permissionViewReferrals: true,
		permisisonCreateReferrals: true,
		permisisonManageReferrals: true,
	};
	const initFormChecks = {
		title: true,
		firstName: true,
		lastName: true,
		email: true,
	};

	const [registerDelegateData, setRegisterDelegateData] = useState(initData);
	const [formChecks, setFormChecks] = useState(initFormChecks);
	const [showSuccessfulRegistration, setShowSuccessfulRegistration] =
		useState(false);
	const [dupEmail, setDupEmail] = useState(false);

	const updateRegisterDelegateData = (data) =>
		setRegisterDelegateData((prevState) => ({ ...prevState, ...data }));
	const updateRegisterEmail = (e) => {
		updateRegisterDelegateData({ email: e.email });
	};

	const handleEmailCheck = () => {
		registerCheckEmail(registerDelegateData.email).then((emailValid) => {
			setDupEmail(!emailValid);
		});
	};

	useEffect(() => {
		handleEmailCheck();
	}, [registerDelegateData.email]);

	const checkForm = () => {
		const titleCheck = registerDelegateData.title.length > 0;
		const firstNameCheck = registerDelegateData.firstName.length > 0;
		const lastNameCheck = registerDelegateData.lastName.length > 0;
		const emailCheck = (registerDelegateData.email.length > 0) & !dupEmail;

		setFormChecks({
			title: titleCheck,
			firstName: firstNameCheck,
			lastName: lastNameCheck,
			email: emailCheck,
		});

		return firstNameCheck && lastNameCheck;
	};

	const dispatch = useDispatch();
	const registerDelegateSuccessful = (value) =>
		setShowSuccessfulRegistration(value);
	const onSumbit = (e) => {
		e.preventDefault();
		const ready = checkForm();
		if (!ready) return;
		dispatch(
			registerDelegateUser(registerDelegateData, registerDelegateSuccessful)
		);
	};

	return {
		registerDelegateData,
		updateRegisterDelegateData,
		updateRegisterEmail,
		formChecks,
		checkForm,
		onSumbit,
		showSuccessfulRegistration,
		dupEmail,
	};
};

export default useRegisterDelegate;
