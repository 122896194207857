import React from 'react';
import { Table, Button, Tabs, Tab } from 'react-bootstrap';
import { useAdminInsurersTable } from './useAdminInsurersTable';

function InsurersTable({ insurersList }){
    if (!insurersList || insurersList === undefined || insurersList.length === 0) {
        return (
            <div className='center-box-parent'>
                <div className='center-box-child'>
                    <h4 className='medr-text-inv'>No verified insurers have been found</h4>
                </div>
            </div>
        )
    }
    return (
        <Table striped bordered hover responsive className="table-sm">
            <thead>
                <tr>
                    <th>Row</th>
                    <th>ID</th>
                    <th>INSURER</th>
                </tr>
            </thead>
            <tbody>
                {insurersList.map((ins, index) => (
                    <tr key={ins.id}>
                        <td>{index+1}</td>
                        <td>{ins.id}</td>
                        <td>{ins.provider}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

function UnverifiedInsurersTable({ insurersList, setVerified }){
    if (!insurersList || insurersList === undefined || insurersList.length === 0) {
        return (
            <div className='center-box-parent'>
                <div className='center-box-child'>
                    <h4 className='medr-text-inv'>No verified insurers have been found</h4>
                </div>
            </div>
        )
    }
    return (
        <Table striped bordered hover responsive className="table-sm">
            <thead>
                <tr>
                    <th>Row</th>
                    <th>ID</th>
                    <th>INSURER</th>
                    <th>VERIFY</th>
                </tr>
            </thead>
            <tbody>
                {insurersList.map((ins, index) => (
                    <tr key={ins.id}>
                        <td>{index+1}</td>
                        <td>{ins.id}</td>
                        <td>{ins.provider}</td>
                        <td>
                            <Button id={`insBtn${ins.id}`} variant="info" className="btn-sm" onClick={() => setVerified(ins.id)}>
                                Set verified
                            </Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}


function AdminInsurersTable() {
    const { 
        insurers,
        unverifiedInsurers,
        setInsurerVerified,
    } = useAdminInsurersTable()
    return (
        	<Tabs
				defaultActiveKey="unverifiedInsurers"
				id="custom-tabs"
				className="custom-tabs mb-3"
				>
				<Tab eventKey="unverifiedInsurers" title="Unverified insurers" className="custom-tabs">
                    <UnverifiedInsurersTable insurersList={unverifiedInsurers} setVerified={setInsurerVerified} />
				</Tab>
				<Tab eventKey="insurers" title="Insurers" className="custom-tabs">
                    <InsurersTable insurersList={insurers} />
				</Tab>
			</Tabs>
    )

}


export default AdminInsurersTable;
