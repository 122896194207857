import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { getNameString, getClinicCenterStr } from '@actions/general';
import { clinicianProfilesPropType, boolPropType } from '@constants/propTypes';
import ProfilePictureDisplay from './ProfilePicture/ProfilePicture';
import FavouriteClinicianStar from '@components/app/parts/FavouriteStar/FavouriteClinicianStar';

function UserProfileCard({ clinicianProfiles, isUser = false }) {
    const navigate = useNavigate();

    if (clinicianProfiles===null || clinicianProfiles===undefined || clinicianProfiles.length===0){
        return (<p>Profile not found</p>);
    }
    const clinician = clinicianProfiles[0];    

    if (clinician===null || clinician===undefined || clinician.id===null){
        return (<p>Profile not found</p>);
    }
    
    const { userProfile, insuranceAgreements } = clinician;
    const { clinicianDescription, jobRole, user, appointmentsContact } = userProfile;
    const gotToDirectReferral = () => navigate(`/referral/direct-to-clinician/${clinician.id}`);

    
    function getInsurersList(){
        if (insuranceAgreements===null || insuranceAgreements===undefined || insuranceAgreements.length===0){
            return <p>No insurers listed</p>
        }
        return (
            <ul className='list-unstyled providers-list insurance-column-list'>
                {insuranceAgreements.map((agreement) => 
                    <li key={`ia${agreement.id}`}>{agreement.provider}</li>
                    )}
            </ul>
        )
    }
    
    const getAppointmentsContacts = (ac) => {
        if (ac.email === '' && ac.phoneNumber === '') return;
        return (
            <li className="d-flex mb-2" key={`appC${ac.id}`}>
                <div className="flex-shrink-0 d-flex align-items-center" style={{ width: '30px' }}>
                    <i className={`${ac.email!=="" ? "fa-regular fa-envelope" : "fa-solid fa-phone-volume"} text-muted mcc-icon`} />
                </div>
                <div className="d-flex align-items-center flex-grow-1">
                    <p className="mb-0 text-muted mcc-text">
                        {ac.email!=="" ? (
                            <a className='text-muted' href={`mailto:"${ac.email}`}>
                                {ac.email}
                            </a>
                        ) : (
                            <a className='text-muted' href={`tel:"${ac.phoneNumber}`}>
                                {ac.phoneNumber}
                            </a>
                            )}
                    </p>
                </div>
            </li>
        )
    }
    
    function getAppointmentsContactsLi(){
        if (appointmentsContact===null || appointmentsContact===undefined || appointmentsContact.length===0){
            return <p>No appointment contacts listed</p>
        }
        return <ul key='list-unstyled mb-1' style={{paddingLeft: '5px'}}>{appointmentsContact.map((ac) => getAppointmentsContacts(ac))}</ul>
    }

    const clinics = clinicianProfiles.map((crp) => getClinicCenterStr(crp.clinic));
    
    const clinicianRoles = jobRole.map((jr) => jr.jobTitle);
    const clinicianRolesString = clinicianRoles.join(', ');
    
	return (
            <Card 
                className="mb-2 rounded d-flex flex-fill" 
                style={{ padding: '0.5rem', maxWidth: '700px' }}
                >    
                <div className="user-card-name-pic-box">
                    <div className='user-card-pic-box'>
                        <ProfilePictureDisplay userProfile={userProfile} editable={isUser}/>
                    </div>
                    <div className="user-card-name-box text-left">
                        <Card.Title className="d-flex align-items-start ">
                            <div className="flex-shrink-0 d-flex align-items-center" style={{ width: '30px' }}>
                                {!isUser && 
                                    <FavouriteClinicianStar 
                                        clinician={clinician} 
                                        requestMade={() => {}}
                                        />
                                }
                            </div>
                            <span className="ms-2">{getNameString(userProfile)}</span>
                        </Card.Title>
                        <Card.Subtitle className="text-muted mb-2" style={{paddingLeft: '30px'}}>
                            {clinicianRolesString}
                        </Card.Subtitle>
                        <ul className="list-unstyled mb-1">
                            {clinics.map((name) => (
                                <li key={name} className="d-flex mb-2">
                                    <div className="flex-shrink-0 d-flex align-items-center" style={{ width: '30px' }}>
                                        <i className="fa-solid fa-house-medical text-muted mcc-icon" />
                                    </div>
                                    <div className="d-flex align-items-center flex-grow-1">
                                        <p className="mb-0 text-muted mcc-text">{name}</p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        {!isUser && (
                            <Button 
                                variant='primary' 
                                className="usr-rx-now-button mt-1 w100"
                                onClick={gotToDirectReferral}
                                >
                                Refer patient now
                            </Button>
                        )}
                    </div>
                </div>
                <hr />
                <div className="user-card-sec-row-box">
                    <div className="text-left user-card-contacts-box">
                        <Card.Title className='text-left'>Contact details</Card.Title>
                        {getAppointmentsContactsLi()}
                    </div>
                    <div className="user-card-description-box">
                        <Card.Title className='text-left'>Clinician Bio</Card.Title>
                        <p className='text-muted'>{clinicianDescription}</p>
                    </div>
                </div>
                <hr />
                <Card.Title>Insurers covered</Card.Title>
                <div className='text-muted text-left'>
                    {getInsurersList()}
                </div>
            </Card>
	);
}

UserProfileCard.propTypes = {
	clinicianProfiles: clinicianProfilesPropType.isRequired,
    isUser: boolPropType,
}


export default UserProfileCard;