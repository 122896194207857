import React, { useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import JobRoleSearchBar from '../../clinicians/JobRoleSearchBar';
import useFilteredClinicians from '@hooks/clinicians/useFilteredClinicians';
import useCliniciansList from '@hooks/clinicians/useCliniciansList';
import useJobRoles from '@hooks/clinicians/useJobRoles';
import ClinicianFilterInput from '../../clinicians/ClinicianFilterInput';
import MapView from '@features/map/MapView';

function ReferralStepSelectClinician({
	setTargetClinicianOpen,
	setTargetClinician,
	values,
	changeValue,
	changeGPS,
	refreshClinicians,
}) {
	useEffect(() => {
		window.scroll(0, 0);
	}, []);
	changeGPS();

	const { updateFilterFields } = useFilteredClinicians(values.jobRoleId);

	const {
		clinicianList,
		clinicAddresses,
		setFilterString,
		toggleShowOnlyFavourites,
		hoveredClinicianUserProfileIds,
		hoveredAddressIds,
		updateHovered,
		updateMapViewCoords,
		getClinicianById,
	} = useCliniciansList();

	const enableClinicianSelect = true;
	const { getJobRoleById } = useJobRoles();

	const onClinicianSelect = (id) => {
		if (!id || id === undefined || id === 0) return;
		const clinician = getClinicianById(id);
		if (!clinician || clinician === undefined) return;
		setTargetClinician(clinician);
	};

	const handleJobRoleSelect = (e) => {
		updateFilterFields(e);
		if (e.jobRoleId !== 0) {
			const jr = getJobRoleById(e.jobRoleId);
			changeValue({ ...e, jobRole: jr });
			return;
		}
		changeValue({ ...e });
	};

	const jrValues = {
		jobRoleId: values.jobRoleId,
		selectedJobRole: values.selectedJobRole,
		selectedJobSpecialty: values.selectedJobSpecialty,
		selectedJobSubSpecialty: values.selectedJobSubSpecialty,
	};

	return (
		<>
			<Row className="py-1">
				<Col className="text-center">
					<h4>Filter clinician by name or role</h4>
				</Col>
			</Row>
			<Row className="mb-4 text-center">
				<Col>
					<Button
						variant="info"
						onClick={setTargetClinicianOpen}
						disabled={!enableClinicianSelect}
					>
						Set open to all suitable clinicians
					</Button>
				</Col>
			</Row>
			<Row
				className="mb-4 referral-step-select-clinician"
				style={{ paddingLeft: '15px' }}
			>
				<Col sm={12} md={6}>
					<JobRoleSearchBar
						values={jrValues}
						changeValues={handleJobRoleSelect}
						filtered
					/>
				</Col>
				<Col sm={12} md={6}>
					<ClinicianFilterInput setFilterString={setFilterString} />
				</Col>
			</Row>
			<MapView
				clinics={clinicAddresses}
				clinicians={clinicianList}
				handleShowFavourites={toggleShowOnlyFavourites}
				hoveredClinicianUserProfileIds={hoveredClinicianUserProfileIds}
				hoveredAddressIds={hoveredAddressIds}
				updateHovered={updateHovered}
				onClinicianSelect={onClinicianSelect}
				refreshClinicians={refreshClinicians}
				updateMapViewCoords={updateMapViewCoords}
				showReferralButton={false}
			/>
		</>
	);
}

ReferralStepSelectClinician.propTypes = {
	setTargetClinicianOpen: PropTypes.func.isRequired,
	setTargetClinician: PropTypes.func.isRequired,
	values: PropTypes.shape({
		jobRoleId: PropTypes.number,
		currentPostcode: PropTypes.string,
		selectedJobRole: PropTypes.string,
		selectedJobSpecialty: PropTypes.string,
		selectedJobSubSpecialty: PropTypes.string,
		currentLatitude: PropTypes.number,
		currentLongitude: PropTypes.number,
	}).isRequired,
	changeValue: PropTypes.func.isRequired,
	changeGPS: PropTypes.func.isRequired,
	refreshClinicians: PropTypes.func.isRequired,
};

export default ReferralStepSelectClinician;
