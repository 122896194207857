import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getInsuranceAgreementsList } from '@actions/clinicianActions';


const useInsuranceSelectorGrid = (insuranceAgreements, currentAgreements, updateExisitingProviders) => {
    const [providers, setProviders] = useState([]);

    const dispatch = useDispatch();
    const refreshAgreements = () => dispatch(getInsuranceAgreementsList());
    useEffect(() => {
        refreshAgreements()
    }, []);

    const initToggledList = () => {
        if (!insuranceAgreements || insuranceAgreements.length === 0) return;
        
        const newList = insuranceAgreements.map((provider) => {
            if (currentAgreements.filter((agreement) => agreement.id === provider.id).length > 0) {
                return {...provider, selected: true};
            }
            return {...provider, selected: false};
        });
        setProviders(newList);
    }
    useEffect(() => initToggledList(), [insuranceAgreements]);

    const toggleProviderSelected = (id) => {
        if (!id) return;
        
        const updatedProviders = providers.map((provider) => {
            if (provider.id === id) {
                return {...provider, selected: !provider.selected}; // Toggle selected and return a new object
            }
            return provider; // Return unmodified provider for those not matching the id
        });
        setProviders(updatedProviders);
        updateExisitingProviders(updatedProviders.filter((provider) => provider.selected));
    }

    const selectAllProviders = () => {
        const updatedProviders = providers.map((provider) => ({...provider, selected: true}));
        setProviders(updatedProviders);
        updateExisitingProviders(updatedProviders);
    }

    return { 
        providers,
        selectAllProviders,
        toggleProviderSelected 
    };
}

export default useInsuranceSelectorGrid;