import React from 'react';
import AdminUsersTable from '@components/admin/AdminUsersTable';

function AdminUserListScreen() {
	return (
		<div>
			<h2>Users</h2>
			<AdminUsersTable />
		</div>
	)
};

export default AdminUserListScreen;
