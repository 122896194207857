import { useState, useEffect } from "react";

const useAdminPatientsList = (patientsList, start, end) => {
    const [patients, setPatients] = useState([]);
    const [startSlice, setStartSlice] = useState(start);
    const [endSlice, setEndSlice] = useState(end);
    const patientsLength = patientsList.length;

    const updateSlice = (startInd, endInd) => {
        setStartSlice(startInd);
        setEndSlice(endInd);
    };

    useEffect(() => {
        if (!patientsList || patientsList.length === 0) {
            setPatients([]);
        }
        const slice = patientsList.slice(startSlice, endSlice);
        setPatients(slice);
    }, [patients, startSlice, endSlice]);
    
    return [patients, updateSlice, patientsLength];
};

export default useAdminPatientsList;