import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { postUserProfilePicture } from '@actions/userActions';


// Helper function to convert base64 to blob
const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
        byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
};

export const checkProfileUrlString = (profilePicture) => {
    if (!profilePicture) return null;
    if (profilePicture.startsWith('http')) return profilePicture;
    return `http://localhost:8000${profilePicture}`;
}


const usePictureUploadModal = (userProfile) => {
    const dispatch = useDispatch();
    const imageSrc = checkProfileUrlString(userProfile.profilePicture);
    const [preview, setPreview] = useState(null);
    const bgColor = "#474649";

    const onBeforeFileLoad = (e) => {
        if (e.target.files[0].size > 716800) {
        //   alert("File is too big!");
          e.target.value = "";
        }
      };
    const onCrop = (view) => setPreview(view);
    const onClose = () => setPreview(null);
    
    const saveImage = () => {
        if (!preview) return;
        // Convert base64 to blob
        const blob = b64toBlob(preview.split(',')[1], 'image/png');

        // load formData
        const formData = new FormData();
        formData.append('image', blob, 'profile.png'); 
        
        // dispatch action
        dispatch(postUserProfilePicture(formData));
    }

    return {
        imageSrc,
        preview,
        bgColor,
        onBeforeFileLoad,
        onCrop,
        onClose,
        saveImage
    }
}

export default usePictureUploadModal;
