import React, { useState, useEffect} from 'react';
import { Table} from 'react-bootstrap';
import { connect } from 'react-redux';
import { clinicianProfilesPropType } from '@constants/propTypes';
import { getNameString } from '@actions/general';
import useAdminCliniciansList from '@hooks/admin/useAdminCliniciansList';
import PaginationBar from '../app/PaginationBar';


function AdminCrpStaffTable({ staffList = null }) {
	const nCliniciansWindow = 20
    const start = 0;
    const end = nCliniciansWindow;
    const [clinList, setClinLsit] = useState([])

	useEffect(() => {
        if (staffList === null || staffList === undefined || staffList.length === 0) {
            setClinLsit([]);
            return;
        }
        setClinLsit(staffList);
    }, [staffList])

    const [
        staff,
        updateStaffSlice,
        staffLength
    ] = useAdminCliniciansList(clinList, start, end);

	const headersRow = (
		<tr>
			<th>Name</th>
			<th>Site Admin</th>
		</tr>
	)

	const staffRows = staff.map((user) => (
		<tr key={user.id}>
			<td>{getNameString(user.userProfile)}</td>
			<td>{user.userProfile.user.is_staff ? "Yes" : "No"}</td>
		</tr>
	))

    const noStaffDisplay = (
        <div className='center-box-parent'>
            <div className='center-box-child'>
                <h4 className='medr-text-inv'>No staff to display at the moment</h4>
            </div>
        </div>
    )

    return (
		<>
			{staffLength===0 && noStaffDisplay}
			{staffLength>0 && (
				<Table striped bordered hover responsive className="table-sm">
					<thead>
						{headersRow}
					</thead>
					<tbody>
						{staffRows}
					</tbody>
				</Table>
			)}
			{staffLength > nCliniciansWindow && (
				<PaginationBar arrayLength={staffLength} setSliceValues={updateStaffSlice} windowSize={nCliniciansWindow}/>
			)}
			
		</>
    )

}

AdminCrpStaffTable.propTypes = {
	staffList: clinicianProfilesPropType,
};


const mapStateToProps = (state) => ({
	staffList: state.admin.clinicians.staff,
});

export default connect(mapStateToProps, {})(AdminCrpStaffTable);
