import * as CONSTS from '@constants/searchConstants';
import * as URL from '@constants/urls';
import { 
	anyNullRunThrough, 
	anyGetRequest, 
	authGetRequest,
	anyPostRequest 
} from './axiosActions';

export const setSearchPatientById = (id)  => async (dispatch) => {
	
	const inputData = {
		URL: `${URL.PATIENT_GET_CURRENT}${id}/`,
		GET_REQUEST: CONSTS.SET_PATIENT_REQUEST,
		GET_SUCCESS: CONSTS.SET_PATIENT_SUCCESS,
		GET_FAIL: CONSTS.SET_PATIENT_FAIL,
	};
	
	dispatch(authGetRequest(inputData));
};

export const setSearchPatientNull = ()  => async (dispatch) => {
	const inputData = {
		REQUEST: CONSTS.SET_PATIENT_NULL_REQUEST,
		SUCCESS: CONSTS.SET_PATIENT_NULL_SUCCESS,
		FAIL: CONSTS.SET_PATIENT_NULL_FAIL
	};
	
	dispatch(anyNullRunThrough(inputData));
};

export const setSearchCrpById = (id)  => async (dispatch) => {
	
	const inputData = {
		URL: `${URL.CLINICIAN}${id}/`,
		GET_REQUEST: CONSTS.SET_CRP_REQUEST,
		GET_SUCCESS: CONSTS.SET_CRP_SUCCESS,
		GET_FAIL: CONSTS.SET_CRP_FAIL,
	};
	
	dispatch(authGetRequest(inputData));
	
	// dispatch({
	// 	type: CONSTS.SET_CRP_REQUEST,
	// });
	
	// try {

	// 	const { data } = await axios({
	// 		method: 'get',
	// 		url: `${URL.CLINICIAN}${id}/`,
	// 		xsrfCookieName: 'csrftoken',
	// 		xsrfHeaderName: 'X-CSRFTOKEN',
	// 		withCredentials: true,
	// 	});

	// 	dispatch({
	// 		type: CONSTS.SET_CRP_SUCCESS,
	// 		payload: data
	// 	});
	// } catch (error) {
	// 	dispatch({
	// 		type: CONSTS.SET_CRP_FAIL,
	// 	});
	// }
};

export const setSearchCrpNull = ()  => async (dispatch) => {
	const inputData = {
		REQUEST: CONSTS.SET_CRP_NULL_REQUEST,
		SUCCESS: CONSTS.SET_CRP_NULL_SUCCESS,
		FAIL: CONSTS.SET_CRP_NULL_FAIL
	};
	
	dispatch(anyNullRunThrough(inputData));
};

export const setJobRoleById = (id)  => async (dispatch) => {
	const inputData = {
		URL: `${URL.JOB_ROLES}${id}/`,
		GET_REQUEST: CONSTS.SET_JOB_ROLE_REQUEST,
		GET_SUCCESS: CONSTS.SET_JOB_ROLE_SUCCESS,
		GET_FAIL: CONSTS.SET_JOB_ROLE_FAIL,
	};

	dispatch(anyGetRequest(inputData));
};

export const setJobRoleNull = ()  => async (dispatch) => {
	const inputData = {
		REQUEST: CONSTS.SET_JOB_ROLE_NULL_REQUEST,
		SUCCESS: CONSTS.SET_JOB_ROLE_NULL_SUCCESS,
		FAIL: CONSTS.SET_JOB_ROLE_NULL_FAIL
	};
	
	dispatch(anyNullRunThrough(inputData));
};

export const setSearchPostcode = (postCode)  => async (dispatch) => {
	const pl = { postCode };
	const inputData = {
		URL: `${URL.GET_GPS}`,
		GET_REQUEST: CONSTS.SET_POSTCODE_REQUEST,
		GET_SUCCESS: CONSTS.SET_POSTCODE_SUCCESS,
		GET_FAIL: CONSTS.SET_POSTCODE_FAIL,
		payload: pl
	};

	dispatch(anyPostRequest(inputData));
};

export const setSearchGps = (postCode, lat, lng)  => async (dispatch) => {
	
	dispatch({
		type: CONSTS.SET_GPS_REQUEST,
		payload: { postCode, lat, lng }
	});
};


export const setClinicianById = (id)  => async (dispatch) => {
	
	const inputData = {
		URL: `${URL.CLINICIAN}${id}/`,
		GET_REQUEST: CONSTS.SET_CLINICIAN_REQUEST,
		GET_SUCCESS: CONSTS.SET_CLINICIAN_SUCCESS,
		GET_FAIL: CONSTS.SET_CLINICIAN_FAIL,
	};
	
	dispatch(authGetRequest(inputData));
};

export const setClinicianNull = ()  => async (dispatch) => {
	const inputData = {
		REQUEST: CONSTS.SET_CLINICIAN_NULL_REQUEST,
		SUCCESS: CONSTS.SET_CLINICIAN_NULL_SUCCESS,
		FAIL: CONSTS.SET_CLINICIAN_NULL_FAIL
	};
	
	dispatch(anyNullRunThrough(inputData));
};