import React from "react";
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import Select from 'react-select';
import { funcPropType, strPropType, insuranceAgreementsPropType, numberPropType } from '@constants/propTypes';
import useInsuranceSearchBar from "./useInsuranceSearchBar";

function InsuranceAgreementSearchBar({ agreement = -1, changeValues, insuranceAgreements = [], valueKey = 'insuranceProviderId' }){
    const {
        insuranceOptions,
        customStyles,
        currentAgreement
    } = useInsuranceSearchBar(agreement, insuranceAgreements);
    
    return (
        <Row className="mb-2 custom-dropdown">
            <Col>
                <Select
                    options={insuranceOptions}
                    onChange={opt => changeValues({[valueKey]: opt.value})}
                    placeholder={currentAgreement}
                    styles={customStyles}
                    className="custom-dropdown-select"
                />
            </Col>
        </Row>
    )
}

InsuranceAgreementSearchBar.propTypes = {
    agreement: numberPropType,
    changeValues: funcPropType.isRequired,
    insuranceAgreements: insuranceAgreementsPropType,
    valueKey: strPropType
};

const mapStateToProps = state => ({
    insuranceAgreements: state.clinicians.insuranceAgreements
})

export default connect(mapStateToProps, {})(InsuranceAgreementSearchBar);