import { useState } from 'react';


const useProfilePicture = (userProfile, editable) => {
    const [showPicUpload, setShowPicUpload] = useState(false);

    let profilePicturePath = null;
    if (userProfile && userProfile.profilePicture) {
        profilePicturePath = userProfile.profilePicture;
    }
    const uploadPicture = () => {
        if (editable) setShowPicUpload(true);
    }
    const uploadPictureKeyPress = (event) => {
        if (event.key === 'Enter' || event.key === ' ') {
            uploadPicture();
        }
    }

    return { 
        profilePicturePath,
        uploadPicture,
        uploadPictureKeyPress,
        showPicUpload,
        setShowPicUpload
    };
}

export default useProfilePicture;