import { useDispatch } from 'react-redux';
import { updateClinicianInsuranceAgreements } from '@actions/clinicianActions';
// import { loadUser } from '@actions/profileActions';


const useProfileChangeInsurance = (clinicalProfiles) => {
    const dispatch = useDispatch();

    const existingProviders = clinicalProfiles[0].insuranceAgreements;
    const updateExistingProviders = (providers) => {
        if (providers === existingProviders) return;
        dispatch(updateClinicianInsuranceAgreements(providers));
    }
    
    const updateNewProviders = (providers) => {
        if (!providers || !providers.length === 0) return;
        console.log("send request to update", providers) // eslint-disable-line no-console
    }
    return {
        existingProviders,
        updateExistingProviders,
        updateNewProviders
    }
}

export default useProfileChangeInsurance;

// const onSubmit = () => {
//     const formData = {newAgreements, existingAgreements};
//     dispatch(updateClinicianInsuranceAgreements(formData, isSuccess));
// };

// const isSuccess = (value) => {
//     setDataHasChanged(!value);
//     if (value===true){
//         dispatch(loadUser());
//     }
// }