import React from 'react';
import useUserJobRole from '@hooks/user/useUserJobRole';
import DashboardClinician from './user/DashboardClinician';
import DashboardStaff from './user/DashboardStaff';
import DashboardDelegate from './user/DashboardDelegate';

function Dashboard() {

    const { userType } = useUserJobRole();
    
	return (
        <div className='medr-layout text-center dashboard-page' style={{maxWidth:'900px', marginLeft: 'auto', marginRight: 'auto'}}>
            {userType==="Clinician" && <DashboardClinician />}
            {userType==="Staff" && <DashboardStaff />}
            {userType==="Delegate" && <DashboardDelegate />}
		</div>
	);
}


export default Dashboard;
