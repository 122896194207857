import React from 'react';
import { connect } from 'react-redux';
import InsuranceSelectorGrid from '../InsuranceSelectorGrid/InsuranceSelectorGrid';
// import InsuranceProviderForm from '../../clinicians/InsuranceProviderDisplay/InsuranceProviderForm';
import { clinicianProfilesPropType, insuranceAgreementsPropType } from '@constants/propTypes';
import useProfileChangeInsurance from './useProfileChangeInsurance';

function ProfileChangeInsurance({ clinicalProfiles, insuranceAgreements = [] }) {
    const {
        existingProviders,
        updateExistingProviders,
        // updateNewProviders
    } = useProfileChangeInsurance(clinicalProfiles);

	return (
        <>
            <InsuranceSelectorGrid 
                insuranceAgreements={insuranceAgreements} 
                currentProviders={existingProviders} 
                updateCurrentProviders={updateExistingProviders}
                />
            {/* <InsuranceProviderForm 
                updateNewProviders={updateNewProviders}
                /> */}
        </>
	);
}

ProfileChangeInsurance.propTypes = {
    clinicalProfiles: clinicianProfilesPropType.isRequired,
    insuranceAgreements: insuranceAgreementsPropType,
};


const mapStateToProps = (state) => ({
	clinicalProfiles: state.profile.clinicianProfiles,
    agreements: state.clinicians.insuranceAgreements
});

export default connect(mapStateToProps, {})(ProfileChangeInsurance);