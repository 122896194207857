import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSearchGps } from '@actions/searchActions';

const useGeoLocationToSearch = () => {
    const dispatch = useDispatch();
    const [userLocation, setUserLocation] = useState({
        loaded: false,
        coordinates: { lat: "", lng: "" },
        mapCenter: [0, 0],
    });

    const onSuccess = (currentLocation) => {
        setUserLocation({
            loaded: true,
            coordinates: {
                lat: currentLocation.coords.latitude,
                lng: currentLocation.coords.longitude,
            },
            mapCenter: [currentLocation.coords.latitude, currentLocation.coords.longitude],
        });
    };

    const onError = (error) => {
        setUserLocation({
            loaded: true,
            error,
        });
    }

    useEffect(() => {
        if (!("geolocation" in navigator)) {
            onError({
                code: 0,
                message: "Geoloation not supported"
            },)
        }

        navigator.geolocation.getCurrentPosition(onSuccess, onError);
    }, []);

    useEffect(() => {
        if (!userLocation.loaded || userLocation.error) return;
        dispatch(setSearchGps("", userLocation.mapCenter[0], userLocation.mapCenter[1]));
    }, [userLocation]);
}

export default useGeoLocationToSearch;