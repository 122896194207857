import React from 'react';
// import axios from 'axios';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import './App.css';

import LandingPageScreen from '@screens/LandingPageScreen';
import PoliciesScreen from '@screens/app/PoliciesScreen';
import LoginScreen from '@screens/user/LoginScreen';
import SignupScreen from '@screens/user/SignupScreen';
import SendResetPassswordScreen from '@screens/user/SendResetPassword';
import SendResetPassswordScreenSuccess from '@screens/user/SendResetPasswordSuccess';
import PasswordResetFromEmailScreen from '@screens/user/PasswordResetFromEmailScreen';
import RegistrationProgressScreen from '@screens/user/RegistrationProgressScreen';
import DelegateVerification from '@screens/user/DelegateVerification';
import HelpScreen from '@screens/app/HelpScreen';
import Dashboard from '@screens/Dashboard';
import FAQScreen from '@screens/app/FAQScreen';
import DelegateVerificationError from '@screens/user/Delegate/DelegateVerificationError';

import AuthenticatedRoutes from '@components/app/AuthenticatedRoutes';
import AdminRoutes from '@components/app/AdminRoutes';

import ProfileScreen from '@screens/ProfileScreen';
import DelegateManagement from '@screens/user/DelegateManagement';
import ReferralScreen from '@screens/referrals/ReferralScreen';

import PatientEntryScreen from '@screens/patients/PatientEntryScreen';
import PatientListScreen from '@screens/patients/PatientListScreen';
import PatientScreen from '@screens/patients/PatientScreen';
import PatientEditScreen from '@screens/patients/PatientEditScreen';

import ReferralEntryScreen from '@screens/referrals/ReferralEntryScreen';
import DirectReferralEntry from '@screens/referrals/DirectReferralEntryScreen';
import ReferralsListScreen from '@screens/referrals/ReferralListScreen';

// import CliniciansListScreen from '@screens/CliniciansListScreen';
import CliniciansMapView from '@screens/clinicians/CliniciansMapView';
import ClinicianScreen from '@screens/clinicians/ClinicianScreen';
import UserCrpsScreen from '@screens/clinicians/UserCrpsScreen';

import AdminUserListScreen from '@screens/admin/AdminUserListScreen';
import AdminClinicianListScreen from '@screens/admin/AdminCliniciansListScreen';
import AdminPatientListScreen from '@screens/admin/AdminPatientListScreen';
import AdminReferralsListScreen from '@screens/admin/AdminReferralsListScreen';
import AdminInsurersScreen from '@screens/admin/AdminInsurersListScreen';

import NotFound from '@screens/NotFound';

import store from './store';

import Layout from './hocs/Layout';

import ErrorFallback from '@components/app/ErrorFallback';

function RoutesContent() {
	const openRoutes = (
		<>
			<Route exact path="/" element={<LandingPageScreen />} />
			<Route exact path="/policy" element={<PoliciesScreen />} />
			<Route exact path="/faqs" element={<FAQScreen />} />
			<Route exact path="/login" element={<LoginScreen />} />
			<Route exact path="/register" element={<SignupScreen />} />
			<Route
				exact
				path="/register/verify-email/:userHash/:timeHash/:verificationCode"
				element={<RegistrationProgressScreen step={1} />}
			/>
			<Route
				exact
				path="/register-delegate/verify-email/:userHash/:timeHash/:verificationCode"
				element={<DelegateVerification />}
			/>
			<Route
				exact
				path="/register-delegate/verify-email-error"
				element={<DelegateVerificationError />}
			/>
			<Route
				exact
				path="/register/pending-account-approval"
				element={<RegistrationProgressScreen step={2} />}
			/>
			<Route
				exact
				path="/help"
				element={<HelpScreen authenticated={false} />}
			/>

			<Route
				exact
				path="/user/send-reset-email"
				element={<SendResetPassswordScreen />}
			/>
			<Route
				exact
				path="/user/password-reset-success"
				element={<SendResetPassswordScreenSuccess />}
			/>
			<Route
				exact
				path="/user/set-new-password/:userHash/:timeHash/:verificationCode"
				element={<PasswordResetFromEmailScreen />}
			/>
			<Route
				exact
				path="/registered/check-email"
				element={<RegistrationProgressScreen step={0} />}
			/>
			<Route
				exact
				path="/registered/verification-pending"
				element={<RegistrationProgressScreen step={1} />}
			/>
			<Route
				exact
				path="/registered/complete"
				element={<RegistrationProgressScreen step={2} />}
			/>
		</>
	);

	const userRoutes = (
		<>
			<Route exact path="/dashboard" element={<Dashboard />} />
			<Route exact path="/auth/help" element={<HelpScreen authenticated />} />

			<Route exact path="/clinicians" element={<CliniciansMapView />} />

			{/* temp user routes for MVP */}

			<Route exact path="/clinicians/:id" element={<ClinicianScreen />} />
			<Route exact path="/clinicians/user/:id" element={<UserCrpsScreen />} />

			<Route exact path="/profile" element={<ProfileScreen />} />
			<Route exact path="/patients/add" element={<PatientEntryScreen />} />
			<Route exact path="/patients/:id" element={<PatientScreen />} />
			<Route exact path="/patients/:id/edit" element={<PatientEditScreen />} />
			<Route exact path="/patients" element={<PatientListScreen />} />
			<Route exact path="/referral/add" element={<ReferralEntryScreen />} />
			<Route
				exact
				path="/referral/direct-to-clinician/:clinicianId"
				element={<DirectReferralEntry />}
			/>
			<Route
				exact
				path="/referral/add/patientId/:patientId"
				element={<ReferralEntryScreen />}
			/>
			<Route exact path="/referrals" element={<ReferralsListScreen />} />

			<Route exact path="/referrals/:id" element={<ReferralScreen />} />
			{/* <Route
				exact
				path="/clinicians2"
				element={<CliniciansListScreen />}
			/> */}
			<Route exact path="/delegates" element={<DelegateManagement />} />
		</>
	);

	const adminRoutes = (
		<>
			<Route exact path="/staff/users" element={<AdminUserListScreen />} />
			<Route
				exact
				path="/staff/clinician-roles"
				element={<AdminClinicianListScreen />}
			/>
			<Route
				exact
				path="/staff/patients"
				element={<AdminPatientListScreen />}
			/>
			<Route
				exact
				path="/staff/referrals"
				element={<AdminReferralsListScreen />}
			/>
			<Route exact path="/staff/insurers" element={<AdminInsurersScreen />} />
		</>
	);

	return (
		<Routes>
			{openRoutes}

			<Route element={<AuthenticatedRoutes />}>{userRoutes}</Route>

			<Route element={<AdminRoutes />}>{adminRoutes}</Route>

			<Route path={'/*'} element={<NotFound />} />
		</Routes>
	);
}

function App() {
	
	const isLocalhost = window.location.origin === "http://localhost:3000";
	return (
		<Provider store={store}>
				<Router>
					<Layout>
						{isLocalhost ? (
							<RoutesContent />
						) : (
							<ErrorBoundary
							FallbackComponent={ErrorFallback}
							onReset={() => {
								console.log('reset after error');
							}}
							>
							<RoutesContent />
							</ErrorBoundary>
						)}
					</Layout>
				</Router>
		</Provider>
	);
}

export default App;
