import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	resetCurrentPatient,
	getCurrentPatient,
} from '@actions/patientActions';

const useUserPatients = () => {
	const dispatch = useDispatch();

	const activePatients = useSelector((state) => state.patients.activePatients);
	const pastPatients = useSelector((state) => state.patients.pastPatients);
	const acceptedPatients = useSelector(
		(state) => state.patients.acceptedPatients
	);
	const [patients, setPatients] = useState([]); // [activePatients, pastPatients, acceptedPatients]

	useEffect(() => {
		if (!activePatients && !pastPatients && !acceptedPatients) return;
		setPatients([...activePatients, ...pastPatients, ...acceptedPatients]);
	}, [activePatients, pastPatients, acceptedPatients]);

	const getPatientById = (id) => {
		if (
			!patients ||
			patients === undefined ||
			patients.length === 0 ||
			!id ||
			id === undefined ||
			id < 1
		)
			return null;
		const patient = patients.find((px) => px.id === id);
		return patient;
	};
	const resetCurrentPx = async () => {
		await dispatch(resetCurrentPatient());
	};
	const setCurrentPatientById = (id) => {
		if (!id || id === undefined || id < 1) return;
		dispatch(getCurrentPatient(id));
	};

	return {
		patients,
		getPatientById,
		resetCurrentPx,
		setCurrentPatientById,
	};
};

export default useUserPatients;
