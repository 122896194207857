import React, { useEffect, useState } from "react";
import { Row,  Col, Button, Form  } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import PropTypes from 'prop-types';
import ClinicsDisplayBox from "../clinicians/ClinicsDisplayBox";
// import AppointmentsContactBox from "../clinicians/AppointmentsContactBox";
import  { register } from '@actions/authActions';
import { jobRolesPropType, appointmentsContactListPropType, clinicsPropType, insuranceAgreementsPropType } from '@constants/propTypes';
import { reformatDate } from '@actions/general';
import TermsAndConditionsPopup from "../app/alerts/TermsAndConditionsPopup";
import BetaVersionFreeAccountAlert from "../app/alerts/BetaVersionFreeAccountAlert";
import { ReferralContact } from './ReferralContacts/ReferralContactsDisplay';


function RegisterReview({ values }){
    
    useEffect(() => {window.scroll(0,0)}, []);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const updateTermsAccepted = () => setTermsAccepted(prevState => !prevState);

    if (values===null || values===undefined){
        return (
            <div className='medr-rounded'>
                <Row>
                    <Col>
                        <h4>Error missing data</h4>
                    </Col>
                </Row>
            </div>
        );
    }
    const { 
        email, 
        password,
        rePassword,
        title,
        firstName, 
        lastName,  
        // jobRoleId, 
        jobRoles, 
        registrationNumber, 
        dob, 
        clinics,
        newProviders, 
        existingProviders,
        clinicianDescription,
        appointmentsContacts,
    } = values;

    const jobRoleIds = jobRoles.map(item => item.id);

    const jobRoleDisplay = () => {
        if (jobRoles===null || jobRoles===undefined) return null;
        return jobRoles.map((role) => <div key={role.id}>{role.jobTitle}</div>);
    }

    const appContactList = appointmentsContacts ? appointmentsContacts.map((contact) => <ReferralContact contact={contact} key={uuidv4()}/>) : null;
    const appContactsBox = (
        <>
            <h5 className='bluegreen'>Appointments contact information</h5>
            <div className='medr-rounded-inv'>
                    {appContactList}
            </div>
        </>
    )

    const name = `${title} ${firstName} ${lastName}`;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const registrationSuccessful = (value) => {
        if (value===true){
            navigate("/registered/check-email");
        }
    }

    const onSubmit = () => {
        const requestData = {
            email,
            password,
            rePassword,
            title,
            firstName,
            lastName,
            dob,
            registrationNumber,
            jobRoleIds,
            clinics,
            existingProviders,
            newProviders,
            clinicianDescription,
            appointmentsContacts,
        }
        dispatch(register(requestData, registrationSuccessful));
    }

    return (
        <>
            <TermsAndConditionsPopup showModal={showTerms} handleClose={() => setShowTerms(false)}/>
            <BetaVersionFreeAccountAlert />
            <Row>
                <Col>
                    <h4>Registration summary</h4>
                </Col>
            </Row>
            <div className='medr-rounded mb-4'>
                <Row className="m-2">
                    <Col>
                        <h5 className='bluegreen'>Clinician details</h5>
                    </Col>
                </Row>
                <Row className='m-2'>
                    <Col className='width-m45'>
                        Name:
                    </Col>
                    <Col>
                        {name}
                    </Col>
                </Row>
                <Row className='m-2'>
                    <Col className='width-m45'>
                        Clinician Role:
                    </Col>
                    <Col>
                        {jobRoleDisplay()}
                    </Col>
                </Row>
                <Row className='m-2'>
                    <Col className='width-m45'>
                        Registration Number:
                    </Col>
                    <Col>
                        {registrationNumber}
                    </Col>
                </Row>
                <Row className='m-2'>
                    <Col className='width-m45'>
                        DOB:
                    </Col>
                    <Col>
                        {reformatDate(dob)}
                    </Col>
                </Row>
                <Row className='m-2'>
                    <Col className='width-m45'>
                        Email:
                    </Col>
                    <Col>
                        {email}
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col>
                        <p className='text-center'>Return to previous screen to see insurance providers selected</p>
                    </Col>
                </Row>
                {appointmentsContacts.length>0 ? appContactsBox : null}
            </div>

            <div className='mt-4 mb-4'>
                <ClinicsDisplayBox clinics={clinics} />
            </div>
            <Row className='text-left mt-4'>
                <Col className='my-0'>
                    <Form.Group controlId="consent" className="mb-3">
                        <Form.Label className='my-0 custom-checkbox w100 text-left'>
                            <Form.Check className='custom-hidden-checkbox'>
                                <input type='checkbox' data-testid='consent-checkbox' id='consent' checked={termsAccepted} onChange={updateTermsAccepted}/>
                                <div className='d-flex align-items-center'>
                                    <i className="fa-regular fa-square-check big-checkbox custom-unchecked" />
                                    <i className="fa-solid fa-square-check big-checkbox custom-checked" />
                                    I agree to the terms and conditions (
                                        <button
                                            type="button"
                                            onClick={() => setShowTerms(true)}
                                            className="btn btn-link p-0 m-0 align-baseline tnc-link"
                                        >
                                            see terms and conditions
                                        </button>
                                    )
                                </div>
                            </Form.Check>
                        </Form.Label>
                    </Form.Group>
                </Col>
			</Row>
            
            <Row>
                <Col className='text-center'>
                    <Button className='sel-button w80 my-4' variant='success' type='submit' disabled={!termsAccepted} onClick={onSubmit}>Complete registration</Button>
                </Col>
            </Row>
        </>
    )
};


RegisterReview.propTypes = {
    values: PropTypes.shape({
        email: PropTypes.string.isRequired,
        password: PropTypes.string.isRequired,
        rePassword: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        phone: PropTypes.string.isRequired,
        jobRoleId: PropTypes.number.isRequired,
        jobRoles: jobRolesPropType.isRequired,
        registrationNumber: PropTypes.string.isRequired,
        dob: PropTypes.string.isRequired,
        appointmentsContacts: appointmentsContactListPropType.isRequired,
        clinics: clinicsPropType.isRequired,
        newProviders: insuranceAgreementsPropType.isRequired,
        existingProviders: insuranceAgreementsPropType.isRequired,
        clinicianDescription: PropTypes.string.isRequired,
     }).isRequired,
};

export default RegisterReview;