import * as CONSTS from '@constants/messageConstants';
import { LOGOUT_SUCCESS } from '@constants/authConstants';

const initialState = {
	variant: "",
	message: "",
};

// eslint-disable-next-line default-param-last
export default function message(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CONSTS.MESSAGE_RECEIVED:
			return {
				...state,
				variant: payload.variant,
				message: payload.message,
			};
		case LOGOUT_SUCCESS:
			return {...initialState};
		case CONSTS.MESSAGE_RESET:
			return initialState;
		default:
			return state;
	}
}
