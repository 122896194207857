import React,  { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import CrpsDisplayBox from '../clinicians/CrpsDisplayBox';
import { clinicianProfilesPropType } from '@constants/propTypes';
import NewCrpPopup from '../clinicians/NewCrpPopup';
import ClinicFormDoctor from "../clinicians/ClinicFormDoctor";
import ClinicFormHcp from "../clinicians/ClinicFormHcp";
import { postCreateCrp } from '@actions/clinicianActions';
import { loadUser } from '@actions/profileActions';
import ProfileRemoveClinic from './profile/ProfileRemoveClinicPopup';


function ProfileAddClinic({ clinicianProfiles }) {
    const dispatch = useDispatch();

    const [showNewCrpPopup, setShowNewCrpPopup ] = useState(false);
    const [currentClinic, setCurrentClinic] = useState(null); // [clinic, setClinic
    const [newCrpPopup, setNewCrpPopup] = useState(null);
    const [isDoctor, setIsDoctor] = useState(false);
    
    useEffect(() => {
        if (clinicianProfiles.length === 0) return;
        const firstCrp = clinicianProfiles[0];
        const drJobrRoles = firstCrp.userProfile.jobRole.filter(item => item.jobRole === "Doctor");
        setIsDoctor(drJobrRoles.length>0);
    }, []);
    

    const closePopup = () => {
        setShowNewCrpPopup(false);
    }

    const isSuccess = (value) => {
        if (value) {
            closePopup();
            dispatch(loadUser());
        }
    };

    const sendCrpRequest = (clinic) => {
        if (!clinic.isNewClinic){
            const isCurrentProfile = clinicianProfiles.filter(item => item.clinic.id === clinic.id).length>0;
            if (isCurrentProfile) {
                closePopup();
                return;
            }
        }
        const formData = {
            clinicName: clinic.clinicName,
            centreName: clinic.centreName,
            address: clinic.address,
        };
        dispatch(postCreateCrp(formData, isSuccess))
    }

    function createNewCrpPopup(clinic) {
        return (
            <NewCrpPopup clinic={clinic} showModal={showNewCrpPopup} sendClinic={() => sendCrpRequest(clinic)} handleClose={closePopup}/>
        );
    }

    const setPopupHandler = (clinic) => {
        setCurrentClinic(clinic);
        setShowNewCrpPopup(true);
    }

    useEffect(() => {
        if (showNewCrpPopup===true) {
            setNewCrpPopup(createNewCrpPopup(currentClinic));
        } else {
            setNewCrpPopup(null);
        }
    }, [showNewCrpPopup])

    const handleAddNewClinic = (clinic) => {
        setPopupHandler(clinic);
    }

    const [showRemoveClinic, setShowRemoveClinic] = useState(false);
    const [rmClinic, setRmClinic] = useState(null);

    const handleRemoveClinic = (clinic) => {
        setRmClinic(clinic.id); 
    };
    const closeRemoveCliunicPopup = () => {
        setShowRemoveClinic(false);
        setRmClinic(null);
        
    }

    useEffect(() => {
        if (rmClinic!==null){
            setShowRemoveClinic(true);
        }
    }, [rmClinic]);

	return (
        <>  
            {showRemoveClinic ? <ProfileRemoveClinic userProfileId={clinicianProfiles[0].userProfile.id} clinicId={rmClinic} showPopup={showRemoveClinic} handleClose={closeRemoveCliunicPopup} /> : null}
            <Row >
                <Col>
                    <CrpsDisplayBox editable removeClinic={handleRemoveClinic} />
                </Col>
            </Row>
            {newCrpPopup}
            <Row className="mt-4">
                <Col className="text-left">
                    <h6>Assign a new clinic</h6>
                </Col>
            </Row>

            {isDoctor ? 
                <ClinicFormDoctor handleAddClinic={handleAddNewClinic} />
                : <ClinicFormHcp handleAddClinic={handleAddNewClinic} />   
            }
        </>
	);
}


ProfileAddClinic.propTypes = {
    clinicianProfiles: clinicianProfilesPropType.isRequired,
};

const mapStateToProps = (state) => ({
    clinicianProfiles: state.profile.clinicianProfiles,
});

export default connect(mapStateToProps, {})(ProfileAddClinic);