import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
	patientPropType,
	jobRolePropType,
	clinicanProfileRankPropType,
} from '@constants/propTypes';
import { getNameString, getClinicCenterStr } from '@actions/general';
import { postCreateReferral } from '@actions/referralActions';
import ReferralSentPopup from '../ReferralSentPopup';
import useUserClinicianProfiles from '@hooks/clinicians/useUserClinicianProfiles';

function RegisterReview({
	validateReferralData,
	getRequestData,
	values,
	refId = null,
}) {
	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	const { clinicianProfiles, validateClinicianProfiles } =
		useUserClinicianProfiles();

	const failedClinicianProfilesValidation = () => {
		console.log(values);
		return (
			<div className="medr-rounded">
				<Row>
					<Col>
						<h4>Error loading user clinician profiles</h4>
					</Col>
				</Row>
			</div>
		);
	};

	if (!validateClinicianProfiles()) {
		return failedClinicianProfilesValidation();
	}

	const lg = 4;
	const md = 6;
	const sm = 12;

	const dispatch = useDispatch();
	const [referralMade, setReferralMade] = useState(false);

	const crp = clinicianProfiles[0];

	const { valid, data } = validateReferralData(values);
	if (!valid) {
		return (
			<div className="medr-rounded">
				<Row>
					<Col>
						<h4>Error missing data</h4>
					</Col>
				</Row>
			</div>
		);
	}

	const {
		currentPatient,
		patientHistory,
		mainDescription,
		jobRole,
		targetClinician,
		specifiedClinician,
		insuranceProvider,
	} = data;

	const navigate = useNavigate();

	const createReferralSuccess = (success) => {
		if (success) {
			setReferralMade(true);
		}
	};

	const exitReferral = () => {
		if (refId !== null) {
			navigate('/dashboard');
		}
	};

	const onSubmit = (crpId) => {
		const formData = getRequestData(crpId);
		dispatch(postCreateReferral(formData, createReferralSuccess));
	};

	return (
		<div
			className="medr-layout text-center"
			style={{ maxWidth: '700px', marginLeft: 'auto', marginRight: 'auto' }}
		>
			<ReferralSentPopup showModal={referralMade} handleClose={exitReferral} />
			<Row>
				<Col>
					<h4>Review referral data</h4>
				</Col>
			</Row>
			<div className="medr-rounded bg-medr-gray bluegreen mt-4 text-left">
				<Row>
					<Col className="text-center">
						<h4 className="bluegreen">Referral summary</h4>
					</Col>
				</Row>
				<Row className="mt-4">
					<Col lg={lg} md={md} sm={sm}>
						Patient:
					</Col>
					<Col>{getNameString(currentPatient)}</Col>
				</Row>
				<Row className="mt-4">
					<Col lg={lg} md={md} sm={sm}>
						Insurance Provider:
					</Col>
					<Col>{insuranceProvider}</Col>
				</Row>
				<Row className="mt-4">
					<Col lg={lg} md={md} sm={sm}>
						History:
					</Col>
					<Col>{patientHistory}</Col>
				</Row>
				<Row className="mt-4">
					<Col lg={lg} md={md} sm={sm}>
						Reason for referral:
					</Col>
					<Col>{mainDescription}</Col>
				</Row>
				<Row className="mt-4">
					<Col lg={lg} md={md} sm={sm}>
						Clinician required:
					</Col>
					<Col>{jobRole.jobTitle}</Col>
				</Row>
				<Row className="mt-4">
					<Col lg={lg} md={md} sm={sm}>
						Clinician selected:
					</Col>
					<Col>
						{specifiedClinician === true
							? `${getNameString(
									targetClinician.userProfile
							  )} - ${getClinicCenterStr(targetClinician.clinic)}`
							: `Referral open to all ${jobRole.jobTitle}`}
					</Col>
				</Row>
			</div>
			<Row className="mt-4">
				<Col>
					<Button
						className="sel-button w80"
						variant="success"
						type="submit"
						onClick={() => onSubmit(crp.id)}
					>
						Send referral
					</Button>
				</Col>
			</Row>
		</div>
	);
}

RegisterReview.propTypes = {
	validateReferralData: PropTypes.func.isRequired,
	getRequestData: PropTypes.func.isRequired,
	values: PropTypes.shape({
		currentPatient: patientPropType.isRequired,
		patientHistory: PropTypes.string.isRequired,
		mainDescription: PropTypes.string.isRequired,
		jobRole: jobRolePropType.isRequired,
		targetClinician: clinicanProfileRankPropType,
		specifiedClinician: PropTypes.bool.isRequired,
	}).isRequired,
	refId: PropTypes.number,
};

const mapStateToProps = (state) => ({
	refId: state.referrals.referral.id,
});

export default connect(mapStateToProps, {})(RegisterReview);
