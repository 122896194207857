import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap';
import { patientsPropType } from '@constants/propTypes';
import { getClinicianPatientList } from '@actions/patientActions';
import PatientCardsDisplay from '@components/patient/PatientCardsDisplay';
import useScreenInfo from '@/hooks/app/useScreenInfo';
import PatientListTabsDropDown from '@/components/patient/PatientListTabsDropDown';

function PatientListScreen({ activePatients, inactivePatients, acceptedPatients}) {
// function PatientListScreen({ activePatients, inactivePatients, acceptedPatients, waitingPatients}) {

    /**
     * 1. Get the patients from the store
     */
	const [isNarrow] = useScreenInfo(769);
    const [actPx, setActPx] = useState([]);
    const [inactPx, setInactPx] = useState([]);
    const [accPx, setAccPx] = useState([]);
    const [activeTab, setActiveTab] = useState('activePatients');

    const viewLabels = {
        activePatients: "Patients Added",
        pastPatients: "Patients Referred",
        acceptedPatients: "Patients Accepted"
    }

    useEffect(() => {
        setActPx(activePatients || []);
        setInactPx(inactivePatients || []);
        setAccPx(acceptedPatients || [])
    }, [activePatients, inactivePatients, acceptedPatients]);
    
    // RETREIVE the patients first
    const dispatch = useDispatch();
    useEffect(() => {window.scroll(0,0)}, []);
	useEffect(() => {
        const fetchData = async () => {
            await dispatch(getClinicianPatientList());
        }
        fetchData();
	}, [dispatch]);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const renderContent = () => {
        switch(activeTab) {
            case 'activePatients':
                return <PatientCardsDisplay patientsList={actPx} />;
            case 'pastPatients':
                return <PatientCardsDisplay patientsList={inactPx} />;
            case 'acceptedPatients':
                return <PatientCardsDisplay patientsList={accPx} />;
            default:
                return null;
        }
    };

    if (isNarrow) {
        return (
            <div className='patient-list'>
                <h6 className='text-left p-1'>Select patient group</h6>
                <PatientListTabsDropDown 
                    viewName={viewLabels[activeTab]} 
                    updateView={handleTabChange} 
                    viewOptions={[
                        {value: 'activePatients', label: "Patients Added"},
                        {value: 'pastPatients', label: "Patients Referred"},
                        {value: 'acceptedPatients', label: "Patients Accepted"}
                    ]}
                    />
                {renderContent()}
            </div>
        );
    }

	return (
        <div className='patient-list'>
            <Tabs
                defaultActiveKey="activePatients"
                id="custom-tabs"
                className="custom-tabs mb-3"
                >
                <Tab eventKey="activePatients" title="Patients Added" className="custom-tabs">
                    <PatientCardsDisplay patientsList={actPx} />
                </Tab>
                <Tab eventKey="pastPatients" title="Patients Referred" className="custom-tabs">
                    <PatientCardsDisplay patientsList={inactPx} />                
                </Tab>
                <Tab eventKey="acceptedPatients" title="Patients Accepted" className="custom-tabs">
                    <PatientCardsDisplay patientsList={accPx} />                
                </Tab>
            </Tabs>
		</div>
	);
}

PatientListScreen.propTypes = {
	activePatients: patientsPropType.isRequired,
	inactivePatients: patientsPropType.isRequired,
	acceptedPatients: patientsPropType.isRequired,
	// waitingPatients: patientsPropType.isRequired
};

const mapStateToProps = (state) => ({
	activePatients: state.patients.activePatients,
    inactivePatients: state.patients.pastPatients,
    acceptedPatients: state.patients.acceptedPatients,
    // waitingPatients: state.patients.waitingPatients,
});

export default connect(mapStateToProps, {})(PatientListScreen);
