import { useEffect , useState } from "react";
import { useSelector } from "react-redux";

const useUserJobRole = () => {
    const jobRoles = useSelector(state => state.auth.user.jobRole);
    const [userType, setUserType] = useState(null);

    const checkUserType = () => {
        if (!jobRoles || jobRoles === undefined || jobRoles.length===0) return;

        if (jobRoles.filter(jr => jr.jobRole==="Staff").length>0){
            setUserType("Staff");
            return;
        }

        if (jobRoles.filter(jr => jr.jobRole==="Delegate").length>0){
            setUserType("Delegate");
            return;
        }

        if (jobRoles.filter(jr => jr.jobRole==="Patient").length>0){
            setUserType("Patient");
            return;
        }

        setUserType("Clinician");
    }

    useEffect(() => {
        checkUserType();
    }, [jobRoles]);
        
    return {
        jobRoles,
        userType,
    };
}

export default useUserJobRole;